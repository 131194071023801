import { Moment } from "moment";
import { OfficeEntity } from "./AdvanceHotDeskingService";
import { CapacityResponse } from "./OfficeCapacityService";
import { agent } from "./agent";
import { DATE_FORMAT } from "../utils/DateUtils";
import { Parser } from "json2csv";
import { saveAs } from "file-saver";
import { WhereaboutsOption } from "./WhereaboutOptions";
import { SimpleUserWithResponsibilitiesAndLocation, UnknownWhereaboutsUserInfo } from "../models/user.models";
import { DateScale } from "../components/UI/molecules/DateFlicker";


export function getMonthlyOfficeUsage(date: Moment, office: OfficeEntity): Promise<CapacityResponse[]> {
  return agent.requests.get(`/api/report/office/usage/month?office=${office.id}&date=${date.format(DATE_FORMAT)}`);
}

export function getWhereaboutsSummary(date: Moment, teamId: string): Promise<WhereaboutsSummaryReport> {
  const from = date.clone().startOf('month').format(DATE_FORMAT);
  const to = date.clone().endOf('month').format(DATE_FORMAT);
  return agent.requests.get(`/api/report/whereabouts/summary?teamId=${teamId}&from=${from}&to=${to}`, true);
}

export interface WhereaboutsSummaryReport {
  whereaboutsOptionList: WhereaboutsOption[];
  whereaboutsUsage: any[];
}

export function getOfficeUsageReport(teamId: string, office: OfficeEntity, from: Moment, to: Moment): Promise<any> {
  return agent.requests.get(`/api/report/office/usage/dates?office=${office.id}&team=${teamId}&from=${from.format(DATE_FORMAT)}&to=${to.format(DATE_FORMAT)}`)
}

export function getUnknownWhereaboutsReport(date: Moment): Promise<UnknownWhereaboutsUserInfo[]> {
  return agent.requests.get(`/api/report/unknown-whereabouts?date=${date.format(DATE_FORMAT)}`)
}

export function getIndividualWhereaboutsReport(teamId: string, companyId: string, from: Moment, to: Moment): Promise<any> {
  return agent.requests.post('/api/report/whereabouts', {
    companyId: companyId,
    fromDate: from?.format(DATE_FORMAT),
    toDate: to?.format(DATE_FORMAT),
    teamId: teamId,
  });
}

export async function downloadDetailedHolidayReport(year: number): Promise<HolidayReportLine[]> {
  return await agent.requests.get(`/api/report/holiday-report?year=${year}`);
}

export async function downloadAbsenceReport(from: Moment, to: Moment): Promise<AbsenceReportLine[]> {
  return await agent.requests.get(`/api/report/absence?from=${from.format(DATE_FORMAT)}&to=${to.format(DATE_FORMAT)}`);
}

export interface HolidayReportLine {
  userId: string;
  firstName: string;
  lastName: string;
  teamName: string;
  startDate: string;
  endDate: string;
  status: string;
  daysTaken: number;
  totalDaysTaken: number;
  remaining: number;
  allowance: number;
}

export interface AbsenceReportLine {
  userId: string;
  firstName: string;
  lastName: string;
  whereaboutsStatus: string;
  date: string;
  duration: string;
  count: number;
}

export function getCheckInSummary(office: OfficeEntity, date: Moment): Promise<any> {
  return agent.requests.get(`/api/report/check-in/summary?officeId=${office.id}&date=${date.format(DATE_FORMAT)}`);
}

export interface UsersSingleWhereaboutsBreakdown {
  key: string;
  count: number;
  locationId: number;
  label: string;
  icon: string;
}

export function getUserWhereaboutsBreakdown(userId: string, date: Moment, scaleDate: DateScale): Promise<UsersSingleWhereaboutsBreakdown[]> {
  let qs = ``;
  qs += `userId=${userId}`;
  if (scaleDate === DateScale.Month) {
    qs += `&year=${date.year()}&month=${date.month() + 1}`;
  } else {
    qs += `&year=${date.year()}`;
  }
  return agent.requests.get(`/api/report/user?${qs}`, true, true);
}

export function getWeeklyOfficeUsage(office: OfficeEntity, date: Moment): Promise<SimpleUserWithResponsibilitiesAndLocation[]> {
  return agent.requests.get(`/api/report/office/usage/average?office=${office.id}&date=${date.format(DATE_FORMAT)}`);
}

export function toCsvDownload(contents: any[], fileName: string, fields?: string[]) {
  const json2csvParser = new Parser({quote: '', fields});
  const csv = json2csvParser.parse(contents);

  const blob = new Blob([csv], {type: "text/csv;charset=utf-8"});
  saveAs(blob, `Team Today ${fileName}.csv`);
}


export interface KeyResponsibilitiesUsageReportItem {
  officeAttendance: number;
  date: string;
  count: number;
  officeCapacity: number;
  freeSeats?: number;
}

export function getKeyResponsibilitiesData(office: OfficeEntity, date: Moment, keyResponsibility: string): Promise<KeyResponsibilitiesUsageReportItem[]> {
  return agent.requests.get(`/api/report/key-responsibilities?officeId=${office.id}&responsibility=${keyResponsibility}&date=${date.format(DATE_FORMAT)}`);
}


interface ExportUserRow {
  firstName: string;
  lastName: string;
  email: string;
  team: string;
  location: string;
  permission: string;
  fireWarden: string;
  firstAider: string;
  keyHolder: string;
  mentalHealthFirstAider: string;
  lineReports: number;
  accountInUser: string;
}

export async function exportAllUsersToCsv(downloadAsCsv: boolean): Promise<ExportUserRow> {
  const userList = await agent.requests.get('/api/user/export');

  if (downloadAsCsv) {
    toCsvDownload(userList,
      'team-today-users',
      ['firstName',
        'lastName',
        'email',
        'team',
        'location',
        'permission',
        'fireWarden',
        'firstAider',
        'keyHolder',
        'mentalHealthFirstAider',
        'lineReports',
        'accountInUse',
      ]);
  }
  return userList;
}
