import React, { useMemo } from 'react';
import { WallPlannerTeamRow } from "./WallPlannerTeamRow";
import { WallPlannerUserRow } from "./WallPlannerUserRow";
import styled from "styled-components/macro";
import { WallPlannerProjectRow } from "./WallPlannerProjectRow";
import { useSelector } from "react-redux";
import { selectTeamDetails } from "../wallPlanner.duck";
import { Moment } from "moment";

interface Props {
  team?: { id: string, name: string };
  isFirst: boolean;
  weeksInMonth: Moment[];
}

export function WallPlannerTeamPlan(props: Props) {
  const {weeksInMonth, isFirst, team} = props;
  const allTeamDetails = useSelector(selectTeamDetails);
  const teamDetails = useMemo(() => {
    if (team) {
      return allTeamDetails[team.id] ?? [];
    } else {
      return [];
    }
  }, [team, allTeamDetails]);

  if (team) {
    return (
      <TeamContainer columns={weeksInMonth.length + (window.innerWidth < 768 ? 0 : 1)}>
        <WallPlannerTeamRow isFirst={isFirst} teamName={team.name}/>
        <WallPlannerProjectRow team={team}/>
        {teamDetails.map((user: any, k: any) => (
          <WallPlannerUserRow key={k} user={user.userInfo} working={user.working} holidays={user.holidays}/>
        ))}
        <WallPlannerUserRow />
      </TeamContainer>
    )
  } else {
    return null;
  }

}

export const TeamContainer = styled.div<any>`
  width: 100%;
  height: 100%;
  &:last-child {
    margin-bottom: 55px;
  }
  .userColumn__wrapper {
    z-index: 999;
  }
`
