import React, { useMemo } from 'react';
import styled from "styled-components/macro";
import { useSelector } from "react-redux";
import { selectPosition, selectRowFromDate, selectRowUserId } from "../data/WallPlannerHoverBox.duck";
import { selectTeamDetails, selectWorkGroupDetails } from "../wallPlanner.duck";
import moment from "moment";
import { DATE_FORMAT } from "../../../../utils/DateUtils";
import { naturalSort } from "../../../../utils/ArrayUtils";
import { UserAvatarAndName } from "../../../UI/molecules/UserAvatarAndName";
import { BodySmall, BodyVerySmall } from "../../../UI/atoms/fonts/Body";
import Colours from "../../../UI/atoms/Colours";
import { selectAllOfficeEntities } from "../../../../store/ducks/advanceHotDeskingSetup.duck";
import { OfficeEntity } from "../../../../services/AdvanceHotDeskingService";
import { getFullOfficeTitleById } from "../../../../utils/OfficeHelper";

export function WallPlannerHoverBox(props: Props) {
  const position = useSelector(selectPosition);
  const userId = useSelector(selectRowUserId);
  const fromDate = useSelector(selectRowFromDate);
  const officeList = useSelector(selectAllOfficeEntities);
  const teamDetails = useSelector(selectTeamDetails);
  const workGroupDetails = useSelector(selectWorkGroupDetails);

  const userInfo = useMemo(() => {
    if (userId && fromDate && teamDetails) {
      const flattendDetails = [...Object.values(teamDetails).flat(), Object.values(workGroupDetails).flat()].flat()
      const info: any = flattendDetails.find((td: any) => td.userInfo.userId === userId);
      return info.userInfo;
    }
  }, [fromDate, teamDetails, userId, workGroupDetails]);

  const userData = useMemo(() => {
    if (userId && fromDate && teamDetails) {
      const flattendDetails = [...Object.values(teamDetails).flat(), Object.values(workGroupDetails).flat()].flat()
      const info: any = flattendDetails.find((td: any) => td.userInfo.userId === userId);
      if (info) {
        const startOfWeek = moment(fromDate).clone().startOf('isoWeek').format(DATE_FORMAT);
        const endOfWeek = moment(fromDate).clone().startOf('isoWeek').add(7, 'days').format(DATE_FORMAT);

        const working = info.working.filter((w: any) => w.fromDate >= startOfWeek && w.fromDate < endOfWeek)
        const holidays = info.holidays.filter((w: any) => w.fromDate >= startOfWeek && w.fromDate < endOfWeek)

        const collection = [...working, ...holidays].map(c => c.collection).flat();

        return naturalSort(collection, 'fromDate');
      }
    }

    return [];
  }, [userId, fromDate, teamDetails, workGroupDetails]);

  return (
    <Wrapper x={position.x} y={position.y}>
      <UserAvatarAndName className={'wallPlanner__userAvatar'}
                         colour={'darkBlue'}
                         firstName={userInfo.firstName}
                         lastName={userInfo.lastName}
                         hideName={true} />
      <BodySmall className={'wallPlanner__username'} colour={Colours.darkGrey}>{userInfo.firstName + ' ' + userInfo.lastName}</BodySmall>

      {userData.map((ud: any) => <DayLine userData={ud} offices={officeList} />)}
    </Wrapper>
  )
}

function DayLine(props: { userData: any, offices: OfficeEntity[] }) {
  const {userData, offices} = props;

  const officeAmLabel = useMemo(() => {
    if (!!userData.amLocationId) {
      return getFullOfficeTitleById(userData.amLocationId, offices, ', ');
    }
  }, [offices, userData.amLocationId]);

  const officePmLabel = useMemo(() => {
    if (!!userData.pmLocationId) {
      return getFullOfficeTitleById(userData.pmLocationId, offices, ', ');
    }
  }, [offices, userData.pmLocationId]);

  if (userData.amOption.key === userData.pmOption.key && userData.amLocationId === userData.pmLocationId) {
    return (<DayWrapper>
      <BodyVerySmall weight={600} colour={Colours.blue}>{moment(userData.fromDate).format('ddd DD').toUpperCase()}</BodyVerySmall>
      <BodyVerySmall weight={600} colour={Colours.black}>{userData.amOption.label}</BodyVerySmall>
      {officeAmLabel && <BodyVerySmall>{officeAmLabel}</BodyVerySmall>}
    </DayWrapper>);
  } else {
    return (<DayWrapper>
      <BodyVerySmall weight={600} colour={Colours.blue}>{moment(userData.fromDate).format('ddd DD').toUpperCase()}</BodyVerySmall>
      <BodyVerySmall weight={600} colour={Colours.black}>AM: {userData.amOption.label}</BodyVerySmall>
      {officeAmLabel && <BodyVerySmall>{officeAmLabel}</BodyVerySmall>}
      <BodyVerySmall style={{marginTop: 8}} weight={600} colour={Colours.black}>PM: {userData.pmOption.label}</BodyVerySmall>
      {officePmLabel && <BodyVerySmall>{officePmLabel}</BodyVerySmall>}
    </DayWrapper>);
  }
}

interface Props {
}

const Wrapper = styled.div<any>`
  position: absolute;
  min-width: 200px;
  left: ${props => props.x}px;
  top: ${props => props.y}px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 17px 2px rgba(0, 87, 255, 0.15);
  display: flex;
  flex-direction: column;
  padding: 8px 16px 16px 16px;
  
  .wallPlanner__userAvatar {
    margin: 6px auto 0 auto;
  }
  .wallPlanner__username {
    margin: 0 auto 8px auto;
  }
`

const DayWrapper = styled.div`
  margin-bottom: 14px;
`
