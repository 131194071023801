import React, { useEffect, useRef, useState } from 'react';
import { requestUsersWeeklyMovements } from "../../../services/Movements";
import { useSelector } from "react-redux";
import { selectCalendarDate } from "../../../store/ducks/dashboard.duck";
import { selectCurrentUser } from "../../../store/ducks/auth.duck";
import { MemberMovements } from "../../../models/movements.models";
import WhereaboutsRow from "./movements-wallchart/components/user-row/WhereaboutsRow";
import { Accordian } from "../../../animations/AnimationVariants";

export function CurrentUsersWeeklyWhereabouts(props: Props) {
  const timerId = useRef(0);
  const [weeklyWhereabouts, setWeeklyWhereabouts] = useState<MemberMovements | undefined>(undefined);
  const calendarDate = useSelector(selectCalendarDate);
  const currentUser = useSelector(selectCurrentUser);

  useEffect(() => {
    if (!weeklyWhereabouts && currentUser) {
      if (timerId.current) {
        clearTimeout(timerId.current);
      }
      timerId.current = setTimeout(() => {
        requestUsersWeeklyMovements(calendarDate, currentUser.id)
          .then(resp => setWeeklyWhereabouts(resp))
          .finally(() => timerId.current = 0);
      }, 250);
    }
  }, [weeklyWhereabouts, currentUser, calendarDate]);

  useEffect(() => {
    setWeeklyWhereabouts(undefined);
  }, [calendarDate]);

  return (
    <div style={{...props.style ?? {}, width: '100%'}}>
      <Accordian isOpen={!!weeklyWhereabouts}>
        {weeklyWhereabouts && <WhereaboutsRow member={weeklyWhereabouts}
                                              enableSetMovements={true}
                                              currentUser={currentUser} />}
      </Accordian>
    </div>
  )
}

interface Props {
  style?: any;
}
