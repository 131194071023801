import React, { useMemo } from 'react';
import { HolidayReportDatePicker } from "./HolidayReportDatePicker";
import isMobile from "is-mobile";
import { OutlineButton } from "../../../../UI/atoms/buttons/OutlineButton";
import styled from "styled-components/macro";
import { HeadlineSmall } from "../../../../UI/atoms/fonts/Headline";
import { useTranslation } from "react-i18next";
import Colours from "../../../../UI/atoms/Colours";
import {
  DownloadHighLevelHolidayReportButton
} from "../../../reporting/components/DownloadHighLevelHolidayReportButton";
import { DownloadDetailedHolidayReportButton } from "../../../reporting/components/DownloadDetailedHolidayReportButton";
import { useSelector } from "react-redux";
import { selectHolidayReportingDate } from "../../models/holiday-reports.duck";
import { DownloadDetailedAbsenceReportButton } from "../../../reporting/components/DownloadDetailedAbsenceReportButton";
import { selectConfig } from "../../../../../store/ducks/config.duck";

export function HolidayReportSidebarContent(props: Props) {
  const {t} = useTranslation();
  const selectedDate = useSelector(selectHolidayReportingDate);
  const config = useSelector(selectConfig);

  const holidayYearStartDate = useMemo(() => {
    let date = selectedDate.clone();
    date.month(config.holidayYearRecurringMonth-1);
    date.date(config.holidayYearRecurringDay);
    return date;
  }, [config, selectedDate]);

  const holidayYearEndDate = useMemo(() => {
    let date = holidayYearStartDate.clone();
    date.add(1, 'years');
    date.subtract(1, 'days');
    return date;
  }, [holidayYearStartDate]);

  return (
    <>
      {!isMobile() && <HolidayReportDatePicker style={{marginBottom: 24}} />}

      <OutlineButton text={'holidays.add-new-leave'} link={'/holidays/add'} fullWidth={true} style={{marginBottom: 24}}  />

      <ReportContentCard>
        <HeadlineSmall style={{marginBottom: 24, marginRight: 32}} color={Colours.darkBlue}>{t('reports-page.download-holiday-summary')}</HeadlineSmall>

        <DownloadHighLevelHolidayReportButton selectedDate={selectedDate} />
        <DownloadDetailedHolidayReportButton selectedDate={selectedDate} />
      </ReportContentCard>

      <ReportContentCard>
        <HeadlineSmall style={{marginBottom: 24, marginRight: 32}} color={Colours.darkBlue}>{t('reports-page.download-absence-summary')}</HeadlineSmall>
        <OutlineButton fullWidth={true} style={{marginBottom: 12}} disabled={true}
                       text={'reports-page.holidays-report.high-level-report'} />
        <DownloadDetailedAbsenceReportButton fromDate={holidayYearStartDate} toDate={holidayYearEndDate} />
      </ReportContentCard>
    </>
  )
}

interface Props {
}

const ReportContentCard = styled.div`
  border-radius: 24px;
  background: #E6FCFF;
  display: flex;
  padding: 32px 24px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  margin-bottom: 16px;
  
  button {
    margin-bottom: 12px;
    min-width: 100%;
    width: 100%;
  }
`
