import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit'
import { AppState } from "../state/app.state";
import {
  FavouriteDesk,
  FavouriteOfficeEntity,
  getFavouriteDesks,
  getFavouriteOffices,
  getOffices,
  OfficeEntity,
  toggleFavouriteDesk,
  toggleFavouriteOffice
} from "../../services/AdvanceHotDeskingService";
import { failureNotification, successNotification } from "./notification.duck";
import { selectOffice, setSelectedArea } from "./officeView.duck";
import { getParameterByName } from "../../utils/UrlUtils";
import { asNumber } from "../../utils/TypeUtils";
import { OfficeWithCapacity } from "../../services/OfficeCapacityService";
import { getOfficeChildren, sortOffice } from "../../utils/OfficeHelper";

export interface AdvanceHotDeskingSetupState {
  offices: any;
  favouriteOffices: FavouriteOfficeEntity[];
  favouriteDesks: FavouriteDesk[];
  loading: boolean
  toggleFavoriteDeskLoading: boolean;
}

export const initialAdvanceHotDeskingSetupState: AdvanceHotDeskingSetupState = {
  offices: [],
  favouriteOffices: [],
  favouriteDesks: [],
  loading: false,
  toggleFavoriteDeskLoading: false,
}

export const fetchOfficeEntities: any = createAsyncThunk(
  'advanceHotDeskingSetup/fetchOfficeEntities',
  async (none: void, thunkAPI) => {
    const offices = await getOffices();
    return {
      offices,
    }
  }
)

export const initSelectedOffice: any = createAsyncThunk(
  'advanceHotDeskingSetup/initSelectedOffice',
  async (selectDefault: boolean, thunkAPI) => {
    const state = thunkAPI.getState() as AppState;
    const offices = state.advanceHotDeskingSetup.offices;

    if (offices.length === 0) {
      console.warn('office not loaded')
      return;
    }

    if (selectDefault) {
      if (!state.officeView.selectedOffice && offices.length > 0) {
        const officeId = asNumber(getParameterByName('office', window.location.href));
        if (officeId && offices.find((o: OfficeEntity) => o.id === officeId)) {
          thunkAPI.dispatch(selectOffice(offices.find((o: OfficeEntity) => o.id === officeId)))
        }

        const areaId = asNumber(getParameterByName('area', window.location.href));
        if (areaId && offices.find((o: OfficeEntity) => o.id === areaId)) {
          thunkAPI.dispatch(setSelectedArea(offices.find((o: OfficeEntity) => o.id === areaId)))
        }

        if (!officeId && !areaId) {
          const parentOffices = offices.filter((o: OfficeEntity) => !o.parentId);
          if (parentOffices.length > 0) {
            thunkAPI.dispatch(selectOffice(parentOffices[0]))
          }
        }
      }
    }
  }
);

export const toggleDefaultOffice: any = createAsyncThunk(
  'advanceHotDeskingSetup/updateUsersDefaultLocation',
  async (officeId: number, thunkAPI) => {
    if (officeId > 0) {
      await toggleFavouriteOffice(officeId);
      await new Promise((resolve: any) => setTimeout(() => resolve(), 100));
      const favouriteOffices = await makeRequestForFavouriteOffices();
      thunkAPI.dispatch(setFavouriteOffices(favouriteOffices));
    } else {
      console.error('Failed to favourite office');
      thunkAPI.dispatch(failureNotification('Failed to favourite'))
    }
  }
);

export const toggleFavoriteDesk: any = createAsyncThunk(
  'advanceHotDeskingSetup/toggleFavoriteDesk',
  async (deskId: number, thunkAPI) => {
    if (deskId > 0) {
      try {
        await toggleFavouriteDesk(deskId);
        const favouriteOffices = await makeRequestForFavouriteOffices();
        thunkAPI.dispatch(setFavouriteOffices(favouriteOffices));

        thunkAPI.dispatch(successNotification(favouriteOffices.desks.find(d => d.deskId === deskId) ? 'Desk favourited' : 'Desk unfavourited'));
      } catch (err: any) {
        thunkAPI.dispatch(failureNotification(JSON.parse(err.message)?.message ?? 'Failed to favourite'))
      }
    } else {
      console.error('Failed to favourite desk');
      thunkAPI.dispatch(failureNotification('Failed to favourite'))
    }
  }
);

export const loadFavouriteOffices: any = createAsyncThunk(
  'advanceHotDeskingSetup/loadFavouriteOffices',
  async (force: any, thunkAPI) => {
    return await makeRequestForFavouriteOffices();
  }
);

async function makeRequestForFavouriteOffices() {
  const offices = await getFavouriteOffices();
  const desks = await getFavouriteDesks();
  return { offices, desks }
}

const advanceHotDeskingSetupSlice = createSlice({
  name: 'advanceHotDeskingSetup',
  initialState: initialAdvanceHotDeskingSetupState,
  reducers: {
    reset: () => initialAdvanceHotDeskingSetupState,
    setOffices: (state, action) => ({...state, offices: action.payload}),
    setFavouriteOffices: (state, action) => ({
      ...state,
      favouriteOffices: action.payload.offices || [],
      favouriteDesks: action.payload.desks || []
    })
  },
  extraReducers: {
    [fetchOfficeEntities.pending]: (state) => ({...state, loading: true, offices: []}),
    [fetchOfficeEntities.reject]: (state) => ({...state, loading: false,}),
    [fetchOfficeEntities.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      offices: action.payload.offices || [],
    }),

    [toggleDefaultOffice.pending]: (state) => ({...state, loading: true,}),
    [toggleDefaultOffice.reject]: (state) => ({...state, loading: false,}),
    [toggleDefaultOffice.fulfilled]: (state) => ({...state, loading: false,}),

    [toggleFavoriteDesk.pending]: (state) => ({...state, toggleFavoriteDeskLoading: true,}),
    [toggleFavoriteDesk.reject]: (state) => ({...state, toggleFavoriteDeskLoading: false,}),
    [toggleFavoriteDesk.fulfilled]: (state) => ({...state, toggleFavoriteDeskLoading: false,}),

    [loadFavouriteOffices.fulfilled]: (state, action) => ({
      ...state,
      favouriteOffices: action.payload.offices || [],
      favouriteDesks: action.payload.desks || [],
    }),
    [loadFavouriteOffices.reject]: (state) => ({ ...state, favouriteOffices: [], favouriteDesks: [], }),
  }
});

export const {
  reset,
  setOffices,
  setFavouriteOffices,
} = advanceHotDeskingSetupSlice.actions;
export default advanceHotDeskingSetupSlice.reducer;

export const selectOffices = (state: AppState) => state.advanceHotDeskingSetup.offices.filter((office: OfficeEntity) => !office.parentId).sort(sortOffice);
export const selectActiveOffices = (state: AppState) => state.advanceHotDeskingSetup.offices.filter((office: OfficeEntity) => !office.parentId && office.active).sort(sortOffice);
export const selectOfficeById = (state: AppState, officeId: number) => state.advanceHotDeskingSetup.offices.find((o: OfficeEntity) => o.id === officeId);
export const selectOfficesByParentId = (state: AppState, parentId?: number) => {
  if (!parentId) return [];
  return state.advanceHotDeskingSetup.offices.filter((o: OfficeEntity) => o.parentId === parentId && o.active).sort(sortOffice);
};
export const selectOfficeByParentIdWithCapacities = (state: AppState, parentId: number): OfficeWithCapacity[] => {
  const childOffices = state.advanceHotDeskingSetup.offices.filter((o: OfficeEntity) => o.parentId === parentId && o.active).sort(sortOffice);
  console.warn('TODO, needs office capacity')
  return childOffices.map((office: OfficeEntity) => ({
    capacity: undefined,
    office: office
  }))
}
export const selectAllOfficeEntities = (state: AppState) => state.advanceHotDeskingSetup.offices;
export const selectFavouriteOffices = (state: AppState) => state.advanceHotDeskingSetup.favouriteOffices;
export const selectFavouriteDesks = (state: AppState) => state.advanceHotDeskingSetup.favouriteDesks;
export const selectNumberOfFavouriteOfficesAndDesks = (state: AppState) => state.advanceHotDeskingSetup.favouriteOffices.length + state.advanceHotDeskingSetup.favouriteDesks.length;
export const selectIsAdvanceHotDeskingLoading = (state: AppState) => state.advanceHotDeskingSetup.loading;
export const selectFavouriteDesksLoading = (state: AppState) => state.advanceHotDeskingSetup.toggleFavoriteDeskLoading;
export const selectAllOfficesForMeetingRooms = (state: AppState) => {
  return state.advanceHotDeskingSetup.offices.filter((office: OfficeEntity) => {
    if (office.floor) return true;
    if (office.office && getOfficeChildren(office.id, state.advanceHotDeskingSetup.offices).length === 0) return true;
    return false;
  }).sort(sortOffice);
}
