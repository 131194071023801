import React from 'react';
import { HeadlineMedium } from "../../../UI/atoms/fonts/Headline";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { LinkButton } from "../../../UI/atoms/buttons/LinkButton";

export function NoMeetingRoomSelection(props: Props) {
  const {t} = useTranslation();

  return (
    <Container>
      <Graphic src={"/assets/images/meeting-room-graphic-1.svg"} />
      <HeadlineMedium style={{marginBottom: 32}}>{t('room-finder.no-selection')}</HeadlineMedium>
      <LinkButton text={'settings.meeting-rooms.add-meeting-rooms'}
                  type={"primary"}
                  size={"large"}
                  link={"/manage/meeting-rooms"} />
    </Container>
  )
}

interface Props {
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10vh;
  .linkButton button {
    margin: 0 auto;
  }
`

const Graphic = styled.img`
  max-width: 90%;
  width: 400px;
  margin-bottom: 32px;
`
