import React from 'react';
import styled from "styled-components";
import { iconPackPath } from "../../../utils/WhereaboutsHelper";
import { FreeBusyStatus } from "../../../services/WhereaboutOptions";
import { IconPack } from "../../../models/company.models";

const ICONS = [
  {src: 'Car.svg', name: 'Car'},
  {src: 'Holiday.svg', name: 'Holiday'},
  {src: 'PendingHoliday.svg', name: 'Holiday'},
  {src: 'House.svg', name: 'House'},
  {src: 'ill.svg', name: 'ill'},
  {src: 'Office.svg', name: 'Office'},
  {src: 'Offsite.svg', name: 'Offsite'},
  {src: 'aeroplane.svg', name: 'Aeroplane'},
  {src: 'building-site.svg', name: 'Building site'},
  {src: 'hospital.svg', name: 'Hospital'},
  {src: 'shop.svg', name: 'Shop'},
  {src: 'university.svg', name: 'University'},
  {src: 'Seminar.svg', name: 'Seminar'},
  {src: 'Travel.svg', name: 'Travel'},
  {src: 'Train.svg', name: 'Train'},
  {src: 'bank-holiday.svg', name: 'Bank Holiday'},
  {src: 'hard-hat.svg', name: 'On-site'},
  {src: 'sales-fayre.svg', name: 'Sales Fayre'},
  {src: 'Boat.svg', name: 'Boat'},
  {src: 'DryDock.svg', name: 'Dry Dock'},
  {src: 'Port.svg', name: 'Port'},
]

export function WhereaboutsImagePicker(props: Props) {
  const {iconPack, freeBusyStatus} = props;
  const colour = props.color ?? 'grey';
  return (
    <Wrapper>
      <form>
        <RadioButtonList id="whereaboutsImages">
          {ICONS.map((icon, key) => (
            <RadioButtonContainer key={key}
                                  onClick={() => props.onSelect(icon.src)}
                                  data-test={`whereabouts-picker-image-${icon.name.replace('/ /g', '-').toLowerCase()}`}>
              <div className={`whereaboutsPicker__icon movement__icon--${colour}`}>
                <img src={iconPackPath(iconPack, icon.src, freeBusyStatus)}
                     alt={icon.name}
                     data-test={`whereabouts-picker-image-${icon.name.replace(/\s+/g, '-')}`.toLowerCase()}
                     title={icon.name} />
              </div>
              <input type="radio" name="radio" readOnly={true} checked={icon.src === props.selectedImage} title={icon.name} />
            </RadioButtonContainer>
          ))}
        </RadioButtonList>
      </form>
    </Wrapper>
  )
}

interface Props {
  color?: string;
  selectedImage?: string;
  iconPack: IconPack;
  freeBusyStatus: FreeBusyStatus;
  onSelect: (image: string) => any;
}

const Wrapper = styled.div`
  display: flex;
`

const RadioButtonContainer = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 74px;
  margin: 0 4px 16px 4px;
  cursor: pointer;
  border-radius: 8px;
  padding: 4px;
  img {
    border-radius: 8px;
    padding: 4px;
    height: 90%;
  }
  .whereaboutsPicker__icon {
    width: 100%;
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    margin-bottom: 6px;
  }
`

const RadioButtonList = styled.label`
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
`
