import React, { useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  selectActiveDay,
  selectFocusedEditingWhereabouts,
  setActiveDay
} from "../../../../../store/ducks/editMovements.duck";
import { Movement, Period } from "../../../../../models/movements.models";
import { HeadlineSmall } from "../../../atoms/fonts/Headline";
import { MovementIcon } from "../../../molecules/MovementIcons";
import moment, { Moment } from "moment";
import { Column } from '../../../atoms/StructuralLayout';
import PeriodList from "../../period-list/PeriodList";
import ActivityList from "./ActivityList";
import { OfficeEntity } from "../../../../../services/AdvanceHotDeskingService";
import {
  selectAllOfficeEntities,
  selectNumberOfFavouriteOfficesAndDesks
} from "../../../../../store/ducks/advanceHotDeskingSetup.duck";
import { WhereaboutsOption } from "../../../../../services/WhereaboutOptions";
import {
  selectAllSelectableWhereaboutsOptions,
  selectAllWhereaboutsOptions
} from "../../../../../store/ducks/whereaboutsOptions.duck";
import isMobile from "is-mobile";
import { selectCurrentUser, selectIconPack } from "../../../../../store/ducks/auth.duck";

export default function MobileEditMovementsContent() {
  const usersMovements: Movement = useSelector(selectFocusedEditingWhereabouts);
  const whereaboutsOptions = useSelector(selectAllWhereaboutsOptions);
  const currentUser = useSelector(selectCurrentUser);

  const getOptionFor = (option: string) => {
    return whereaboutsOptions.find((whereaboutsOption) => whereaboutsOption.key === option);
  }

  if (!usersMovements) {
    return null;
  }

  return (
    <div className="mobileEditMovements">
      <MobileMovementDayRow day={moment(usersMovements.date).clone().add(0, 'days')}
                            amStatus={getOptionFor(usersMovements.mondayAmStatus)} pmStatus={getOptionFor(usersMovements.mondayPmStatus)}
                            officeLocationId={usersMovements.mondayLocationId} officePmLocationId={usersMovements.mondayPmLocationId}/>
      <MobileMovementDayRow day={moment(usersMovements.date).clone().add(1, 'days')}
                            amStatus={getOptionFor(usersMovements.tuesdayAmStatus)} pmStatus={getOptionFor(usersMovements.tuesdayPmStatus)}
                            officeLocationId={usersMovements.tuesdayLocationId} officePmLocationId={usersMovements.tuesdayPmLocationId}/>
      <MobileMovementDayRow day={moment(usersMovements.date).clone().add(2, 'days')}
                            amStatus={getOptionFor(usersMovements.wednesdayAmStatus)} pmStatus={getOptionFor(usersMovements.wednesdayPmStatus)}
                            officeLocationId={usersMovements.wednesdayLocationId} officePmLocationId={usersMovements.wednesdayPmLocationId}/>
      <MobileMovementDayRow day={moment(usersMovements.date).clone().add(3, 'days')}
                            amStatus={getOptionFor(usersMovements.thursdayAmStatus)} pmStatus={getOptionFor(usersMovements.thursdayPmStatus)}
                            officeLocationId={usersMovements.thursdayLocationId} officePmLocationId={usersMovements.thursdayPmLocationId}/>
      <MobileMovementDayRow day={moment(usersMovements.date).clone().add(4, 'days')}
                            amStatus={getOptionFor(usersMovements.fridayAmStatus)} pmStatus={getOptionFor(usersMovements.fridayPmStatus)}
                            officeLocationId={usersMovements.fridayLocationId} officePmLocationId={usersMovements.fridayPmLocationId}/>
      {currentUser?.companyEntity.sevenDayWhereaboutsWeekEnabled && <>
        <MobileMovementDayRow day={moment(usersMovements.date).clone().add(5, 'days')}
                              amStatus={getOptionFor(usersMovements.saturdayAmStatus)} pmStatus={getOptionFor(usersMovements.saturdayPmStatus)}
                              officeLocationId={usersMovements.saturdayLocationId} officePmLocationId={usersMovements.saturdayPmLocationId}/>
        <MobileMovementDayRow day={moment(usersMovements.date).clone().add(6, 'days')}
                              amStatus={getOptionFor(usersMovements.sundayAmStatus)} pmStatus={getOptionFor(usersMovements.sundayPmStatus)}
                              officeLocationId={usersMovements.sundayLocationId} officePmLocationId={usersMovements.sundayPmLocationId}/>
      </>}
    </div>
  )
}

function MobileMovementDayRow(props: { day: Moment, amStatus?: WhereaboutsOption, pmStatus?: WhereaboutsOption, officeLocationId: number, officePmLocationId: number }) {
  const dispatch = useDispatch();
  const activeDay = useSelector(selectActiveDay);
  const user = useSelector(selectCurrentUser);
  const isDisabled = useMemo(() => moment().diff(props.day, 'days') > 0, [props.day]);
  const isActive = useMemo(() => !!activeDay && moment(activeDay).diff(props.day, 'days') === 0, [activeDay, props.day]);
  const onDayClick = (day: Moment) => dispatch(setActiveDay(day));
  const activities = useSelector(selectAllSelectableWhereaboutsOptions);
  const numberOfFavouriteOfficesAndDesks = useSelector(selectNumberOfFavouriteOfficesAndDesks);
  const iconPack = useSelector(selectIconPack);

  const heightOfActivitiesList = useMemo(() => {
    const numberOfActivitiesInList = 1 + numberOfFavouriteOfficesAndDesks + activities.length + 1 + ((user?.defaultLocationId ?? 0) > 0 ? 1 : 0);
    return isActive ? 100 * (numberOfActivitiesInList) : 0;
  }, [isActive, numberOfFavouriteOfficesAndDesks, activities, user]);

  const offices = useSelector(selectAllOfficeEntities);
  const officeLocationId = props.officeLocationId ?? null;
  const officePmLocationId = props.officePmLocationId ?? null;

  const officeAm = useMemo(() => {
    const attendingOffice = offices.find((o: OfficeEntity) => o.id === officeLocationId) ?? null
    if (attendingOffice && attendingOffice.parentId) {
      return offices.find((o: OfficeEntity) => o.id === attendingOffice.parentId)
    }
    return attendingOffice ?? null;
  }, [offices, officeLocationId]);

  const officePm = useMemo(() => {
    if (!officePmLocationId || officeLocationId === officePmLocationId) return;

    const attendingOffice = offices.find((o: OfficeEntity) => o.id === officePmLocationId) ?? null
    if (attendingOffice && attendingOffice.parentId) {
      return offices.find((o: OfficeEntity) => o.id === attendingOffice.parentId)
    }
    return attendingOffice ?? null;
  }, [offices, officeLocationId, officePmLocationId]);

  if (isMobile()) {
    return (
      <div onClick={() => onDayClick(props.day)}
           className={`mobileEditMovements__row ${isActive ? 'mobileEditMovements__row--active' : ''}`}>
        <div className="mobileEditMovements__dayHeader">
          <Title day={props.day.format('dddd')} activePeriod={Period.AllDay} isActive={true}/>
          <MovementIcon className="mobileEditMovements__movementIcon"
                        disabled={isDisabled}
                        iconPack={iconPack}
                        amStatus={props.amStatus} pmStatus={props.pmStatus}
                        amOfficeLocationId={officeAm?.id} pmOfficeLocationId={officePm?.id}
                        officeAmLabel={officeAm?.label}  officePmLabel={officePm?.label}/>
        </div>
        <Column className="mobileEditMovements__content"
                onClick={(e: any) => e.stopPropagation()}
                style={{maxHeight: heightOfActivitiesList}}>
          {isActive && <>
            <PeriodList/>
            <ActivityList activeDate={activeDay}/>
          </>}
        </Column>
      </div>
    )
  } else {
    return null;
  }

}

function Title(props: { day: String, activePeriod: Period, isActive: boolean }) {
  const start = props.day;
  const middle = props.isActive && props.activePeriod !== Period.AllDay ?
    <span className="mobileEditMovements--highlighted"> {props.activePeriod}</span> : '';
  const end = props.isActive ? ' I\'ll be' : '';

  return (
    <HeadlineSmall>
      {start}
      <span>{middle}</span>
      {end}
    </HeadlineSmall>
  )
}
