import { agent } from './agent';
import {
  EnrichedMeetingRoomBooking,
  MeetingRoom,
  MeetingRoomBooking,
  MeetingRoomFacilities, MeetRoomCapacityBands
} from "../components/pages/meeting-rooms/models/MeetingRooms";
import moment, { Moment } from "moment";
import { DATE_FORMAT } from "../utils/DateUtils";
import { OfficeEntity } from "./AdvanceHotDeskingService";

export async function getMeetingRooms(officeId: number, capacityBand: MeetRoomCapacityBands, facilities: MeetingRoomFacilities[]): Promise<MeetingRoom[]> {
  let qs = `?capacity=${capacityBand}`;
  if (facilities.length > 0) qs += `&facilities=${facilities.join(',')}`
  return await agent.requests.get(`/api/meeting-rooms/office/${officeId}${qs}`);
}

export async function getAllMeetingRooms(): Promise<MeetingRoom[]> {
  return await agent.requests.get(`/api/meeting-rooms`);
}

export async function getMeetingRoomById(meetingRoomId: string): Promise<MeetingRoom> {
  return await agent.requests.get(`/api/meeting-room/${meetingRoomId}`);
}

export async function deleteMeetingRoom(meetingRoomId: string): Promise<void> {
  await agent.requests.del(`/api/meeting-room/${meetingRoomId}`)
}

export async function updateMeetingRoomPosition(meetingRoomId: string, x: number, y: number): Promise<void> {
  await agent.requests.put(`/api/meeting-rooms/position`, {
    roomId: meetingRoomId,
    x: x,
    y: y,
  });
}

export async function updateMeetingRoomSize(meetingRoomId: string, width: number, height: number): Promise<void> {
  await agent.requests.put(`/api/meeting-rooms/size`, {
    roomId: meetingRoomId,
    width: width,
    height: height,
  });
}

export async function getMeetingRoomBookingsForMeetingRoom(meetingRoom: MeetingRoom, date: Moment): Promise<MeetingRoomBooking[]> {
  const bookings = await agent.requests.get(`/api/meeting-room/${meetingRoom.id}/bookings?date=${date.format(DATE_FORMAT)}`);
  return bookings.map((booking: any) => {
    return {
      ...booking,
      startDateTime: moment.utc(booking.startDateTime),
      endDateTime: moment.utc(booking.endDateTime),
    }
  })
}

export async function getMeetingRoomBookings(office: OfficeEntity, date: Moment): Promise<MeetingRoomBooking[]> {
  const bookings = await agent.requests.get(`/api/meeting-rooms/bookings/office/${office.id}?date=${date.format(DATE_FORMAT)}`);
  return bookings.map((booking: any) => {
    return {
      ...booking,
      startDateTime: moment.utc(booking.startDateTime),
      endDateTime: moment.utc(booking.endDateTime),
    }
  })
}

export async function bookMeetingRoomRequest(meetingRoom: MeetingRoom, fromDateTime: Moment, toDateTime: Moment, meetingName: string, addToCalendar: boolean): Promise<any> {
  if (toDateTime.isBefore(moment())) {
    throw new Error('Cannot book a meeting room in the past');
  }
  await agent.requests.post(`/api/meeting-rooms/booking`, {
    meetingRoomId: meetingRoom.id,
    fromDateTime: fromDateTime.toISOString(true),
    toDateTime: toDateTime.toISOString(true),
    name: meetingName,
    updateCalendar: addToCalendar,
  });
  return await new Promise((resolve: any) => setTimeout(() => resolve(), 5000));
}

export async function updateMeetingRoomBooking(bookingId: number, meetingRoom: MeetingRoom, fromDateTime: Moment, toDateTime: Moment, meetingName: string, addToCalendar: boolean): Promise<any> {
  return await agent.requests.put(`/api/meeting-rooms/booking`, {
    bookingId: bookingId,
    meetingRoomId: meetingRoom.id,
    fromDateTime: fromDateTime.toISOString(true),
    toDateTime: toDateTime.toISOString(true),
    name: meetingName,
    updateCalendar: addToCalendar,
  });
}

export async function cancelMeetingRoomBooking(bookingId: number): Promise<any> {
  return await agent.requests.del(`/api/meeting-rooms/booking/${bookingId}/cancel`);
}

export async function createMeetingRoom(office: OfficeEntity, meetingRoomName: string, meetingRoomCapacity: number, facilities: MeetingRoomFacilities[]) {
  return await agent.requests.post(`/api/meeting-room`, {
    name: meetingRoomName,
    capacity: meetingRoomCapacity,
    officeId: office.id,
    facilities: facilities,
  });
}

export async function updateMeetingRoom(meetingRoom: MeetingRoom): Promise<any> {
  return await agent.requests.put(`/api/meeting-room`, meetingRoom);
}

export async function getMyFutureBookings(): Promise<EnrichedMeetingRoomBooking[]> {
  const httpResp = await agent.requests.get(`/api/my-bookings`);
  return httpResp.map((mr: MeetingRoomBooking) => {
      const splitName = mr.bookerName.split(' ');
      const firstName = splitName.shift() || '';
      const lastName = splitName.join(" ");
      return {
        ...mr,
        firstName: firstName,
        lastName: lastName,
        startDateTime: moment.utc(mr.startDateTime).local(),
        endDateTime: moment.utc(mr.endDateTime).local(),
        dateString: moment.utc(mr.startDateTime).local().format(DATE_FORMAT),
      }
    });
}
