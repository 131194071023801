import React, { useMemo, useState } from 'react';
import moment, { Moment } from "moment";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../../../store/ducks/dialog.duck";
import Dialog from "../../../../UI/molecules/Dialog";
import DialogHeader from "../../../../dialogs/DialogHeader";
import { OfficeEntity } from "../../../../../services/AdvanceHotDeskingService";
import { OfficeAndDateContentCard } from "../../../../UI/molecules/OfficeAndDateContentCard";
import styled from "styled-components/macro";
import TextField from "../../../../UI/atoms/TextField";
import { UserSearchDropDownList } from "../../../../UI/molecules/UserSearchDropDownList";
import { BasicUserInfo } from "../../../../../models/user.models";
import { UserAvatarAndName } from "../../../../UI/molecules/UserAvatarAndName";
import { PrimaryButton } from "../../../../UI/atoms/buttons/PrimaryButton";
import { Switch } from "../../../../UI/atoms/Switch";
import { BodyRegular } from "../../../../UI/atoms/fonts/Body";
import { useTranslation } from "react-i18next";
import Colours from "../../../../UI/atoms/Colours";
import { createVisitorBooking } from "./data/visitor-booking-service";
import { selectCalendarDate } from "../../../../../store/ducks/dashboard.duck";
import { DATE_FORMAT } from "../../../../../utils/DateUtils";
import { selectSelectedOffice } from "../../../../../store/ducks/officeView.duck";
import { selectAllOfficeEntities } from "../../../../../store/ducks/advanceHotDeskingSetup.duck";
import { getRootOffice } from "../../../../../utils/OfficeHelper";
import { loadVisitorBookings } from "./data/visitorBooking.duck";
import { ToggleableOutlineButton } from "../../../../UI/atoms/buttons/ToggleableOutlineButton";
import { TimeRangeSelector } from "../../../meeting-rooms/components/TimeRangeSelector";

export function AddVisitorDialog(props: Props) {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [visitingUser, setVisitingUser] = useState<BasicUserInfo | undefined>();
  const [visitorName, setVisitorName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [dueInHours, setDueInHours] = useState(9);
  const [dueInMinutes, setDueInMinutes] = useState(0);
  const [enableRepeatBooking, setEnableRepeatBooking] = useState(false);
  const [daysToRepeat, setDaysToRepeat] = useState<Moment[]>([]);
  const calendarDate = useSelector(selectCalendarDate);

  const selectedOffice = useSelector(selectSelectedOffice);
  const allOffices = useSelector(selectAllOfficeEntities);

  const repeatableDays = useMemo(() => {
    const date = props.payload?.date || calendarDate || moment();
    let retVal: Moment[] = [];
    for (let i = 1; i < 8; i++) {
      const repeatableDay = date.clone().add(i, 'days');
      if (repeatableDay.day() !== 6 && repeatableDay.day() !== 0) {
        retVal.push(repeatableDay);
      }
    }
    return retVal;
  }, [props.payload.date, calendarDate])

  const rootOffice = useMemo(() => {
    if (selectedOffice?.parentId) {
      return getRootOffice(allOffices, selectedOffice);
    } else if (selectedOffice) {
      return selectedOffice;
    }
  }, [allOffices, selectedOffice]);

  const date = useSelector(selectCalendarDate);

  const updateDayToRepeat = (date: Moment) => {
    if (daysToRepeat.includes(date)) {
      setDaysToRepeat(daysToRepeat.filter(d => d !== date));
    } else {
      setDaysToRepeat([...daysToRepeat, date]);
    }
  }

  const close = () => {
    dispatch(closeDialog());
  }

  const userSelected = (user: BasicUserInfo) => {
    setVisitingUser(user);
  }

  const completeBooking = async () => {
    if (visitingUser && rootOffice) {
      await createVisitorBooking({
        date: date.format(DATE_FORMAT),
        visitingUserId: visitingUser.userId,
        visitorName: visitorName,
        companyName: companyName,
        dueInHours: dueInHours,
        dueInMinutes: dueInMinutes,
        visitingOfficeId: rootOffice.id,
      });
      dispatch(loadVisitorBookings({date: date.format(DATE_FORMAT)}));
    }
    close();
  }

  const onTimeChange = (from: Moment, to?: Moment) => {
    setDueInHours(from.hours());
    setDueInMinutes(from.minutes());
  }

  return (
    <>
      <DialogWrapper>
        <Dialog isOpen={true} onClose={close}>
          <DialogHeader title={'visitor-booking.add-visitor'} />
          <OfficeAndDateContentCard style={{marginBottom: 24}}
                                    office={rootOffice}
                                    date={props.payload.date} />

          <TextField onChange={setVisitorName} value={visitorName} fullWidth={true} label={'visitor-booking.visitor-name'} />
          <TextField onChange={setCompanyName} value={companyName} fullWidth={true} label={'visitor-booking.company-name'} />
          <div>
            <BodyRegular colour={Colours.darkGrey} weight={600} style={{marginBottom: 8}}>{t('visitor-booking.due-in')}</BodyRegular>
            <TimeRangeSelector style={{marginBottom: 16}} hour={dueInHours}
                               minutes={dueInMinutes}
                               onTimeChange={onTimeChange}
                               hideTimeTo={true} hideLabels={true} />
          </div>
          <div style={{marginBottom: 24}}>
            <BodyRegular colour={Colours.darkGrey} weight={600} style={{marginBottom: 8}}>{t('visitor-booking.meeting-with')}</BodyRegular>
            <UserSearchDropDownList closeOnSelect={true} onSelect={userSelected} />
          </div>

          {visitingUser && <UserAvatarAndName firstName={visitingUser.firstName} lastName={visitingUser.lastName} colour={"blue"} />}

          <Switch label={'visitor-booking.repeat-booking'}
                  value={enableRepeatBooking} onChange={setEnableRepeatBooking} style={{marginBottom: 24}} />

          {enableRepeatBooking && <Row>
            {repeatableDays.map((date: Moment, key: number) => (<ToggleableOutlineButton key={key}
                                                                                         size={'small'}
                                                                                         isActive={daysToRepeat.includes(date)}
                                                                                         click={() => updateDayToRepeat(date)}
                                                                                         text={date.format('ddd Mo')} />))}
          </Row>}

          <PrimaryButton text={'visitor-booking.add-visitor'} fullWidth={true} click={completeBooking} />
        </Dialog>
      </DialogWrapper>
    </>
  )
}

interface Props {
  payload: {
    office: OfficeEntity;
    date: Moment;
  };
}

const DialogWrapper = styled.div`
  .dialog {
    min-width: 600px;
  }
  .userAvatarAndName {
    margin-bottom: 24px;
  }
  .switch p {
    font-weight: 600;
    color: ${Colours.darkGrey};
  }
`

const Row = styled.div`
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  display: flex;
  margin-bottom: 32px;
  button {
    padding: 4px 8px;
    min-width: unset;
    flex: 1;
    height: unset;
  }
  .toggleableOutlineButton {
    margin-right: 8px;
  }
  .toggleableOutlineButton:last-child {
    margin-right: 0;
  }
`
