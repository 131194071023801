import React, { useMemo } from 'react';
import { Column, Row } from '../../../UI/atoms/StructuralLayout';
import { CompanyTeamViewSidebar } from "../../../UI/molecules/CompanyTeamViewSidebar";
import { WithCompanyViewSelector } from "../../../../hooks/WithCompanyViewSelector";
import Layout from "../../../UI/organisms/layout/Layout";
import { HeadlineMedium } from "../../../UI/atoms/fonts/Headline";
import { TagWithCount } from "../../../../models/tags.models";
import { Link } from "react-router-dom";
import { TeamContentCard } from "../../../UI/molecules/TeamContentCard";
import { CreateTagContentCard } from "../../../UI/molecules/CreateTagContentCard";
import { TeamContentCardList } from "./ViewComponents";
import { useDispatch, useSelector } from "react-redux";
import {
  selectActiveOfficeId,
  selectCheckedWorkgroupIds,
  setCheckedWorkgroupIds
} from "../../../../store/ducks/companyMovements.duck";
import { selectAllTags } from "../../../../store/ducks/userTags.duck";
import { useTranslation } from "react-i18next";
import { canUserEditGroups } from "../../../../utils/AccessControl";
import { DialogIdentifiers, openDialogWithPayload } from "../../../../store/ducks/dialog.duck";
import { selectCurrentUser } from "../../../../store/ducks/auth.duck";

export function CompanyTagsView() {
  const dispatch = useDispatch();
  WithCompanyViewSelector();
  const currentUser = useSelector(selectCurrentUser);
  const activeOfficeId = useSelector(selectActiveOfficeId);
  const tags = useSelector(selectAllTags);
  const {t} = useTranslation();
  const showEditIcon = canUserEditGroups(currentUser);
  const checkedWorkgroupIds = useSelector(selectCheckedWorkgroupIds);


  const visibleWorkgroups = useMemo(() => {
    if (activeOfficeId) {
      return tags.filter(t => t.officeIds && t.officeIds?.includes(activeOfficeId));
    } else {
      return tags;
    }
  }, [tags, activeOfficeId]);

  const onEditTagClick = (tag: TagWithCount) => {
    dispatch(openDialogWithPayload({
      payload: { tag: tag },
      activeDialog: DialogIdentifiers.CreateTagDialog,
    }));
  }

  const onWorkgroupCheckboxSelect = (tag: TagWithCount, state: boolean) => {
    if (state) {
      dispatch(setCheckedWorkgroupIds([...checkedWorkgroupIds, tag.id]));
    } else {
      dispatch(setCheckedWorkgroupIds(checkedWorkgroupIds.filter(id => id !== tag.id)));
    }
  }

  return (
    <Layout>
      <Row>
        <Column className="companyMovements__movements">
          <HeadlineMedium style={{marginBottom: 24}}>{t('company-view-page.work-groups')}</HeadlineMedium>

          <TeamContentCardList>
            {visibleWorkgroups.map((tag: TagWithCount, key: number) => (
              <Link to={`/whereabouts/company/tag/${tag.id}`} key={key}>
                <TeamContentCard label={tag.label}
                                 showEditIcon={showEditIcon}
                                 memberCount={tag.memberCount}
                                 officeIds={tag.officeIds}
                                 onEditClick={() => onEditTagClick(tag)}
                                 checkboxEnabled={showEditIcon}
                                 isChecked={checkedWorkgroupIds.includes(tag.id)}
                                 onCheckboxChecked={(state: boolean) => onWorkgroupCheckboxSelect(tag, state)} />
              </Link>
            ))}
            <CreateTagContentCard />
          </TeamContentCardList>
        </Column>
        <CompanyTeamViewSidebar />
      </Row>
    </Layout>
  )
}
