import React, { useCallback, useEffect, useState } from 'react';
import styled from "styled-components/macro";
import jwt_decode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { AuthType, GetCurrentAuthType, stepUpAzureCalendarPermissions, teamsLogin } from "../../../../utils/AuthUtils";
import { selectCurrentUser, setCalendarSyncActive } from "../../../../store/ducks/auth.duck";
import { BodyVerySmall } from "../../../UI/atoms/fonts/Body";
import { failureNotification, warningNotification } from "../../../../store/ducks/notification.duck";
import Colours from "../../../UI/atoms/Colours";
import { DialogIdentifiers, openDialog } from "../../../../store/ducks/dialog.duck";
import { SimpleSpinner } from "../../../UI/atoms/SimpleSpinner";
import { isInTeams } from "../../../../utils/TeamsUtils";
import { microsoftRequest } from "../../../../services/EnvironmentVariables";

enum SyncStatus {
  Active, Failed, Pending, Disabled, NA
}

const CALENDAR_ACCESS = 'CALENDARS.READWRITE';
const MAILBOX_SETTINGS_ACCESS = 'MAILBOXSETTINGS.READ';

function getColour(status: SyncStatus) {
  switch (status) {
    case SyncStatus.Active: return Colours.green;
    case SyncStatus.Pending: return Colours.orange;
    case SyncStatus.Disabled: return Colours.orange;
    case SyncStatus.Failed: return Colours.red;
    default: return Colours.darkGrey;
  }
}

function getMessage(status: SyncStatus) {
  switch (status) {
    case SyncStatus.Active: return 'Calendar sync active';
    case SyncStatus.Pending: return 'Calendar sync loading';
    case SyncStatus.Failed: return 'No calendar sync';
    case SyncStatus.Disabled: return 'Calendar sync disabled';
    default: return 'Status unknown';
  }
}

export function MicrosoftCalendarSyncActiveIndicator(props: Props) {
  const authType = GetCurrentAuthType();
  const user = useSelector(selectCurrentUser);
  const {instance, accounts} = useMsal();
  const [status, setStatus] = useState(SyncStatus.Pending);
  const dispatch = useDispatch();

  const checkStatus = useCallback((user, authType) => {
    if (authType !== AuthType.Microsoft) {
      dispatch(setCalendarSyncActive(false));
      return SyncStatus.NA;
    }
    if (!user?.enableOutlookSync) {
      dispatch(setCalendarSyncActive(false));
      return SyncStatus.Disabled;
    }
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      const parsedToken = jwt_decode(accessToken) as any;
      const scopes = (parsedToken?.scp ?? '').toUpperCase();
      // console.log('idToken', localStorage.getItem('idToken'))
      // console.log('accessToken', accessToken)
      // console.log(scopes)
      if (scopes.includes(CALENDAR_ACCESS) || scopes.includes(MAILBOX_SETTINGS_ACCESS)) {
        dispatch(setCalendarSyncActive(true));
        return SyncStatus.Active;
      }
    }
    dispatch(setCalendarSyncActive(false));
    return SyncStatus.Failed;
  }, [dispatch]);

  useEffect(() => {
    setTimeout(() => {
      setStatus(checkStatus(user, authType));
    }, 1000);
  }, [authType, checkStatus, user])

  const onClick = async () => {
    if (status === SyncStatus.Pending) {
      checkStatus(user, authType);
    }
    if (status === SyncStatus.Active || status === SyncStatus.NA) {
      return
    }
    if (status === SyncStatus.Disabled) {
      dispatch(openDialog(DialogIdentifiers.AzureCalendarSyncDisabledDialog));
      return;
    }
    let account;
    if (instance.getActiveAccount()) {
      account = instance.getActiveAccount();
    }
    if (!account && accounts.length > 0) {
      account = accounts[0];
    }
    if (accounts.length > 1 && account) {
      const message = `Multiple Microsoft accounts found, using ${account.username}`;
      console.log(message);
      dispatch(warningNotification(message));
    }

    if (isInTeams()) {
      setStatus(SyncStatus.Pending);
      await teamsLogin(instance, microsoftRequest.calendarScopes, dispatch, true, true);
    } else {
      if (account) {
        setStatus(SyncStatus.Pending);
        stepUpAzureCalendarPermissions(instance, account, dispatch);
      } else {
        dispatch(failureNotification('Failed to find Microsoft account, try to logout and login.'));
      }
    }
  }

  if (authType !== AuthType.Microsoft || status === SyncStatus.NA) return null;

  return <Row style={props.style ?? {}}>
    {status === SyncStatus.Pending ? <SimpleSpinner size={22} /> : <TrafficLightIndicator colour={getColour(status)} />}
    <BodyVerySmall weight={600} colour={Colours.darkGrey}>{getMessage(status)}</BodyVerySmall>
    {(status === SyncStatus.Failed || status === SyncStatus.Disabled) && <TurnOnButton weight={600} colour={Colours.darkGrey} onClick={onClick}>Turn on</TurnOnButton>}
  </Row>
}

interface Props {
  style?: any;
}

const TrafficLightIndicator = styled.div<any>`
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-color: ${props => props.colour};
  margin-right: 12px;
`

const Row = styled.div<any>`
  background: ${Colours.veryLightGrey};
  border-radius: 50px;
  width: 100%;
  display: flex;
  padding: 12px;
  align-items: center;
  * {
    user-select: none ;
  }
`

const TurnOnButton = styled<any>(BodyVerySmall)<any>`
  text-decoration: underline;
  cursor: pointer;
  margin-left: auto;
`
