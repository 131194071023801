import React from 'react';
import styled from "styled-components/macro";
import Calendar from "../../../UI/molecules/calendar/Calendar";
import moment, { Moment } from "moment";
import { useDispatch, useSelector } from "react-redux";
import { NumberOfPeopleOption } from "./NumberOfPeopleOption";
import {
  refreshBookings,
  selectedOffice,
  selectIsSidebarOpen,
  setOffice,
  setSidebarOpen
} from "../models/room-finder.duck";
import { MeetingRoomFacilitiesOptions } from "./MeetingRoomFacilitiesOptions";
import { OfficeEntity } from "../../../../services/AdvanceHotDeskingService";
import { BodyRegular } from "../../../UI/atoms/fonts/Body";
import { selectActiveDay, setActiveDay } from "../../../../store/ducks/editMovements.duck";
import { selectAllOfficesForMeetingRooms } from "../../../../store/ducks/advanceHotDeskingSetup.duck";
import {
  MicrosoftCalendarSyncActiveIndicator
} from "../../team-movements/components/MicrosoftCalendarSyncActiveIndicator";
import { addOrReplaceQueryParam } from "../../../../utils/UrlUtils";
import { DATE_FORMAT } from "../../../../utils/DateUtils";
import IconButton from "../../../UI/molecules/icon-button/IconButton";
import { IconTypes } from "../../../UI/atoms/icon/Icon";
import { OfficeDropDownList } from "../../../UI/organisms/OfficeDropDownList";
import { tablet } from "../../../UI/atoms/MediaQueries";
import { isInTeams } from "../../../../utils/TeamsUtils";

export function RoomFinderSidebar(props: Props) {
  const dispatch = useDispatch();
  const calendarDate = useSelector(selectActiveDay);
  const office = useSelector(selectedOffice);
  const allOfficesForMeetingRooms = useSelector(selectAllOfficesForMeetingRooms);
  const isSidebarOpen = useSelector(selectIsSidebarOpen)

  const isMobileSidebarOpen = window.innerWidth >= 768 || isSidebarOpen;

  const onOfficeSelected = (office: OfficeEntity) => {
    dispatch(setOffice(office));
  }

  const onDateSelected = (date: Moment) => {
    addOrReplaceQueryParam('date', date.format(DATE_FORMAT))
    dispatch(setActiveDay(date));
    dispatch(refreshBookings())
  }

  return (
    <>
      {isMobileSidebarOpen &&
        <Container isInTeams={isInTeams()}>
          <IconButton icon={IconTypes.Close}
                      size={"large"} className={"sidebar__closeBtn"}
                      onClick={() => dispatch(setSidebarOpen(false))} />
          <Calendar onDateSelect={onDateSelected}
                    initialDate={calendarDate ?? moment()}
                    activeDate={calendarDate} style={{margin: '0 auto 24px auto'}} />
          <MicrosoftCalendarSyncActiveIndicator style={{marginBottom: 24}} />
          <OfficeDropDownListWrapper>
            <BodyRegular weight={700} style={{marginBottom: 8}}>Office</BodyRegular>
            <OfficeDropDownList onSelect={onOfficeSelected} offices={allOfficesForMeetingRooms} disabled={false} preSelectedOffice={office?.id} />
          </OfficeDropDownListWrapper>
          <NumberOfPeopleOption />
          <MeetingRoomFacilitiesOptions />
        </Container>}
    </>
  )
}

interface Props {
}

const Container = styled.div<any>`
  padding: 32px;
  top: 0;
  left: 0;
  background: #FFFFFF;
  box-shadow: 0 9px 22px rgba(46, 74, 148, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.05);
  z-index: 999999;
  overflow-y: auto;

  height: 100vh;
  position: fixed;
  width: 100vw;

  @media (${tablet}) {
    min-width: 400px;
    min-height: 600px;
    height: ${props => props.isInTeams ? 'calc(100vh - 56px)' : "calc(100vh - 116px)"};
    width: unset;
    position: relative;
    border-radius: 24px 0 0 0;
    margin-top: ${props => props.isInTeams ? 0 : -56};
  }
  
  .sidebar__closeBtn {
    position: absolute;
    right: 16px;
    top: 16px;

    @media (${tablet}) {
      display: none;
    }
  }
`

const OfficeDropDownListWrapper = styled.div`
  margin: 16px 0;
  width: 100%;
`
