import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Button } from '../../../UI/atoms/buttons/Button';
import { BodyLarge, BodyRegular, BodySmall } from '../../../UI/atoms/fonts/Body';
import { HeadlineLarge } from '../../../UI/atoms/fonts/Headline';
import { Section } from '../../../UI/organisms/layout/Layout';
import TextField from '../../../UI/atoms/TextField';
import { Permission } from '../../../../models/user.models';
import { updatePermissions } from '../../../../services/ManagementService';
import { selectCurrentUser } from '../../../../store/ducks/auth.duck';
import { failureNotification, successNotification } from '../../../../store/ducks/notification.duck';
import SelectableContentCard from '../components/SelectableContentCard';
import DeleteUserDialog from "../../../dialogs/delete-user-dialog/DeleteUserDialog";
import { setDeleteUserDialogOpen } from "../../../../store/ducks/management.duck";
import { GoBackButton } from "../../../UI/atoms/buttons/GoBackButton";
import {
  canChangeUsersDefaultOffice,
  canChangeUsersRole,
  isCompanyAdminUser,
  isOfficeAdminUser,
  isTeamAdminUser
} from '../../../../utils/AccessControl';
import Colours from "../../../UI/atoms/Colours";
import SelectLocationSection from "../components/SelectLocationSection";
import {
  fetchEditUser,
  resetEditUser,
  selectEdittingUser,
  selectHasEditUserErrored,
  selectIsEditUserLoading,
  updateUser
} from '../../../../store/ducks/editUser.duck';
import UsersLineReports from '../components/UsersLineReports';
import { LoadingSpinner } from '../../../UI/atoms/LoadingSpinner';
import { Center } from '../../../UI/atoms/StructuralLayout';
import { AdditionalResponsibilitiesSection } from "../components/AdditionalResponsibilitiesSection";
import { UserHolidayAllowanceSection } from "../components/UserHolidayAllowanceSection";
import { selectAllTeams, selectConfig } from "../../../../store/ducks/config.duck";
import { app } from "@microsoft/teams-js";
import { teamsTabs, uiUrl } from "../../../../services/EnvironmentVariables";
import { deepLinkTo, isInTeams } from "../../../../utils/TeamsUtils";
import { useTranslation } from "react-i18next";
import { ChangeUsersTeamDropDownComponent } from "./edit-user-page/ChangeUsersTeamDropDownComponent";
import AccessRequired from "../components/AccessRequired";

export default function EditUserPage() {
  const params: any = useParams();
  const dispatch = useDispatch();
  const editingUser = useSelector(selectEdittingUser);
  const isLoading = useSelector(selectIsEditUserLoading);
  const teams = useSelector(selectAllTeams);
  const currentUser = useSelector(selectCurrentUser);
  const config = useSelector(selectConfig);
  const hasEditUserErrored = useSelector(selectHasEditUserErrored);
  const {t} = useTranslation();
  const editingUserId = params.userId;

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [permission, setPermission] = useState(Permission.Basic);
  const [holidayAllowance, setHolidayAllowance] = useState(0);
  const [subEntityId, setSubEntityId] = useState<string | null>(null);

  const canLoadUserToEdit = useMemo(() => {
    return isCompanyAdminUser(currentUser) || isOfficeAdminUser(currentUser) || isTeamAdminUser(currentUser);
  }, [currentUser]);

  useEffect(() => {
    if (isInTeams()) {
      app.getContext().then((context: app.Context) => {
        setSubEntityId(context.page.subPageId ?? null);
      })
    }

    return () => {
      dispatch(resetEditUser());
    }
  }, [dispatch]);

  useEffect(() => {
    if (!editingUser && editingUserId && canLoadUserToEdit) {
      dispatch(fetchEditUser(editingUserId));
    }
  }, [editingUser, editingUserId, canLoadUserToEdit, dispatch]);

  useEffect(() => {
    if (editingUser) {
      setFirstName(editingUser.firstName);
      setLastName(editingUser.lastName);
      setPermission(editingUser.role);
    }
  }, [editingUser]);

  useEffect(() => {
    setHolidayAllowance(editingUser?.holidayAllowance ?? config?.companyHolidayAllowance ?? 0);
  }, [config, editingUser]);

  const saveChanges = async () => {
    if (!editingUser) return;

    if (firstName === editingUser.firstName && lastName === editingUser.lastName &&
        permission === editingUser.role && holidayAllowance === editingUser.holidayAllowance) {
      dispatch(failureNotification('Nothing to update'))
      return;
    }

    try {
      dispatch(updateUser({
        ...editingUser,
        firstName: firstName,
        lastName: lastName,
        holidayAllowance: holidayAllowance,
      }))
      await Promise.all([
        updatePermissions(editingUser.userId, permission)]
      )
      dispatch(successNotification('User updated'))
    } catch (e: any) {
      dispatch(failureNotification('Error. User NOT updated'))
    }
  }

  const onLocationChange = async () => {
    dispatch(fetchEditUser(params.userId));
  }

  const onHolidayAllowanceUpdated = (val: number) => {
    setHolidayAllowance(val);
  }

  const onBackButtonPressed = () => {
    if (subEntityId) {
      // Deep link back to settings page to reset subEntityId
      deepLinkTo(teamsTabs.settings, `${uiUrl}/manage`);
    } else {
      // Else just go back
      window.history.back();
    }
  }

  if (!canLoadUserToEdit || hasEditUserErrored) {
    return <Center>
      <AccessRequired visible={true} />
    </Center>
  }

  if (!editingUser || !currentUser) {
    return <Center>
      <LoadingSpinner hideBorder={true} />
    </Center>;
  }

  return (
    <React.Fragment>
      {isLoading && <LoadingSpinner fullScreen={true} />}
      <DeleteUserDialog userInfo={editingUser}/>
      <div className="management">
        <GoBackButton onClick={onBackButtonPressed}/>
        <HeadlineLarge className="editTeam__header management__title">{t('settings-page.edit-user.title')}</HeadlineLarge>
        <TextField dataTest="editUserPage-firstName-input" disabled={!canLoadUserToEdit} label="textinput.firstname"
                   onChange={(val) => setFirstName(val)} value={firstName}/>
        <TextField dataTest="editUserPage-lastName-input" disabled={!canLoadUserToEdit} label="textinput.lastname"
                   onChange={(val) => setLastName(val)} value={lastName}/>

        <Section hideBorder={true}>
          <BodyLarge colour={Colours.blue}>{t('settings-page.edit-user.email-address')}</BodyLarge>
          <BodyRegular className="management__row">{editingUser?.email}</BodyRegular>
          <Button dataTest="editUserPage-delete-button"
                  disabled={!isCompanyAdminUser(currentUser)}
                  text="button.delete-user"
                  type="grey"
                  onClick={() => dispatch(setDeleteUserDialogOpen(true))}/>
        </Section>

        <ChangeUsersTeamDropDownComponent editingUser={editingUser} teams={teams}  />

        {currentUser?.companyEntity.lineManagementEnabled && <UsersLineReports user={editingUser} />}

        <SelectLocationSection disabled={!canChangeUsersDefaultOffice(currentUser)} user={editingUser}  onLocationChange={onLocationChange} />


        <UserPermissionSelection disabled={!canChangeUsersRole(currentUser)} permission={permission} userId={editingUser?.userId}
                                 role={editingUser?.role} onPermissionChanged={setPermission}/>

        {currentUser?.companyEntity.enableHolidayBooking &&
          <UserHolidayAllowanceSection user={editingUser}
                                       holidayAllowance={holidayAllowance}
                                       onHolidayAllowanceChange={onHolidayAllowanceUpdated} />}

        <AdditionalResponsibilitiesSection user={editingUser} />

        <Button dataTest="editUserPage-save-button" disabled={!canLoadUserToEdit} className="management__loneControl"
                text="button.save-changes" size="large" type="primary" onClick={saveChanges}/>
      </div>
    </React.Fragment>
  );
}

function UserPermissionSelection(props: { permission: Permission, userId: string, role: any, onPermissionChanged: any, disabled?: boolean }) {
  const current = useSelector(selectCurrentUser);
  const {t} = useTranslation();
  const isCompanyAdmin = current?.role === Permission.CompanyAdmin;
  const isOfficeAdmin = current?.role === Permission.OfficeAdmin;
  const isTeamAdmin = current?.role === Permission.TeamAdmin;

  const setPermission = async (permission: Permission) => {
    if (!props.disabled) {
      props.onPermissionChanged(permission);
    }
  }

  if (!props.role) {
    return null;
  }

  return (
    <Section>
      <BodyLarge colour={Colours.blue}>{t('settings-page.edit-user.permissions')}</BodyLarge>
      <div className="editUse__permissions">
        <div>
          <SelectableContentCard disabled={props.disabled}
                                 selected={props.permission === Permission.Basic}
                                 title="settings-page.edit-user.standard-user"
                                 onClick={() => setPermission(Permission.Basic)}>
            <ul>
              <li><BodySmall>{t('settings-page.edit-user.basic-permissions')}</BodySmall></li>
            </ul>
          </SelectableContentCard>

          {isCompanyAdmin || isTeamAdmin || isOfficeAdmin ?
            <SelectableContentCard disabled={props.disabled}
                                   selected={props.permission === Permission.TeamAdmin}
                                   title="settings-page.edit-user.team-admin"
                                   onClick={() => setPermission(Permission.TeamAdmin)}>
              <ul>
                <li><BodySmall>{t('settings-page.edit-user.team-admin-permissions-1')}</BodySmall></li>
                <li><BodySmall>{t('settings-page.edit-user.team-admin-permissions-2')}</BodySmall></li>
                <li><BodySmall>{t('settings-page.edit-user.team-admin-permissions-3')}</BodySmall></li>
              </ul>
            </SelectableContentCard>
            : null}

          {isCompanyAdmin ?
            <SelectableContentCard disabled={props.disabled} selected={props.permission === Permission.OfficeAdmin}
                                   title="settings-page.edit-user.office-admin" onClick={() => setPermission(Permission.OfficeAdmin)}>
              <ul>
                <li><BodySmall>{t('settings-page.edit-user.office-admin-permissions-1')}</BodySmall></li>
                <li><BodySmall>{t('settings-page.edit-user.office-admin-permissions-2')}</BodySmall></li>
                <li><BodySmall>{t('settings-page.edit-user.office-admin-permissions-3')}</BodySmall></li>
                <li><BodySmall>{t('settings-page.edit-user.office-admin-permissions-4')}</BodySmall></li>
              </ul>
            </SelectableContentCard>
            : null}

          {isCompanyAdmin ?
            <SelectableContentCard disabled={props.disabled} selected={props.permission === Permission.CompanyAdmin}
                                   title="settings-page.edit-user.company-admin" onClick={() => setPermission(Permission.CompanyAdmin)}>
              <ul>
                <li><BodySmall>{t('settings-page.edit-user.company-admin-permissions-1')}</BodySmall></li>
                <li><BodySmall>{t('settings-page.edit-user.company-admin-permissions-2')}</BodySmall></li>
                <li><BodySmall>{t('settings-page.edit-user.company-admin-permissions-3')}</BodySmall></li>
              </ul>
            </SelectableContentCard>
            : null}

        </div>
      </div>
    </Section>
  )
}
