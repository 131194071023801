import React from 'react';
import { BodyXXSmall } from "../fonts/Body";
import Colours from "../Colours";
import styled from "styled-components/macro";
import { useDispatch } from "react-redux";
import { redirectTo } from "../../../../store/ducks/auth.duck";
import { useTranslation } from "react-i18next";

interface Props {
  onClick?: any;
  url?: string;
  text?: string;
}

export function GoBackButton(props: Props) {
  const text = props.text ?? 'Back';
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const onClick = () => {
    if (props.onClick) {
      props.onClick();
    } else if (props.url) {
      dispatch(redirectTo(props.url));
    } else {
      window.history.back();
    }
  }
  return (
    <GoBackButtonContainer className="goBackButton" onClick={() => onClick()} data-test="goBackBtn">
      <GoBackButtonIcon className={`icon-Previous`.trim()}/>
      <BodyXXSmall weight={600} colour={Colours.blue}>{t(text)}</BodyXXSmall>
    </GoBackButtonContainer>
  );
}

export const GoBackButtonContainer = styled.div`
  padding: 0 12px 0 0;
  display: inline-flex !important;
  cursor: pointer;
  align-self: flex-start;
  transition: background-color .25s;
  margin-bottom: 24px;
  align-items: center;
  border-radius: 64px;
  p {
    color: ${Colours.darkGrey};
  }
  &:hover {
    background-color: ${Colours.veryLightGrey};
    p {
      color: ${Colours.blue};
    }
    i {
     background-color: ${Colours.white};
      color: ${Colours.blue};
      border-color: ${Colours.blue};
      // border: 3px solid ${Colours.blue};
    }
  }
`

export const GoBackButtonIcon = styled.i`
  margin-right: 12px;
  border: solid 1px ${Colours.darkGrey};
  color: ${Colours.darkGrey};
  border-radius: 50%;
  padding: 6px;
  font-size: 16px;
  font-weight: 600;
  transition: padding .1s ease-in-out, color .1s ease-in-out, border .1s ease-in-out;
`
