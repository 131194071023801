import React from 'react';
import { BodyLarge, BodyRegular, BodyXXSmall } from "../../atoms/fonts/Body";
import { useTranslation } from "react-i18next";
import { GreenPill } from "../../atoms/Pill";
import { HeadlineSmall } from "../../atoms/fonts/Headline";
import Colours from "../../atoms/Colours";

interface Props {
  style?: any;
}

export function AzureScimIntegrationMessage(props: Props) {
  const {t} = useTranslation();

  return (
    <div style={props?.style ?? {}} className={'azureScimIntegration'}>
      <GreenPill style={{marginBottom: 16}}><BodyXXSmall>{t('settings.azure-scim.medium')}</BodyXXSmall></GreenPill>
      <HeadlineSmall style={{marginBottom: 4}}>{t('settings.azure-scim.title')}</HeadlineSmall>
      <BodyRegular style={{marginBottom: 4}} weight={600}>{t('settings.azure-scim.subtitle')}</BodyRegular>
      <BodyRegular style={{marginBottom: 16}}>{t('settings.azure-scim.description')}</BodyRegular>
    </div>
  )
}

export function AzureScimIntegrationSettingMessage(props: Props) {
  const {t} = useTranslation();

  return (
    <div style={props?.style ?? {}} className={'azureScimIntegration'}>
      <BodyLarge colour={Colours.blue} style={{marginBottom: 4}}>{t('settings.azure-scim.title')}</BodyLarge>
      <BodyRegular style={{marginBottom: 4}} weight={600}>{t('settings.azure-scim.subtitle')}</BodyRegular>
      <BodyRegular style={{marginBottom: 16}}>{t('settings.azure-scim.description')}</BodyRegular>
    </div>
  )
}
