import { agent } from './agent';
import { Moment } from "moment";
import { DATE_FORMAT } from "../utils/DateUtils";
import { Period, SideOfDay } from "../models/movements.models";

export async function getDesksInOffice(officeId: number): Promise<Desk[]> {
  return await agent.requests.get(`/api/desks/${officeId}`);
}

export async function getDeskReservationsInOffice(officeId: number, date: Moment): Promise<DeskReservations[]> {
  return await agent.requests.get(`/api/desks/usage/${officeId}/${date.format(DATE_FORMAT)}`);
}

export async function getAllAvailableDesksInOffice(officeId: number, date: Moment, period: Period): Promise<Desk[]> {
  return await agent.requests.get(`/api/desks/available/${officeId}/${date.format(DATE_FORMAT)}/${period}`);
}

export async function isDeskAvailable(deskId: number, date: Moment, period: Period): Promise<boolean> {
  return await agent.requests.get(`/api/desk/available/check?deskId=${deskId}&date=${date.format(DATE_FORMAT)}&period=${period}`);
}

export async function getDesk(deskId: number): Promise<Desk> {
  return await agent.requests.get(`/api/desk/${deskId}`);
}

export async function makeReservationRequest(deskId: number, sideOfDay: SideOfDay, date: Moment, requestParkingSpace: boolean): Promise<any> {
  return await agent.requests.post(`/api/desks/reserve`, {
    deskId: deskId,
    sideOfDay: sideOfDay,
    date: date.format(DATE_FORMAT),
    requestParkingSpace: requestParkingSpace,
  });
}

export async function cancelReservationRequest(deskId: number, sideOfDay: SideOfDay, date: Moment, userId: string): Promise<DeskReservations[]> {
  return await agent.requests.post(`/api/desks/cancel`, {
    deskId: deskId,
    sideOfDay: sideOfDay,
    date: date.format(DATE_FORMAT),
    userId: userId,
  });
}

export async function createDesk(desk: Desk): Promise<Desk> {
  return await agent.requests.post(`/api/desk`, desk);
}

export async function updateDesk(desk: Desk): Promise<Desk> {
  return await agent.requests.put(`/api/desk`, desk);
}

export async function updateDeskActiveState(deskIds: number[], state: boolean): Promise<Desk> {
  return await agent.requests.put(`/api/desk/state`, {deskIds: deskIds, state: state});
}

export async function deleteDeskRequest(deskId: number): Promise<Desk> {
  return await agent.requests.del(`/api/desk/${deskId}`);
}

export async function checkDeskUsage(deskId: number): Promise<DeskUsageSummary> {
  return await agent.requests.get(`/api/desk/check/${deskId}`);
}


export interface Desk {
  label: string;
  officeId: number;
  rootOfficeId: number;
  id: number;
  active: boolean;
  x: number;
  y: number;
  width: number;
  height: number;
  rotate?: number;
  rotation?: number;

  teamIds: string[];
  users: AssignedUsers[];
  workgroupIds: number[];
  canUserBook?: boolean;
}

export interface AssignedUsers {
  firstname: string;
  lastname: string;
  userId: string;
}

export interface EditableDesk extends Desk {
  editted?: boolean;
}

export interface DeskReservations {
  id: number;
  userId: string;
  date: string;

  amStatusId?: number;
  pmStatusId?: number;
  amOfficeId?: number;
  pmOfficeId?: number;
  amDeskId?: number;
  pmDeskId?: number;
  amParkingSpaceId?: number;
  pmParkingSpaceId?: number;

  amStatus: string;
  pmStatus: string;

  amDeskLabel: string;
  pmDeskLabel: string;

  amParkingSpaceLabel: string;
  pmParkingSpaceLabel: string;

  keyHolder?: boolean;
  firstAider?: boolean;
  fireWarden?: boolean;
  mentalHealthAider?: boolean;

  firstName: string;
  lastName: string;
}

export interface DeskUsageSummary {
  numberOfTimesBooked: number;
  floorPlans: boolean;
}

export interface WhereaboutsBookingError {
  date: string;
  error?: string;
  type: WhereaboutsBookingErrorType;
}

export enum WhereaboutsBookingErrorType {
  OUT_OF_BOOKING_RANGE= "OUT_OF_BOOKING_RANGE",
  NO_PARKING_SPACE = "NO_PARKING_SPACE",
  NOT_BOOKABLE_LOCATION = "NOT_BOOKABLE_LOCATION",
  OFFICE_FULL = "OFFICE_FULL",
  NO_ACCESS_TO_DESK = "NO_ACCESS_TO_DESK",
  CANNOT_BOOK_OVER_APPROVED_HOLIDAY = "CANNOT_BOOK_OVER_APPROVED_HOLIDAY",
}
