import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit'
import { AppState } from "../../../../store/state/app.state";
import { MeetingRoom, MeetingRoomBooking, MeetingRoomFacilities, MeetRoomCapacityBands } from "./MeetingRooms";
import { OfficeEntity } from "../../../../services/AdvanceHotDeskingService";
import { getMeetingRoomBookings, getMeetingRooms } from "../../../../services/MeetingRoomsService";
import { findMeetingRoomInMeetingRooms } from "./MeetingRoomHelper";
import { selectActiveDay } from "../../../../store/ducks/editMovements.duck";


export interface RoomFinderState {
  meetingRoomCapacityBand: MeetRoomCapacityBands;
  selectedOffice?: OfficeEntity;
  meetingRoomFacilities: MeetingRoomFacilities[];
  meetingRooms: MeetingRoom[];
  meetingRoomBooking: MeetingRoomBooking[];
  sidebarOpen: boolean;
  loading: boolean;
}

export const initialRoomFinderState: RoomFinderState = {
  meetingRoomCapacityBand: MeetRoomCapacityBands.ANY,
  meetingRoomFacilities: [],
  meetingRooms: [],
  meetingRoomBooking: [],
  sidebarOpen: false,
  loading: false,
}

export const refreshBookings: any = createAsyncThunk('roomFinder/refreshBookings',
  async(params: any, thunkAPI) => {
    const appState = thunkAPI.getState() as AppState;
    const office = appState.roomFinder.selectedOffice;
    const date = appState.editMovements.activeDay;

    if (office && date) {
      return {
        bookings: await getMeetingRoomBookings(office, date),
      }
    }

    return {
      bookings: appState.roomFinder.meetingRoomBooking
    }
  }
)

export const loadAllMeetingRoomsForOffice: any = createAsyncThunk(
  'roomFinder/loadAllMeetingRoomsForOffice',
  async (params: {office: OfficeEntity, capacityBands: MeetRoomCapacityBands, facilities: MeetingRoomFacilities[]}, thunkAPI) => {
    const {office, capacityBands, facilities} = params;

    let filteredMeetingRooms: MeetingRoom[] = [];

    if (office) {
      filteredMeetingRooms = await getMeetingRooms(office.id, capacityBands, facilities)
    }

    return {
      meetingRooms: filteredMeetingRooms,
    }
  }
)

export const loadMeetingRoomBookings: any = createAsyncThunk(
  'roomFinder/loadMeetingRoomBookings',
  async (params: {}, thunkAPI) => {
    const state = thunkAPI.getState() as AppState;
    const office = selectedOffice(state);
    const date = selectActiveDay(state);

    if (!office || !date) {
      return { bookings: [] };
    }

    let filteredBookings: MeetingRoomBooking[] = [];

    if (office) {
      filteredBookings = await getMeetingRoomBookings(office, date);
    }

    return {
      bookings: filteredBookings,
    }
  }
)

const roomFinderSlice = createSlice({
  name: 'roomFinder',
  initialState: initialRoomFinderState,
  reducers: {
    reset: () => initialRoomFinderState,
    setMeetingRoomBand: (state, action) => ({...state, meetingRoomCapacityBand: action.payload}),
    setMeetingRoomFacilities: (state, action) => ({...state, meetingRoomFacilities: action.payload}),
    setOffice: (state, action) => ({...state, selectedOffice: action.payload}),
    setSidebarOpen: (state, action) => ({...state, sidebarOpen: action.payload}),
    setMeetingRoomBookings: (state, action) => ({...state, meetingRoomBooking: action.payload}),
  },
  extraReducers: {
    [loadMeetingRoomBookings.pending]: (state, action) => ({...state, loading: true, meetingRoomBooking: []}),
    [loadMeetingRoomBookings.reject]: (state, action) => ({...state, loading: false,}),
    [loadMeetingRoomBookings.fulfilled]: (state, action) => ({
      ...state,
      meetingRoomBooking: action.payload.bookings,
      loading: false,
    }),

    [refreshBookings.pending]: (state) => ({...state, loading: true,}),
    [refreshBookings.reject]: (state) => ({...state, loading: false,}),
    [refreshBookings.fulfilled]: (state, action) => ({
      ...state,
      meetingRoomBooking: action.payload.bookings,
      loading: false,
    }),

    [loadAllMeetingRoomsForOffice.pending]: (state) => ({...state, loading: true,}),
    [loadAllMeetingRoomsForOffice.reject]: (state) => ({...state, loading: false,}),
    [loadAllMeetingRoomsForOffice.fulfilled]: (state, action) => ({
      ...state,
      meetingRooms: action.payload.meetingRooms,
      loading: false,
    }),
  }
})

export default roomFinderSlice.reducer;
export const {
  reset,
  setMeetingRoomBand,
  setMeetingRoomFacilities,
  setOffice,
  setSidebarOpen,
  setMeetingRoomBookings,
} = roomFinderSlice.actions;

// Selectors
export const selectedMeetingRoomCapacityBand = (state: AppState) => state.roomFinder.meetingRoomCapacityBand;
export const selectedMeetingRoomFacilities = (state: AppState) => state.roomFinder.meetingRoomFacilities;
export const selectedOffice = (state: AppState) => state.roomFinder.selectedOffice;
export const selectFilteredMeetingRooms = (state: AppState) => state.roomFinder.meetingRooms;
export const filteredMeetingRoomBookings = (state: AppState) => state.roomFinder.meetingRoomBooking;
export const isLoading = (state: AppState) => state.roomFinder.loading;
export const selectIsSidebarOpen = (state: AppState) => state.roomFinder.sidebarOpen;
export const selectMeetingRoomById = (state: AppState, roomId: string) => {
  return findMeetingRoomInMeetingRooms(state.meetingRoomSettings.meetingRooms, roomId);
}
