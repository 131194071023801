import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from "../../store/ducks/dialog.duck";
import DialogHeader from "./DialogHeader";
import Dialog from "../UI/molecules/Dialog";
import { OutlineButton } from "../UI/atoms/buttons/OutlineButton";
import { isCompanyAdminUser } from "../../utils/AccessControl";
import { selectCurrentUser } from "../../store/ducks/auth.duck";
import AccessRequired from "../pages/settings/components/AccessRequired";
import { LoadingOverlay } from "../UI/molecules/LoadingOverlay";
import TextField from "../UI/atoms/TextField";
import { Switch } from "../UI/atoms/Switch";
import { Desk, getDesk, updateDesk } from "../../services/DeskBookingService";
import { failureNotification, successNotification } from "../../store/ducks/notification.duck";
import { fetchDesks } from "../../store/ducks/deskBooking.duck";

interface Props {
  payload?: {deskId: number};
}

export default function EditDeskDialog(props: Props) {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const hasAccess = useMemo(() => isCompanyAdminUser(currentUser), [currentUser]);
  const [loading, setLoading] = useState(false);
  const [hasRequested, setHasReqested] = useState(false);
  const [label, setLabel] = useState('');
  const [desk, setDesk] = useState<Desk | undefined>(undefined);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (props.payload && !hasRequested && !loading) {
      setLoading(true);
      setHasReqested(true);
      getDesk(props.payload?.deskId)
        .then((desk: Desk) => {
          setLabel(desk.label);
          setIsActive(desk.active);
          setDesk(desk);
        })
        .catch(() => {
          dispatch(failureNotification('Failed to load desk'));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [dispatch, loading, props.payload, hasRequested]);

  const onUpdateClick = async () => {
    if (desk) {
      setLoading(true);
      await updateDesk({
        ...desk,
        label: label,
        active: isActive,
      });
      dispatch(fetchDesks(desk.officeId));
      dispatch(successNotification('Desk updated'));
      close();
    }
  }

  const close = () => {
    setLoading(false);
    dispatch(closeDialog());
  }

  return (
    <Dialog isOpen={true} onClose={close} showLogo={true}>
      <DialogHeader title="dialog-headers.update-desk" />
      <AccessRequired teamAdminsRequired={false} companyAdminsRequired={true} visible={!hasAccess} />

      {loading && <LoadingOverlay />}

      {hasAccess && <div>
        <TextField label={"Desk label"} onChange={setLabel} fullWidth={true} value={label} />
        <Switch style={{marginBottom: 32}} value={isActive} label={"Is active"} onChange={setIsActive} />
        <OutlineButton click={onUpdateClick} text="button.save-changes" fullWidth={true} disabled={!hasAccess} />
      </div>}

    </Dialog>
  )
}
