import { createAsyncThunk, createSlice, PayloadAction, } from '@reduxjs/toolkit'
import { AppState } from '../state/app.state';
import moment, { Moment } from 'moment';
import { OfficeEntity } from "../../services/AdvanceHotDeskingService";
import { Movements, requestUsersWeeklyMovements } from "../../services/Movements";
import { selectOffice } from "./officeView.duck";
import { refreshAllMovements } from "./companyMovements.duck";
import { emptyOfficeUsage, getOfficeCapacity, OfficeUsage } from "../../services/OfficeCapacityService";
import { failureNotification } from "./notification.duck";
import { FixedWhereabouts, UNKNOWN_OPTION } from "../../services/WhereaboutOptions";
import { Period } from "../../models/movements.models";
import { DATE_FORMAT } from "../../utils/DateUtils";

export interface OfficeUsageState {
  subscriptionsList: OfficeUsage[];
  isLoading: boolean;
}

export const initialOfficeUsageState: OfficeUsageState = {
  subscriptionsList: [],
  isLoading: false,
}

export const subscribeToOfficeUsage: any = createAsyncThunk(
  'officeUsage/subscribeToOfficeUsage',
  async (params: {officeId: number, date: Moment}, thunkAPI) => {
    const officeUsage = (thunkAPI.getState() as AppState).officeUsage;
    const dateString = params.date.format(DATE_FORMAT);
    const officeSub = officeUsage.subscriptionsList.find((ou: OfficeUsage) => ou.officeId === params.officeId && ou.date === dateString);


    if (!officeSub) {
      await thunkAPI.dispatch(addOfficeUsage(emptyOfficeUsage(params.officeId, dateString)));
    }
  }
)

export const loadUsages: any = createAsyncThunk(
  'officeUsage/loadUsages',
  async (forceAll: boolean, thunkAPI) => {
    const officeUsage = (thunkAPI.getState() as AppState).officeUsage;

    officeUsage.subscriptionsList
      .filter(ou => !ou.hasLoaded || ou.loading)
      .map(async (ou) => {
        try {
          const capacity = await getOfficeCapacity(ou.officeId, moment(ou.date));
          thunkAPI.dispatch(replaceOfficeUsage({...ou, ...capacity, hasLoaded: true, loading: false, failed: false}));
        } catch (e: any) {
          thunkAPI.dispatch(replaceOfficeUsage({...ou, hasLoaded: true, loading: false, failed: true}));
        }
      })
  }
)

export const bookSingleDayMovementByOfficeId: any = createAsyncThunk(
  'officeUsage/bookSingleDayMovementByOfficeId',
  async (params: {officeId?: number, period: Period, date: Moment}, thunkAPI) => {
    const appState = thunkAPI.getState() as AppState;
    const offices = appState.advanceHotDeskingSetup.offices;
    const officeEntity = offices.find((o: OfficeEntity) => o.id === params.officeId);
    if (officeEntity) {
      await thunkAPI.dispatch(bookSingleDayMovement({office: officeEntity, period: params.period, date: params.date, userId: appState.auth.currentUser?.id}));
    } else {
      throw new Error('Could not find office: ' + params.officeId);
    }
  }
)

export const bookSingleDayMovement: any = createAsyncThunk(
  'officeUsage/bookSingleDayMovement',
  async (params: {office?: OfficeEntity, period: Period, date: Moment, userId: string}, thunkAPI) => {
    const weekCommencing = params.date.clone().isoWeekday('monday');
    let usersWeeklyWhereaboutsResponse = await requestUsersWeeklyMovements(weekCommencing, params.userId);
    let usersWeeklyWhereabouts = usersWeeklyWhereaboutsResponse.movements;

    const amStatus = params.period === Period.AM || params.period === Period.AllDay ? FixedWhereabouts.Office : UNKNOWN_OPTION.key;
    const pmStatus = params.period === Period.PM || params.period === Period.AllDay ? FixedWhereabouts.Office : UNKNOWN_OPTION.key;

    let amLocationId = 0, pmLocationId = 0;
    let amDeskLocationId, pmDeskLocationId;

    if (params.period === Period.AM) {
      amLocationId = params.office?.id ?? 0;
      amDeskLocationId = 0;
    } else if (params.period === Period.PM) {
      pmLocationId = params.office?.id ?? 0;
      pmDeskLocationId = 0;
    } else if (params.period === Period.AllDay) {
      amLocationId = params.office?.id ?? 0;
      pmLocationId = params.office?.id ?? 0;
      amDeskLocationId = 0;
      pmDeskLocationId = 0;
    }

    const currentUser = (thunkAPI.getState() as AppState).auth.currentUser;
    const carParkingEnabled = !!currentUser?.carParkingEnabled;


    switch (params.date.isoWeekday()) {
      case 1: // Monday
        usersWeeklyWhereabouts.mondayAmStatus = amStatus;
        usersWeeklyWhereabouts.mondayPmStatus = pmStatus;
        usersWeeklyWhereabouts.mondayLocationId = amLocationId;
        usersWeeklyWhereabouts.mondayPmLocationId = pmLocationId;
        usersWeeklyWhereabouts.mondayAmDeskLocation = amDeskLocationId ?? usersWeeklyWhereabouts.mondayAmDeskLocation;
        usersWeeklyWhereabouts.mondayPmDeskLocation = pmDeskLocationId ?? usersWeeklyWhereabouts.mondayPmDeskLocation;
        usersWeeklyWhereabouts.mondayAmGeneralParking = amLocationId > 0 ? carParkingEnabled : false;
        usersWeeklyWhereabouts.mondayPmGeneralParking = pmLocationId > 0 ? carParkingEnabled : false;
        break;
      case 2: // Tuesday
        usersWeeklyWhereabouts.tuesdayAmStatus = amStatus;
        usersWeeklyWhereabouts.tuesdayPmStatus = pmStatus;
        usersWeeklyWhereabouts.tuesdayLocationId = amLocationId;
        usersWeeklyWhereabouts.tuesdayPmLocationId = pmLocationId;
        usersWeeklyWhereabouts.tuesdayAmDeskLocation = amDeskLocationId ?? usersWeeklyWhereabouts.tuesdayAmDeskLocation;
        usersWeeklyWhereabouts.tuesdayPmDeskLocation = pmDeskLocationId ?? usersWeeklyWhereabouts.tuesdayPmDeskLocation;
        usersWeeklyWhereabouts.tuesdayAmGeneralParking = amLocationId > 0 ? carParkingEnabled : false;
        usersWeeklyWhereabouts.tuesdayPmGeneralParking = pmLocationId > 0 ? carParkingEnabled : false;
        break;
      case 3: // Wednesday
        usersWeeklyWhereabouts.wednesdayAmStatus = amStatus;
        usersWeeklyWhereabouts.wednesdayPmStatus = pmStatus;
        usersWeeklyWhereabouts.wednesdayLocationId = amLocationId;
        usersWeeklyWhereabouts.wednesdayPmLocationId = pmLocationId;
        usersWeeklyWhereabouts.wednesdayAmDeskLocation = amDeskLocationId ?? usersWeeklyWhereabouts.wednesdayAmDeskLocation;
        usersWeeklyWhereabouts.wednesdayPmDeskLocation = pmDeskLocationId ?? usersWeeklyWhereabouts.wednesdayPmDeskLocation;
        usersWeeklyWhereabouts.wednesdayAmGeneralParking = amLocationId > 0 ? carParkingEnabled : false;
        usersWeeklyWhereabouts.wednesdayPmGeneralParking = pmLocationId > 0 ? carParkingEnabled : false;
        break;
      case 4: // Thursday
        usersWeeklyWhereabouts.thursdayAmStatus = amStatus;
        usersWeeklyWhereabouts.thursdayPmStatus = pmStatus;
        usersWeeklyWhereabouts.thursdayLocationId = amLocationId;
        usersWeeklyWhereabouts.thursdayPmLocationId = pmLocationId;
        usersWeeklyWhereabouts.thursdayAmDeskLocation = amDeskLocationId ?? usersWeeklyWhereabouts.thursdayAmDeskLocation;
        usersWeeklyWhereabouts.thursdayPmDeskLocation = pmDeskLocationId ?? usersWeeklyWhereabouts.thursdayPmDeskLocation;
        usersWeeklyWhereabouts.thursdayAmGeneralParking = amLocationId > 0 ? carParkingEnabled : false;
        usersWeeklyWhereabouts.thursdayPmGeneralParking = pmLocationId > 0 ? carParkingEnabled : false;
        break;
      case 5: // Friday
        usersWeeklyWhereabouts.fridayAmStatus = amStatus;
        usersWeeklyWhereabouts.fridayPmStatus = pmStatus;
        usersWeeklyWhereabouts.fridayLocationId = amLocationId;
        usersWeeklyWhereabouts.fridayPmLocationId = pmLocationId;
        usersWeeklyWhereabouts.fridayAmDeskLocation = amDeskLocationId ?? usersWeeklyWhereabouts.fridayAmDeskLocation;
        usersWeeklyWhereabouts.fridayPmDeskLocation = pmDeskLocationId ?? usersWeeklyWhereabouts.fridayPmDeskLocation;
        usersWeeklyWhereabouts.fridayAmGeneralParking = amLocationId > 0 ? carParkingEnabled : false;
        usersWeeklyWhereabouts.fridayPmGeneralParking = pmLocationId > 0 ? carParkingEnabled : false;
        break;
      case 6: // Saturday
        usersWeeklyWhereabouts.saturdayAmStatus = amStatus;
        usersWeeklyWhereabouts.saturdayPmStatus = pmStatus;
        usersWeeklyWhereabouts.saturdayLocationId = amLocationId;
        usersWeeklyWhereabouts.saturdayPmLocationId = pmLocationId;
        usersWeeklyWhereabouts.saturdayAmDeskLocation = amDeskLocationId ?? usersWeeklyWhereabouts.saturdayAmDeskLocation;
        usersWeeklyWhereabouts.saturdayPmDeskLocation = pmDeskLocationId ?? usersWeeklyWhereabouts.saturdayPmDeskLocation;
        usersWeeklyWhereabouts.saturdayAmGeneralParking = amLocationId > 0 ? carParkingEnabled : false;
        usersWeeklyWhereabouts.saturdayPmGeneralParking = pmLocationId > 0 ? carParkingEnabled : false;
        break;
      case 7: // Sunday
        usersWeeklyWhereabouts.sundayAmStatus = amStatus;
        usersWeeklyWhereabouts.sundayPmStatus = pmStatus;
        usersWeeklyWhereabouts.sundayLocationId = amLocationId;
        usersWeeklyWhereabouts.sundayPmLocationId = pmLocationId;
        usersWeeklyWhereabouts.sundayAmDeskLocation = amDeskLocationId ?? usersWeeklyWhereabouts.sundayAmDeskLocation;
        usersWeeklyWhereabouts.sundayPmDeskLocation = pmDeskLocationId ?? usersWeeklyWhereabouts.sundayPmDeskLocation;
        usersWeeklyWhereabouts.sundayAmGeneralParking = amLocationId > 0 ? carParkingEnabled : false;
        usersWeeklyWhereabouts.sundayPmGeneralParking = pmLocationId > 0 ? carParkingEnabled : false;
        break;
    }

    try {
      await Movements.create(usersWeeklyWhereabouts);
      await new Promise((resolve: any) => setTimeout(() => resolve(), 200));

      // Get floor if office is an area
      if (params.office?.area && params.office?.parentId) {
        const appState = thunkAPI.getState() as AppState;
        const offices = appState.advanceHotDeskingSetup.offices;
        const parentOffice = offices.find((o: OfficeEntity) => o.id === params.office?.parentId)
        if (parentOffice) {
          await thunkAPI.dispatch(selectOffice(parentOffice ?? params.office));
        }
      } else {
        await thunkAPI.dispatch(selectOffice(params.office));
      }

      await new Promise((resolve: any) => setTimeout(() => resolve(), 100));
      await thunkAPI.dispatch(refreshAllMovements());
      await new Promise((resolve: any) => setTimeout(() => resolve(), 50));
    } catch (e: any) {
      thunkAPI.dispatch(failureNotification(e.message));
    }
  }
)

const officeUsageSlice = createSlice({
  name: 'officeUsage',
  initialState: initialOfficeUsageState,
  reducers: {
    resetOfficeUsage: () => ({...initialOfficeUsageState}),
    addOfficeUsage: (state, action: PayloadAction<OfficeUsage>) => {
      const updatedOfficeUsage = [
        ...state.subscriptionsList,
        // ...state.subscriptionsList.filter((ou: OfficeUsage) => action.payload.officeId !== ou.officeId && action.payload.date !== ou.date),
        action.payload,
      ]
      return {
        ...state,
        subscriptionsList: updatedOfficeUsage
      }
    },
    replaceOfficeUsage: (state, action: PayloadAction<OfficeUsage>) => {
      const subs = state.subscriptionsList.map((ou: OfficeUsage) => {
        return (ou.officeId === action.payload.officeId && ou.date === action.payload.date) ? action.payload : ou;
      });
      return {
        ...state,
        subscriptionsList: subs
      }
    },
  },
  extraReducers: {
    [bookSingleDayMovement.pending]: (state) => ({...state, isLoading: true}),
    [bookSingleDayMovement.failed]: (state) => ({...state, isLoading: false}),
    [bookSingleDayMovement.fulfilled]: (state) => ({...state, isLoading: false}),

    [loadUsages.pending]: (state) => ({...state, isLoading: true}),
    [loadUsages.failed]: (state) => ({...state, isLoading: false}),
    [loadUsages.fulfilled]: (state) => ({...state, isLoading: false}),
  }
})

export default officeUsageSlice.reducer;
export const {
  resetOfficeUsage,
  addOfficeUsage,
  replaceOfficeUsage,
} = officeUsageSlice.actions;

// Selectors
export const selectIsOfficeUsageLoading = (state: AppState) => state.officeUsage.isLoading;
export const selectAllOfficeUsages = (state: AppState) => state.officeUsage.subscriptionsList;
export const selectOfficeCapacityByDateAndOfficeId = (state: AppState, date?: string, officeId?: number) => {
  if (date && officeId) {
    return state.officeUsage.subscriptionsList.find(ou => ou.officeId === officeId && date === ou.date);
  }
}
