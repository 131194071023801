import React from 'react';
import { ContentPage, MainContent, Sidebar } from "../ViewComponents";
import { VerticalSpacer } from "../../../../UI/atoms/VerticalSpacer";
import {
  OfficeView,
  selectActiveView,
  selectedRootOffice,
  selectIsOfficeListLoading
} from "../../../../../store/ducks/officeView.duck";
import { useSelector } from "react-redux";
import { LoadingSpinner } from "../../../../UI/atoms/LoadingSpinner";
import { OfficeViewDateWrapper } from "../OfficeViewDateWrapper";
import { selectIsOfficeUsageLoading } from "../../../../../store/ducks/officeUsage.duck";
import styled from "styled-components/macro";
import Colours from "../../../../UI/atoms/Colours";
import { Center } from "../../../../UI/atoms/StructuralLayout";
import { OfficeUsageView } from "./OfficeUsageView";
import { OfficeListSidebar } from "./OfficeListSidebar";
import { tablet } from "../../../../UI/atoms/MediaQueries";
import { selectActiveDay } from "../../../../../store/ducks/editMovements.duck";
import {
  DailyResponsibilityContentCards
} from "../../../../UI/organisms/responsibilitiesIndicator/DailyResponsibilityContentCards";
import { DownloadDailyCarParkReportButton } from "../../../../UI/molecules/DownloadDailyCarParkReportButton";
import { DownloadDailyOfficeReportButton } from "../../../../UI/molecules/DownloadDailyOfficeReportButton";
import Layout from "../../../../UI/organisms/layout/Layout";
import { BiggerThanTabletOnly } from "../../../../UI/atoms/DeviceSpecific";
import { WithDefaultOfficeSelection } from "../../../../../hooks/WithDefaultOfficeSelection";
import { DailyOfficeVisitors } from "../../../../UI/organisms/visitor-booking/DailyOfficeVisitors";

export function DailyOfficeListView() {
  const isLoading = useSelector(selectIsOfficeListLoading);
  const isSavingMovements = useSelector(selectIsOfficeUsageLoading);
  const officeActiveView = useSelector(selectActiveView);
  const activeDay = useSelector(selectActiveDay);
  const activeOffice = useSelector(selectedRootOffice);


  WithDefaultOfficeSelection(true);

  return (
    <Layout>
      <DailyOfficeListViewContainer>
        <DailyOfficeListViewContent>
          <OfficeViewDateWrapper>
            {isSavingMovements && <Center><LoadingSpinner fullScreen={true} /></Center>}
            <OfficeContainer>
              {isLoading ? <LoadingSpinner hideBorder={true} style={{alignSelf: 'center', justifySelf: 'center'}}/> : <>
                {officeActiveView === OfficeView.List && <OfficeUsageView />}
              </>}
            </OfficeContainer>
          </OfficeViewDateWrapper>
        </DailyOfficeListViewContent>
        <VerticalSpacer/>
        <Sidebar>
          <BiggerThanTabletOnly>
            <OfficeListSidebar />
          </BiggerThanTabletOnly>
          <DailyOfficeVisitors office={activeOffice} date={activeDay} />
          <DailyResponsibilityContentCards office={activeOffice} date={activeDay} />
          <DownloadDailyOfficeReportButton />
          <DownloadDailyCarParkReportButton />
        </Sidebar>
      </DailyOfficeListViewContainer>
    </Layout>
  )
}

const OfficeContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 500px;
  background-color: ${Colours.veryLightGrey};
`

const DailyOfficeListViewContainer = styled<any>(ContentPage)`
  flex-direction: column;
  @media (${tablet}) {
    flex-direction: row;
  }
`

const DailyOfficeListViewContent = styled<any>(MainContent)`
  padding-top: 0;
`
