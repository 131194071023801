import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { redirectTo, selectCurrentUser, setAuthLoading, setUser } from "../../store/ducks/auth.duck";
import { PUBLIC_ROUTES } from "../../App";
import { rawUserRequest } from "../../services/Auth";
import {
  AuthType, clearLoginStorage,
  GetCurrentAuthType,
  HandleAuthenticationResult,
  LoginWithRedirect,
  resetAllLoginStorages,
  stepUpAzureCalendarPermissions
} from "../../utils/AuthUtils";
import { useMsal } from "@azure/msal-react";
import { AuthenticationResult } from "@azure/msal-common";
import { microsoftRequest } from "../../services/EnvironmentVariables";
import { TrackJS } from 'trackjs';
import jwt_decode from "jwt-decode";
import { IPublicClientApplication } from "@azure/msal-browser";


export function WithWebAuth() {
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const {instance} = useMsal();
  const instanceRef = useRef<undefined | IPublicClientApplication>();

  // Check current token
  //  This will also clear auth loading
  useEffect(() => {
    const trySilentMsLogin = async () => {
      try {
        const accounts = instance.getAllAccounts();
        if (!instance.getActiveAccount() && accounts.length > 0) {
          instance.setActiveAccount(accounts[0]);
        } else if (accounts.length === 0) {
          TrackJS?.track('No microsoft accounts to login with');
        }

        const result: AuthenticationResult = await instance.acquireTokenSilent({
          ...microsoftRequest.login,
          redirectUri: `${window.location.origin}/blank.html`,
          forceRefresh: true,
        });

        await HandleAuthenticationResult(result, dispatch, true);

      } catch (e: any) {
        if ((e?.message ?? '').toLowerCase().includes('user not registered')) {
          clearLoginStorage();
          dispatch(redirectTo('/login'))
        } else {
          await LoginWithRedirect(instance, dispatch, true);
        }
      }
    }

    const checkCurrentToken = async () => {
      try {
        if (GetCurrentAuthType() === AuthType.Microsoft || localStorage.getItem('accessToken') || localStorage.getItem('authToken') || localStorage.getItem('idToken')) {
          if (GetCurrentAuthType() === AuthType.Microsoft) {
            await trySilentMsLogin();
          } else {
            const user = await rawUserRequest();
            dispatch(setUser(user));
          }
        }
      } catch (e: any) {
        console.error(e)
        // Check the error and try to re-login (if error response is 'Azure access token timed out')
        if (e.message.toLowerCase().includes('azure access token timed out')) {
          await trySilentMsLogin();
        } else {
          //  Otherwise clearLoginStorage, capture and handle later
          TrackJS?.track(e);
          resetAllLoginStorages();
          dispatch(redirectTo('/login'));
        }
      } finally {
        dispatch(setAuthLoading(false));
      }
    }

    if (!PUBLIC_ROUTES.includes(window.location.pathname) && !hasAccessTokenInLocalStorage()) {
      dispatch(redirectTo('/login'));
      dispatch(setAuthLoading(false));
    } else if (['/login/azure', '/register/azure'].includes(window.location.pathname)) {
      dispatch(setAuthLoading(false));
    } else {
      checkCurrentToken();
    }
  }, [dispatch, instance]);

  useEffect(() => {
    instanceRef.current = instance;
  }, [instance])

  useEffect(() => {
    if (user?.companyEntity.calendarSyncEnabled && user?.enableOutlookSync && user?.authType !== AuthType.Google) {

      const accessToken = localStorage.getItem('accessToken');
      if (!accessToken) return;

      let parsedTokenScopes = '';
      try {
        const parsedToken = jwt_decode(accessToken) as any;
        parsedTokenScopes = (parsedToken?.scp ?? '').toUpperCase();
      } catch (e: any) {
        TrackJS?.track(e)
      }

      if (!parsedTokenScopes.includes('Calendars.ReadWrite'.toUpperCase()) || !parsedTokenScopes.includes('MailboxSettings.Read'.toUpperCase())) {
        if (instanceRef.current && instanceRef.current.getActiveAccount) {
          if (instanceRef.current?.getActiveAccount()) {
            // @ts-ignore
            stepUpAzureCalendarPermissions(instanceRef.current, instanceRef.current.getActiveAccount(), dispatch);
          } else {
            console.log('No active account on instance')
          }
        }
      }
    }
  }, [user, dispatch])

}

function hasAccessTokenInLocalStorage() {
  if (GetCurrentAuthType() === AuthType.Standard) {
    return !!localStorage.getItem('idToken');
  } else if (GetCurrentAuthType() === AuthType.Microsoft) {
    return !!localStorage.getItem('accessToken');
  } else if (GetCurrentAuthType() === AuthType.Google) {
    return !!localStorage.getItem('accessToken');
  }

  return (!!localStorage.getItem('accessToken') || !!localStorage.getItem('authToken'));
}
