import { MovementEntity, Period, UsersCarParkBooking } from "../models/movements.models";
import moment from "moment";
import { IconPack } from "../models/company.models";
import { FreeBusyStatus } from "../services/WhereaboutOptions";

export function updateWhereaboutsWithParking(whereabouts: MovementEntity, carParking: UsersCarParkBooking[] | undefined) {
  carParking?.forEach((carParkingDay: UsersCarParkBooking) => {
    switch (moment(carParkingDay.date).isoWeekday()) {
      case 1:
        whereabouts.mondayAmGeneralParking = carParkingDay.am;
        whereabouts.mondayPmGeneralParking = carParkingDay.pm;
        break;
      case 2:
        whereabouts.tuesdayAmGeneralParking = carParkingDay.am;
        whereabouts.tuesdayPmGeneralParking = carParkingDay.pm;
        break;
      case 3:
        whereabouts.wednesdayAmGeneralParking = carParkingDay.am;
        whereabouts.wednesdayPmGeneralParking = carParkingDay.pm;
        break;
      case 4:
        whereabouts.thursdayAmGeneralParking = carParkingDay.am;
        whereabouts.thursdayPmGeneralParking = carParkingDay.pm;
        break;
      case 5:
        whereabouts.fridayAmGeneralParking = carParkingDay.am;
        whereabouts.fridayPmGeneralParking = carParkingDay.pm;
        break;
      case 6:
        whereabouts.saturdayAmGeneralParking = carParkingDay.am;
        whereabouts.saturdayPmGeneralParking = carParkingDay.pm;
        break;
      case 7:
        whereabouts.sundayAmGeneralParking = carParkingDay.am;
        whereabouts.sundayPmGeneralParking = carParkingDay.pm;
        break;
    }
  })
  return whereabouts;
}

export function isCarParkingRequiredCheckedForWhereaboutsDay(activePeriod: Period,
                                                             activeDay: moment.Moment,
                                                             defaultParkingState: undefined | boolean,
                                                             amStatus: string,
                                                             pmStatus: string,
                                                             amRequiresParking: boolean | undefined,
                                                             pmRequiresParking: boolean | undefined) {
  if (activePeriod === Period.AllDay || activePeriod === Period.AM) {
    if (amStatus === 'OFFICE') return amRequiresParking;
  } else if (activePeriod === Period.PM) {
    if (pmStatus === 'OFFICE') return pmRequiresParking;
  }
  return defaultParkingState;
}


export function iconPackPath(iconPack: IconPack, imageName: string, freeBusyStatus: FreeBusyStatus): string {
  if (iconPack === IconPack.STANDARD) {
    return `/assets/icons/${iconPackToPath(iconPack)}/${imageName}`;
  }

  const prefixedImageName = imageName.replace('.svg', `${freeBusyStatusToPrefix(freeBusyStatus)}.svg`)
  return `/assets/icons/${iconPackToPath(iconPack)}/${prefixedImageName}`;
}

function iconPackToPath(iconPack: IconPack) {
  switch (iconPack) {
    case IconPack.STANDARD: return 'standard'
    case IconPack.BASIC: return 'basic';
    default: return 'standard';
  }
}

function freeBusyStatusToPrefix(freeBusyStatus: FreeBusyStatus) {
  switch (freeBusyStatus) {
    case FreeBusyStatus.FREE: return '-Available';
    case FreeBusyStatus.WORKING_ELSEWHERE: return '-OutOffice';
    case FreeBusyStatus.OOF: return '-Busy';
    case FreeBusyStatus.BUSY: return '-OutOffice';
    case FreeBusyStatus.TENTATIVE: return '-Busy';
    default: return '-Available';
  }
}
