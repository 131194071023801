import React, { useMemo } from 'react';
import { MovementIcon } from "../../../../molecules/MovementIcons";
import moment, { Moment } from "moment";
import { useDispatch, useSelector } from "react-redux";
import { selectActiveDay, startEditingWhereabouts } from "../../../../../../store/ducks/editMovements.duck";
import { MovementsDayContainer } from "../StructuralComponents";
import { selectAllOfficeEntities } from "../../../../../../store/ducks/advanceHotDeskingSetup.duck";
import { findOfficeInOffices, getFullOfficeHierarchy, getRootOfficeById } from "../../../../../../utils/OfficeHelper";
import { MovementOfficeLabel } from "./MovementOfficeLabel";
import { FixedWhereabouts, UNKNOWN_OPTION, WhereaboutsOption } from "../../../../../../services/WhereaboutOptions";
import { MovementCustomLabel } from "./MovementCustomLabel";
import { OfficeEntity } from "../../../../../../services/AdvanceHotDeskingService";
import { Period, UsersCarParkBooking } from "../../../../../../models/movements.models";
import { CarParkingBookedIcon } from "./CarParkingBookedIcon";
import { DATE_FORMAT } from "../../../../../../utils/DateUtils";
import { IconPack } from "../../../../../../models/company.models";
import { CarParkingPendingIcon } from "./CarParkingPendingIcon";

interface Props {
  amStatus?: WhereaboutsOption;
  pmStatus?: WhereaboutsOption;
  momentDate: Moment;
  isUsersMovement?: boolean;
  officeAmLocationId?: any;
  officePmLocationId?: any;
  deskAmLabel?: string;
  deskPmLabel?: string;
  dataTest: string;
  enableSetMovements?: boolean;
  className?: string;
  userId: string;
  isEditing: boolean;
  amDeskId: number;
  pmDeskId: number;
  iconPack: IconPack;
  carParking: UsersCarParkBooking[];
  carParkingRequestedAm?: boolean;
  carParkingRequestedPm?: boolean;
  amCarParkSpaceId?: number;
  pmCarParkSpaceId?: number;
  amParkingSpaceLabel?: string;
  pmParkingSpaceLabel?: string;
}

const getOfficeLabel = (offices: OfficeEntity[], deskId: number, officeId: number, deskLabel?: string | null) => {
  if (offices) {
    const office = findOfficeInOffices(offices, officeId);
    let hierarchy = getFullOfficeHierarchy(offices, [], office);

    if (hierarchy.length > 1) {
      return (!!deskLabel ? `${deskLabel} - ` : ``) + hierarchy.slice(1).map(office => office.label).join(' - ');
    }
  }
}

export function MovementsDay(props: Props) {
  const {momentDate, isUsersMovement, isEditing, amDeskId, pmDeskId, carParking, amCarParkSpaceId, pmCarParkSpaceId} = props;
  const officeAmLocationId = props.officeAmLocationId ?? null;
  const officePmLocationId = props.officePmLocationId ?? null;
  const deskAmLabel = props.deskAmLabel ?? null;
  const deskPmLabel = props.deskPmLabel ?? null;
  const dispatch = useDispatch();
  const activeDay = useSelector(selectActiveDay);
  const todaysDate = moment().startOf('day');
  const offices = useSelector(selectAllOfficeEntities);

  const officeAmLabel = useMemo(() => {
    if (offices) {
      const rootOfficeAm = getRootOfficeById(offices, officeAmLocationId);
      return rootOfficeAm?.label;
    }
  }, [offices, officeAmLocationId]);

  const officePmLabel = useMemo(() => {
    if (offices && officePmLocationId && officeAmLocationId !== officePmLocationId) {
      const rootOfficePm = getRootOfficeById(offices, officePmLocationId);
      return rootOfficePm?.label;
    }
  }, [offices, officeAmLocationId, officePmLocationId]);

  const amLabel = useMemo(() => {
    const amOfficeLabel = getOfficeLabel(offices, amDeskId, officeAmLocationId, deskAmLabel)
    if (amOfficeLabel) return amOfficeLabel;
    else return ``;
  }, [offices, amDeskId, officeAmLocationId, deskAmLabel])

  const pmLabel = useMemo(() => {
    const pmOfficeLabel = getOfficeLabel(offices, pmDeskId, officePmLocationId, deskPmLabel)
    if (pmOfficeLabel) return pmOfficeLabel;
    else return ``;
  }, [offices, pmDeskId, officePmLocationId, deskPmLabel])

  const amStatus = useMemo(() => {
    if (props.isUsersMovement) {
      return props.amStatus;
    } else {
      return props.amStatus?.key === FixedWhereabouts.HomeRejected ? UNKNOWN_OPTION : props.amStatus;
    }
  }, [props.amStatus, props.isUsersMovement]);

  const pmStatus = useMemo(() => {
    if (props.isUsersMovement) {
      return props.pmStatus;
    } else {
      return props.pmStatus?.key === FixedWhereabouts.HomeRejected ? UNKNOWN_OPTION : props.pmStatus;
    }
  }, [props.pmStatus, props.isUsersMovement]);

  const amOrAllDayParking = useMemo(() => {
    if (amCarParkSpaceId) {
      return true;
    } else {
      if (carParking.length > 0 && offices.length > 0) {
        const rootOffice = getRootOfficeById(offices, props.officeAmLocationId);
        if (rootOffice) {
          return carParking.filter(cp => cp.officeId === rootOffice.id && cp.date === momentDate.format(DATE_FORMAT) && (cp.period === Period.AM || cp.period === Period.AllDay)).length > 0
        }
      }
    }
    return false;
  }, [amCarParkSpaceId, carParking, momentDate, offices, props.officeAmLocationId]);

  const pmParking = useMemo(() => {
    if (pmCarParkSpaceId && !amCarParkSpaceId) {
      return true;
    } else {
      if (carParking.length > 0 && offices.length > 0) {
        const rootOffice = getRootOfficeById(offices, props.officePmLocationId);
        if (rootOffice) {
          return carParking.filter(cp => cp.officeId === rootOffice.id && cp.date === momentDate.format(DATE_FORMAT) && cp.period === Period.PM).length > 0
        }
      }
    }
    return false;
  }, [amCarParkSpaceId, carParking, momentDate, offices, pmCarParkSpaceId, props.officePmLocationId]);

  const simpleDate = useMemo(() => momentDate.format('YYYYMMDD'), [momentDate]);
  const isHistoric = useMemo(() => momentDate.isBefore(todaysDate, 'day'), [momentDate, todaysDate]);
  const isFocused = useMemo(() => momentDate.isSame(todaysDate, 'day'), [momentDate, todaysDate]);
  const isActiveEdit = useMemo(() => {
    return isEditing && activeDay && isUsersMovement && activeDay?.isSame(momentDate)
  }, [isEditing, activeDay, isUsersMovement, momentDate])

  const onClick = () => {
    if (props.enableSetMovements || isUsersMovement) {
      dispatch(startEditingWhereabouts({userId: props.userId, date: momentDate}));
    }
  }

  const hasCarParkingRequested = (props.carParkingRequestedAm && props.officeAmLocationId) || (props.carParkingRequestedPm && props.officePmLocationId);

  return (
    <MovementsDayContainer className={`wallchartRow__day--${simpleDate} ${props.className ?? ''}`.trim()}
                           active={isActiveEdit}
                           data-test={props.dataTest}>
      {(amOrAllDayParking || pmParking) ? <CarParkingBookedIcon am={amOrAllDayParking} pm={pmParking} /> : <>
        {!!hasCarParkingRequested && <CarParkingPendingIcon am={!!props.carParkingRequestedAm} pm={!!props.carParkingRequestedPm} />}
      </>}
      <MovementIcon disabled={isHistoric} focused={isFocused}
                    amStatus={amStatus} pmStatus={pmStatus}
                    amOfficeLocationId={props.officeAmLocationId}
                    pmOfficeLocationId={props.officePmLocationId}
                    iconPack={props.iconPack}
                    onClick={onClick}
                    officeAmLabel={officeAmLabel}
                    officePmLabel={officePmLabel} />

      <MovementOfficeLabel amLabel={amLabel}
                           pmLabel={pmLabel}
                           date={props.momentDate}
                           amOfficeId={props.officeAmLocationId}
                           pmOfficeId={props.officePmLocationId}
                           amDeskId={props.amDeskId}
                           pmDeskId={props.pmDeskId}
                           amParkingLabel={props.amParkingSpaceLabel}
                           pmParkingLabel={props.pmParkingSpaceLabel} />
      <MovementCustomLabel amOption={amStatus}
                           pmOption={pmStatus} />

    </MovementsDayContainer>
  )
}
