import React from 'react';
import { useDispatch } from "react-redux";
import { WithStartOfWeek } from "../../../../../../hooks/WithStartOfWeek";
import { startEditingWhereabouts } from "../../../../../../store/ducks/editMovements.duck";
import { isDateBefore } from "../../../../../../utils/DateUtils";
import moment from "moment";
import { OutlineButton } from "../../../../atoms/buttons/OutlineButton";

export function SetWhereaboutsButton(props: Props) {
  const {isEditing, userId, isCurrentUser, className, hasMovementSet} = props;
  const dispatch = useDispatch();
  const startOfWeek = WithStartOfWeek();

  if (isEditing || !isCurrentUser) {
    return null;
  }

  const onClick = () => {
    const activeDate = isDateBefore(startOfWeek, moment()) ? moment() : startOfWeek;
    dispatch(startEditingWhereabouts({userId: userId, date: activeDate.clone()}));
  }

  const btnText = hasMovementSet ? 'team-view.edit-whereabouts' : 'team-view.set-whereabouts';
  return (
    <OutlineButton className={`wallchartRow__editButton ${className ?? ''}`.trim()}
                   dataTest="set-whereabouts-btn"
                   size={"small"}
                   text={btnText} click={onClick}/>
  )
}

interface Props {
  className?: string;
  hasMovementSet: boolean;
  userId: string;
  isCurrentUser: boolean;
  isEditing: boolean;
}
