import React, { useEffect, useState } from 'react';
import { HeadlineLarge } from "../../UI/atoms/fonts/Headline";
import Layout from "../../UI/organisms/layout/Layout";
import { MountedContentCard } from "../../UI/atoms/MountedContentCard";
import { GoBackButton } from "../../UI/atoms/buttons/GoBackButton";
import TextField from "../../UI/atoms/TextField";
import FullScreenDateRangeSelector from "../../UI/molecules/calendar/FullScreenDateRangeSelector";
import { Moment } from "moment";
import TeamDropDownList from "../../UI/organisms/team-drop-down-list/TeamDropDownList";
import { DateInputField } from '../../UI/molecules/DateInputField';
import styled from "styled-components";
import { Button } from "../../UI/atoms/buttons/Button";
import { smallTablet } from "../../UI/atoms/MediaQueries";
import { useDispatch, useSelector } from "react-redux";
import { createTeamEvent, deleteTeamEvent, selectIsLoading } from "./wallPlanner.duck";
import { LoadingSpinner } from "../../UI/atoms/LoadingSpinner";
import { useParams } from "react-router";
import { getTeamEvent } from "../../../services/WallPlannerService";
import { TeamEvent } from "../../../models/wall-planner.models";
import { SpaceBetweenRow } from "../../UI/atoms/StructuralLayout";
import { selectCurrentUser } from "../../../store/ducks/auth.duck";
import { BodyError } from "../../UI/atoms/fonts/Body";
import { canUserCreateTeamEvent } from "../../../utils/AccessControl";
import { useTranslation } from "react-i18next";
import { selectAllTeams } from "../../../store/ducks/config.duck";

export function WallPlannerNewEventPage() {
  const teams = useSelector(selectAllTeams);
  const isLoading = useSelector(selectIsLoading);
  const current = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const params = useParams<any>();
  const {t} = useTranslation();

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [fromDate, setFromDate] = useState<Moment | null>(null);
  const [toDate, setToDate] = useState<Moment | null>(null);
  const [eventName, setName] = useState('');
  const [selectedTeamId, setSelectedTeam] = useState<string | null>(null);
  const [error, setError] = useState('');

  const [nameError, setNameError] = useState('');
  const [fromDateError, setFromDateError] = useState('');
  const [toDateError, setToDateError] = useState('');

  useEffect(() => {
    if (params.eventId) {
      getTeamEvent(params.eventId)
        .then((event: TeamEvent) => {
          setName(event.eventName);
          setFromDate(event.from);
          setToDate(event.to);
          setSelectedTeam(event.teamId);
        });
    } else if (current?.teamEntity?.id) {
      setSelectedTeam(current?.teamEntity?.id);
    }
  }, [params.eventId, current]);

  useEffect(() => {
    if (!current || !teams || !selectedTeamId) {
      return;
    }
    if (!canUserCreateTeamEvent(current, teams.find(t => t.id === selectedTeamId))) {
      setError('You cannot add an event to that team. Please ask an Admin or a team member of that team.')
    } else {
      setError('');
    }
  }, [selectedTeamId, current, teams]);

  const onDateSelected = (startDate?: Moment, endDate?: Moment) => {
    if (startDate) setFromDate(startDate);
    if (endDate) setToDate(endDate);
  }

  const deleteEvent = () => {
    if (!selectedTeamId) {
      // todo select team error message
      return;
    }
    dispatch(deleteTeamEvent({
      teamEventId: params.eventId,
      teamId: selectedTeamId,
    }));
  }

  const validate = () => {
    let validationPass = true;
    setNameError('');
    setFromDateError('');
    setToDateError('');
    if (!eventName.trim()) {
      setNameError('Event requires a name');
      validationPass = false;
    }
    if (!fromDate) {
      setFromDateError('From date is required');
      validationPass = false;
    }
    if (!toDate) {
      setToDateError('To date is required');
      validationPass = false;
    }
    if (!selectedTeamId) {
      // todo select team error message
      validationPass = false;
    }
    return validationPass;
  }

  const saveEvent = () => {
    if (validate()) {
      dispatch(createTeamEvent({
        eventName: eventName,
        fromDate,
        toDate,
        teamId: selectedTeamId,
        id: params.eventId
      }));
    }
  }

  return (
    <Layout>
      <div style={{display: 'flex', justifyContent: 'center', marginTop: 32}}>
        <MountedContentCard>
          <GoBackButton/>
          <HeadlineLarge style={{marginBottom: 24}}>{t('work-planner.add-event-project')}</HeadlineLarge>
          {error && <BodyError style={{marginBottom: 24}}>{error}</BodyError>}
          <TextField onChange={setName} label="work-planner.create-event-title" value={eventName} error={nameError}/>
          <DateInputRow>
            <HalfWidthDateInputField onClick={() => setIsCalendarOpen(true)}
                                     placeholder="textinput.add-date"
                                     value={fromDate ? fromDate.format('D MMMM YYYY') : ''}
                                     label="textinput.from"
                                     error={fromDateError}
                                     editableOnly={true}/>

            <HalfWidthDateInputField onClick={() => setIsCalendarOpen(true)}
                                     placeholder="textinput.add-date"
                                     value={toDate ? toDate.format('D MMMM YYYY') : ''}
                                     label="textinput.to"
                                     error={toDateError}
                                     editableOnly={true}/>
          </DateInputRow>
          <div style={{marginBottom: 32}}>
            <FullWidthTeamDropDownList label="Event / project is for" teams={teams} onSelect={setSelectedTeam}
                                       selectedTeamId={selectedTeamId ?? ''}/>
          </div>


          {params.eventId ? <>
              <SpaceBetweenRow>
                <Button size="large" fullWidth={true} text="button.save-changes" onClick={saveEvent}/>
              </SpaceBetweenRow>
              <Button type="outline-borderless" size="large" fullWidth={true} text="button.delete-event-project"
                      onClick={deleteEvent}/>
            </> :
            <div style={{marginBottom: 64, maxWidth: 396, marginLeft: 'auto', marginRight: 'auto'}}>
              <Button size="large" fullWidth={true} text="work-planner.add-event-project" onClick={saveEvent}/>
            </div>
          }

        </MountedContentCard>
      </div>
      <FullScreenDateRangeSelector open={isCalendarOpen} onDateSelect={onDateSelected}
                                   startDate={fromDate} endDate={toDate}
                                   onClose={() => setIsCalendarOpen(false)}/>
      {isLoading && <LoadingSpinner fullScreen={true}/>}
    </Layout>
  )
}

const FullWidthTeamDropDownList = styled(TeamDropDownList)`
  width: 100%;
`

export const DateInputRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (${smallTablet}) {
    flex-direction: row;
  }
`

const HalfWidthDateInputField = styled<any>(DateInputField)`
  width: 100%;
  @media (${smallTablet}) {
    width: 47.5%;
  }
`

