import { MovementEntity } from "../../../models/movements.models";
import { Movements } from "../../../services/Movements";
import moment, { Moment } from "moment";
import { DATE_FORMAT } from "../../../utils/DateUtils";

export interface UpdatedWhereaboutsWeekResult {
  success: boolean;
  date: Moment;
  error?: any
}

export async function repeatWhereaboutsSchedule(movements: MovementEntity, numberOfWeeks: number, updater: (completeCount: number) => void): Promise<UpdatedWhereaboutsWeekResult[]> {
  const startDate = moment(movements.date);
  let results = [] as UpdatedWhereaboutsWeekResult[];

  for (let i = 1; i < numberOfWeeks+1; i++) {
    const weekToUpdate = startDate.clone().add(i, 'week');
    try {
      movements.date = weekToUpdate.format(DATE_FORMAT);
      await setMovementsForWeek(movements);
      results.push({success: true, date: weekToUpdate});
    } catch (e) {
      results.push({success: false, date: weekToUpdate, error: e});
    }
    updater(i);
  }

  return results;
}

async function setMovementsForWeek(movements: MovementEntity) {
  await Movements.makeWhereaboutsScheduleRequest(movements);
}
