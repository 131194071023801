import React, { useEffect, useMemo, useState } from 'react';
import styled from "styled-components/macro";
import DateFlicker, { DateScale } from "../../UI/molecules/DateFlicker";
import { Center, Column, Row } from "../../UI/atoms/StructuralLayout";
import { HeadlineLarge } from "../../UI/atoms/fonts/Headline";
import { BasicUserInfo } from "../../../models/user.models";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../../store/ducks/auth.duck";
import { canViewUserReport } from "../../../utils/AccessControl";
import { useTranslation } from "react-i18next";
import { SingleWhereaboutsUsage } from "./components/SingleWhereaboutsUsage";
import { UserSearchDropDownList } from "../../UI/molecules/UserSearchDropDownList";
import { ToggleableOutlineButton } from "../../UI/atoms/buttons/ToggleableOutlineButton";
import { selectCalendarDate } from "../../../store/ducks/dashboard.duck";
import { getUserWhereaboutsBreakdown, UsersSingleWhereaboutsBreakdown } from "../../../services/ReportingService";
import { getListOfDaysBetweenInclusive } from "../../../utils/DateUtils";
import { LoadingSpinner } from "../../UI/atoms/LoadingSpinner";
import { failureNotification } from "../../../store/ducks/notification.duck";
import { TrackJS } from "trackjs";
import { UserAvatarAndName } from "../../UI/molecules/UserAvatarAndName";
import { MountedContentCard } from "../../UI/atoms/MountedContentCard";
import { BodyLarge, BodyRegular } from '../../UI/atoms/fonts/Body';

export function SingleUserReporting() {
  const [dateScale, setDateScale] = useState(DateScale.Year);
  const [user, setUser] = useState<BasicUserInfo | undefined>();
  const [reportData, setReportData] = useState<UsersSingleWhereaboutsBreakdown[]>([]);
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector(selectCurrentUser);
  const hasAccess = useMemo(() => canViewUserReport(currentUser),[currentUser]);
  const calendarDate = useSelector(selectCalendarDate);
  const dispatch = useDispatch()
  const {t} = useTranslation();

  const userSelected = (user: BasicUserInfo) => {
    setUser(user);
  }

  const changeDateScale = (dateScale: DateScale) => {
    setDateScale(dateScale);
  }

  useEffect(() => {
    // /api/report/user
    if (user && calendarDate && dateScale) {
      setLoading(true);
      getUserWhereaboutsBreakdown(user.userId, calendarDate, dateScale)
        .then(setReportData)
        .catch((err) => {
          dispatch(failureNotification('Failed to get data'));
          TrackJS?.track(err);
        })
        .finally(() => setLoading(false));
    }
  }, [dispatch, user, calendarDate, dateScale]);

  const totalDays = useMemo(() => {
    const dateScaleString = dateScale===DateScale.Month ? 'month' : 'year';
    let days = getListOfDaysBetweenInclusive(calendarDate.clone().startOf(dateScaleString), calendarDate.clone().endOf(dateScaleString));
    if (!currentUser?.companyEntity.sevenDayWhereaboutsWeekEnabled) {
      days = days.filter(d => d.isoWeekday());
    }
    return days.length;
  }, [currentUser, calendarDate, dateScale]);


  return (
    <ReportingContainer>
      {hasAccess &&
        <>
          <Header>
            <Column>
              <HeadlineLarge style={{marginBottom: 16}}>{t('reports-page.user-report')}</HeadlineLarge>
              <div style={{width: 400, maxWidth: '100%'}}>
                <UserSearchDropDownList closeOnSelect={true} onSelect={userSelected} />
              </div>
            </Column>
            <Column>
              <Row style={{marginBottom: 16}}>
                <ToggleableOutlineButton isActive={dateScale === DateScale.Year}
                                         click={() => changeDateScale(DateScale.Year)}
                                         style={{marginRight: 12}}
                                         text={"button.year-view"}
                                         size={"small"} />
                <ToggleableOutlineButton isActive={dateScale === DateScale.Month}
                                         click={() => changeDateScale(DateScale.Month)}
                                         text={"button.month-view"}
                                         size={"small"} />
              </Row>
              <DateFlicker dateScale={dateScale}
                           showDateRange={true}
                           updateGlobalDate={true} />
            </Column>
          </Header>

          {!user && <>
            <Center>
              <MountedContentCard style={{marginTop: 64}}>
                <BodyLarge weight={600}>{t('reports-page.user-breakdown-select-user-title')}</BodyLarge>
                <BodyRegular>{t('reports-page.user-breakdown-select-user-description')}</BodyRegular>
              </MountedContentCard>
            </Center>
          </>}
          {loading ? <Center>
            <LoadingSpinner hideBorder={true} hideText={true} />
          </Center> : <>
            {user && <AvatarWrapper>
              <UserAvatarAndName firstName={user.firstName}
                                 lastName={user.lastName}
                                 showEditLink={true}
                                 hideName={false}
                                 colour={'blue'} />
            </AvatarWrapper>}
            {reportData.map((line, key) => (
              <SingleWhereaboutsUsage key={key}
                                      totalDays={totalDays}
                                      usedDays={line.count}
                                      label={line.label}
                                      icon={line.icon}
                                      whereaboutsKey={line.key} />
            ))}
          </>}


        </>
      }
    </ReportingContainer>
  );
}

const ReportingContainer = styled.div`
  max-width: 100%;
  min-height: 100vh;
  background-color: white;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`

const AvatarWrapper = styled.div`
  margin-bottom: 24px;
  .userAvatarAndName__avatar {
    width: 40px;
    height: 40px;
    font-size: 15px;
    line-height: 18px;
    font-weight: 600;
  }
  .userAvatarAndName__name {
    font-size: 24px;
    line-height: 26px;
    font-weight: 600;
  }
`
