import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit'
import { AppState } from '../state/app.state';
import { Movements, requestUsersWeeklyMovements } from '../../services/Movements';
import moment, { Moment } from 'moment';
import { Movement, MovementEntityRequest, Period, toMovementsRequest } from '../../models/movements.models';
import { SavingState } from '../../models/api.models';
import { matchesDay, shouldUpdateStatus } from "../../utils/Movement";
import { isWeekend } from "../../utils/DateUtils";
import { failureNotification, successNotification } from "./notification.duck";
import { loadWeeklyAvailability } from "./weeklyDeskAvailability.duck";
import { UNKNOWN_OPTION, WhereaboutsOption } from "../../services/WhereaboutOptions";
import { selectOffices } from "./advanceHotDeskingSetup.duck";
import { setCalendarDate } from "./dashboard.duck";
import { refreshAllMovements } from "./companyMovements.duck";
import { updateWhereaboutsWithParking } from "../../utils/WhereaboutsHelper";
import { DialogIdentifiers, openDialogWithPayload } from "./dialog.duck";

export enum OfficeWhereaboutsView {
  Office, CarPark,
}

export interface EditMovementsState {
  // This is going to drive whose being edited
  focusedUserId?: string;

  // Set when you click on edit whereabouts or the day then updated automatically as you cycle through
  activeDay?: Moment;

  // Defaulted to AllDay or set when you select AM/PM then updated automatically
  activePeriod: Period;

  // Loaded on EditWhereabouts activated
  focusedEditingWhereabouts?: Movement;

  // state setting
  savingState: SavingState;

  repeatWhereabouts: boolean;

  // To Check
  dirty: boolean;

  // Not used
  usingMagicLink: boolean;
  magicMovementsFailed: boolean;
  magicMovementsLoading: boolean;
  activeView: OfficeWhereaboutsView;
  userRequiresParking: boolean;
}

export const initialEditMovementsState: EditMovementsState = {
  activeDay: moment(),
  focusedEditingWhereabouts: undefined,
  activePeriod: Period.AllDay,
  savingState: {isComplete: false, isProcessing: false, error: ''},
  dirty: false,
  usingMagicLink: false,
  magicMovementsFailed: false,
  magicMovementsLoading: false,
  activeView: OfficeWhereaboutsView.Office,
  userRequiresParking: false,
  repeatWhereabouts: false,
}

export const startEditingWhereabouts: any = createAsyncThunk('editMovements/startEditingWhereabouts',
  async (params: {userId: string, date: Moment}, thunkAPI) => {
    const editMovementsState = (thunkAPI.getState() as AppState).editMovements;
    const sevenDayWhereaboutsWeekEnabled = !!((thunkAPI.getState() as AppState).auth.currentUser?.companyEntity.sevenDayWhereaboutsWeekEnabled);
    const parkingRequired = ((thunkAPI.getState() as AppState).auth.currentUser?.carParkingEnabled);
    const nextActiveDay = ((isWeekend(params.date) && !sevenDayWhereaboutsWeekEnabled) && !!editMovementsState.focusedEditingWhereabouts) ? moment(editMovementsState.focusedEditingWhereabouts.date) : params.date.clone();

    const date = moment(nextActiveDay);
    const resp = await requestUsersWeeklyMovements(date, params.userId);
    const updatedWhereabouts = updateWhereaboutsWithParking(resp.movements, resp.carParking);
    updatedWhereabouts.userId = resp.id;

    return {
      userId: params.userId,
      date: nextActiveDay,
      period: Period.AllDay,
      whereabouts: updatedWhereabouts,
      userRequiresParking: parkingRequired,
    }
  }
);

export const endEditingWhereabouts: any = createAsyncThunk('editMovements/endEditingWhereabouts',
  async (params: void, thunkAPI) => {
    const state = (thunkAPI.getState() as AppState);
    const editMovements = (thunkAPI.getState() as AppState).editMovements;

    if (editMovements.dirty) {
      await saveMovements((thunkAPI.getState() as AppState), thunkAPI);
      const offices = selectOffices((thunkAPI.getState() as AppState));
      if (offices.length === 1) {
        await thunkAPI.dispatch(loadWeeklyAvailability());
      }

      // Refactor to reload the correct users team
      await thunkAPI.dispatch(refreshAllMovements());
      await thunkAPI.dispatch(setCalendarDate(state.dashboard.calendarDate.clone()))
    }
  }
);

export const clearEditingWhereabouts: any = createAsyncThunk('editMovements/clearEditingWhereabouts',
  async (params: void, thunkAPI) => {
    const editMovements = (thunkAPI.getState() as AppState).editMovements;
    const whereabouts = editMovements.focusedEditingWhereabouts;

    if (!whereabouts) {
      return;
    }

    switch (editMovements.activeDay?.isoWeekday()) {
      case 1: return {
        ...whereabouts,
        mondayAmStatus: UNKNOWN_OPTION.key,
        mondayPmStatus: UNKNOWN_OPTION.key,
        mondayLocationId: 0,
        mondayPmLocationId: 0,
        mondayAmDeskLocation: 0,
        mondayPmDeskLocation: 0,
        mondayAmDeskLabel: undefined,
        mondayPmDeskLabel: undefined,
        mondayAmParkingSpaceId: 0,
        mondayPmParkingSpaceId: 0,
        mondayAmGeneralParking: false,
        mondayPmGeneralParking: false,
      }
      case 2: return {
        ...whereabouts,
        tuesdayAmStatus: UNKNOWN_OPTION.key,
        tuesdayPmStatus: UNKNOWN_OPTION.key,
        tuesdayLocationId: 0,
        tuesdayPmLocationId: 0,
        tuesdayAmDeskLocation: 0,
        tuesdayPmDeskLocation: 0,
        tuesdayAmDeskLabel: undefined,
        tuesdayPmDeskLabel: undefined,
        tuesdayAmParkingSpaceId: 0,
        tuesdayPmParkingSpaceId: 0,
        tuesdayAmGeneralParking: false,
        tuesdayPmGeneralParking: false,
      }
      case 3: return {
        ...whereabouts,
        wednesdayAmStatus: UNKNOWN_OPTION.key,
        wednesdayPmStatus: UNKNOWN_OPTION.key,
        wednesdayLocationId: 0,
        wednesdayPmLocationId: 0,
        wednesdayAmDeskLocation: 0,
        wednesdayPmDeskLocation: 0,
        wednesdayAmDeskLabel: undefined,
        wednesdayPmDeskLabel: undefined,
        wednesdayAmParkingSpaceId: 0,
        wednesdayPmParkingSpaceId: 0,
        wednesdayAmGeneralParking: false,
        wednesdayPmGeneralParking: false,
      }
      case 4: return {
        ...whereabouts,
        thursdayAmStatus: UNKNOWN_OPTION.key,
        thursdayPmStatus: UNKNOWN_OPTION.key,
        thursdayLocationId: 0,
        thursdayPmLocationId: 0,
        thursdayAmDeskLocation: 0,
        thursdayPmDeskLocation: 0,
        thursdayAmDeskLabel: undefined,
        thursdayPmDeskLabel: undefined,
        thursdayAmParkingSpaceId: 0,
        thursdayPmParkingSpaceId: 0,
        thursdayAmGeneralParking: false,
        thursdayPmGeneralParking: false,
      }
      case 5: return {
        ...whereabouts,
        fridayAmStatus: UNKNOWN_OPTION.key,
        fridayPmStatus: UNKNOWN_OPTION.key,
        fridayLocationId: 0,
        fridayPmLocationId: 0,
        fridayAmDeskLocation: 0,
        fridayPmDeskLocation: 0,
        fridayAmDeskLabel: undefined,
        fridayPmDeskLabel: undefined,
        fridayAmParkingSpaceId: 0,
        fridayPmParkingSpaceId: 0,
        fridayAmGeneralParking: false,
        fridayPmGeneralParking: false,
      }
      case 6: return {
        ...whereabouts,
        saturdayAmStatus: UNKNOWN_OPTION.key,
        saturdayPmStatus: UNKNOWN_OPTION.key,
        saturdayLocationId: 0,
        saturdayPmLocationId: 0,
        saturdayAmDeskLocation: 0,
        saturdayPmDeskLocation: 0,
        saturdayAmDeskLabel: undefined,
        saturdayPmDeskLabel: undefined,
        saturdayAmParkingSpaceId: 0,
        saturdayPmParkingSpaceId: 0,
        saturdayAmGeneralParking: false,
        saturdayPmGeneralParking: false,
      }
      case 7: return {
        ...whereabouts,
        sundayAmStatus: UNKNOWN_OPTION.key,
        sundayPmStatus: UNKNOWN_OPTION.key,
        sundayLocationId: 0,
        sundayPmLocationId: 0,
        sundayAmDeskLocation: 0,
        sundayPmDeskLocation: 0,
        sundayAmDeskLabel: undefined,
        sundayPmDeskLabel: undefined,
        sundayAmParkingSpaceId: 0,
        sundayPmParkingSpaceId: 0,
        sundayAmGeneralParking: false,
        sundayPmGeneralParking: false,
      }
    }
  }
);


async function saveMovements(appState: AppState, thunkAPI: any) {
  const usersMovements = appState.editMovements.focusedEditingWhereabouts

  try {
    if (!appState.editMovements.dirty) {
      return;
    }

    if (!!usersMovements) {
      const toSend: MovementEntityRequest = toMovementsRequest(usersMovements);

      const response = await Movements.create(toSend);
      // TODO Refine this to invalidate a specific team and refresh all invalidated
      await thunkAPI.dispatch(refreshAllMovements());
      if (appState.advanceHotDeskingSetup.offices.length === 1) {
        await thunkAPI.dispatch(loadWeeklyAvailability());
      }
      thunkAPI.dispatch(successNotification("Whereabouts saved"));


      if (appState.editMovements.repeatWhereabouts) {
        thunkAPI.dispatch(openDialogWithPayload({
          payload: { movements: toSend },
          activeDialog: DialogIdentifiers.RepeatScheduleDialog,
        }));
      }

      if (response.errors && response.errors.length > 0) {
        thunkAPI.dispatch(openDialogWithPayload({
          payload: { errors: response.errors, response: response },
          activeDialog: DialogIdentifiers.SavedWhereaboutsErrors
        }))
      }
    }
  } catch (err: any) {
    try {
      thunkAPI.dispatch(failureNotification(JSON.parse(err.message).message ?? "Whereabouts not saved"));
    } catch (e: any) {
      thunkAPI.dispatch(failureNotification("Whereabouts not saved"));
    }
  }
}

export const updateCarParkingForDay: any = createAsyncThunk(
  'editMovements/updateCarParkingForDay',
  async (params: any, thunkAPI) => {
    const editMovementsState = (thunkAPI.getState() as AppState).editMovements;
    const {activeDay, focusedEditingWhereabouts} = editMovementsState;

    if (!focusedEditingWhereabouts) throw new Error('No whereabouts to set');
    if (!activeDay) throw new Error('No active day');

    let updatedMovements = {
      ...focusedEditingWhereabouts,
    }

    if (matchesDay(focusedEditingWhereabouts.date, activeDay, 0)) { // Monday
      updatedMovements = { ...updatedMovements,
        mondayAmGeneralParking: !!updatedMovements.mondayLocationId,
        mondayPmGeneralParking: !!updatedMovements.mondayPmLocationId,
      };

    } else if (matchesDay(focusedEditingWhereabouts.date, activeDay, 1)) {
      updatedMovements = { ...updatedMovements,
        tuesdayAmGeneralParking: !!updatedMovements.tuesdayLocationId,
        tuesdayPmGeneralParking: !!updatedMovements.tuesdayPmLocationId,
      };

    } else if (matchesDay(focusedEditingWhereabouts.date, activeDay, 2)) {
      updatedMovements = { ...updatedMovements,
        wednesdayAmGeneralParking: !!updatedMovements.wednesdayLocationId,
        wednesdayPmGeneralParking: !!updatedMovements.wednesdayPmLocationId,
      }

    } else if (matchesDay(focusedEditingWhereabouts.date, activeDay, 3)) {
      updatedMovements = { ...updatedMovements,
        thursdayAmGeneralParking: !!updatedMovements.thursdayLocationId,
        thursdayPmGeneralParking: !!updatedMovements.thursdayPmLocationId,
      }

    } else if (matchesDay(focusedEditingWhereabouts.date, activeDay, 4)) {
      updatedMovements = { ...updatedMovements,
        fridayAmGeneralParking: !!updatedMovements.fridayLocationId,
        fridayPmGeneralParking: !!updatedMovements.fridayPmLocationId,
      }

    } else if (matchesDay(focusedEditingWhereabouts.date, activeDay, 5)) {
      updatedMovements = { ...updatedMovements,
        saturdayAmGeneralParking: !!updatedMovements.saturdayLocationId,
        saturdayPmGeneralParking: !!updatedMovements.saturdayPmLocationId,
      }

    } else if (matchesDay(focusedEditingWhereabouts.date, activeDay, 6)) {
      updatedMovements = { ...updatedMovements,
        sundayAmGeneralParking: !!updatedMovements.sundayLocationId,
        sundayPmGeneralParking: !!updatedMovements.sundayPmLocationId,
      }
    }

    await thunkAPI.dispatch(updateUsersMovements(updatedMovements));
})


export const updateMovement: any = createAsyncThunk(
  'editMovements/updateMovement',
  async (data: {selectedOption: WhereaboutsOption, locationId: number, deskId?: number, skip?: boolean, stickOnDay?: boolean, parkingSpaceId?: number}, thunkAPI) => {
    const editMovementsState = (thunkAPI.getState() as AppState).editMovements;
    const currentUser = (thunkAPI.getState() as AppState).auth.currentUser;
    const {activeDay, activePeriod, focusedEditingWhereabouts} = editMovementsState;
    const {selectedOption, skip, stickOnDay, deskId, parkingSpaceId} = data;

    if (!!focusedEditingWhereabouts && !!activeDay) {
      let updatedMovements = {
        ...focusedEditingWhereabouts,
      }

      if (!skip) {
        updatedMovements = {
          ...updatedMovements,
          mondayAmStatus: shouldUpdateStatus(activePeriod, activeDay, focusedEditingWhereabouts, 0, Period.AM) ? selectedOption.key : focusedEditingWhereabouts.mondayAmStatus,
          mondayPmStatus: shouldUpdateStatus(activePeriod, activeDay, focusedEditingWhereabouts, 0, Period.PM) ? selectedOption.key : focusedEditingWhereabouts.mondayPmStatus,
          tuesdayAmStatus: shouldUpdateStatus(activePeriod, activeDay, focusedEditingWhereabouts, 1, Period.AM) ? selectedOption.key : focusedEditingWhereabouts.tuesdayAmStatus,
          tuesdayPmStatus: shouldUpdateStatus(activePeriod, activeDay, focusedEditingWhereabouts, 1, Period.PM) ? selectedOption.key : focusedEditingWhereabouts.tuesdayPmStatus,
          wednesdayAmStatus: shouldUpdateStatus(activePeriod, activeDay, focusedEditingWhereabouts, 2, Period.AM) ? selectedOption.key : focusedEditingWhereabouts.wednesdayAmStatus,
          wednesdayPmStatus: shouldUpdateStatus(activePeriod, activeDay, focusedEditingWhereabouts, 2, Period.PM) ? selectedOption.key : focusedEditingWhereabouts.wednesdayPmStatus,
          thursdayAmStatus: shouldUpdateStatus(activePeriod, activeDay, focusedEditingWhereabouts, 3, Period.AM) ? selectedOption.key : focusedEditingWhereabouts.thursdayAmStatus,
          thursdayPmStatus: shouldUpdateStatus(activePeriod, activeDay, focusedEditingWhereabouts, 3, Period.PM) ? selectedOption.key : focusedEditingWhereabouts.thursdayPmStatus,
          fridayAmStatus: shouldUpdateStatus(activePeriod, activeDay, focusedEditingWhereabouts, 4, Period.AM) ? selectedOption.key : focusedEditingWhereabouts.fridayAmStatus,
          fridayPmStatus: shouldUpdateStatus(activePeriod, activeDay, focusedEditingWhereabouts, 4, Period.PM) ? selectedOption.key : focusedEditingWhereabouts.fridayPmStatus,
          saturdayAmStatus: shouldUpdateStatus(activePeriod, activeDay, focusedEditingWhereabouts, 5, Period.AM) ? selectedOption.key : focusedEditingWhereabouts.saturdayAmStatus,
          saturdayPmStatus: shouldUpdateStatus(activePeriod, activeDay, focusedEditingWhereabouts, 5, Period.PM) ? selectedOption.key : focusedEditingWhereabouts.saturdayPmStatus,
          sundayAmStatus: shouldUpdateStatus(activePeriod, activeDay, focusedEditingWhereabouts, 6, Period.AM) ? selectedOption.key : focusedEditingWhereabouts.sundayAmStatus,
          sundayPmStatus: shouldUpdateStatus(activePeriod, activeDay, focusedEditingWhereabouts, 6, Period.PM) ? selectedOption.key : focusedEditingWhereabouts.sundayPmStatus,
        }
      }

      if (matchesDay(focusedEditingWhereabouts.date, activeDay, 0)) { // Monday
        const amLocationId = activePeriod === Period.AllDay || activePeriod === Period.AM ? (data.locationId ?? 0) : (updatedMovements.mondayLocationId ?? 0);
        const pmLocationId = activePeriod === Period.AllDay || activePeriod === Period.PM ? (data.locationId ?? 0) : (updatedMovements.mondayPmLocationId ?? 0);
        const amDeskId = activePeriod === Period.AllDay || activePeriod === Period.AM ? (deskId ?? 0) : (updatedMovements.mondayAmDeskLocation ?? 0);
        const pmDeskId = activePeriod === Period.AllDay || activePeriod === Period.PM ? (deskId ?? 0) : (updatedMovements.mondayPmDeskLocation ?? 0);
        const amParking = activePeriod === Period.AllDay || activePeriod === Period.AM ? editMovementsState.userRequiresParking : !!updatedMovements.mondayAmGeneralParking;
        const pmParking = activePeriod === Period.AllDay || activePeriod === Period.PM ? editMovementsState.userRequiresParking : !!updatedMovements.mondayPmGeneralParking;
        const amParkingSpaceId = activePeriod === Period.AllDay || activePeriod === Period.AM ? (parkingSpaceId ?? 0) : updatedMovements.mondayAmParkingSpaceId;
        const pmParkingSpaceId = activePeriod === Period.AllDay || activePeriod === Period.PM ? (parkingSpaceId ?? 0) : updatedMovements.mondayPmParkingSpaceId;
        updatedMovements = { ...updatedMovements,
          mondayLocationId: amLocationId, mondayPmLocationId: pmLocationId,
          mondayAmDeskLocation: amDeskId, mondayPmDeskLocation: pmDeskId,
          mondayAmGeneralParking: amParking, mondayPmGeneralParking: pmParking,
          mondayAmParkingSpaceId: amParkingSpaceId, mondayPmParkingSpaceId: pmParkingSpaceId,
        };

      } else if (matchesDay(focusedEditingWhereabouts.date, activeDay, 1)) {
        const amLocationId = activePeriod === Period.AllDay || activePeriod === Period.AM ? (data.locationId ?? 0) : (updatedMovements.tuesdayLocationId ?? 0);
        const pmLocationId = activePeriod === Period.AllDay || activePeriod === Period.PM ? (data.locationId ?? 0) : (updatedMovements.tuesdayPmLocationId ?? 0);
        const amDeskId = activePeriod === Period.AllDay || activePeriod === Period.AM ? (deskId ?? 0) : (updatedMovements.tuesdayAmDeskLocation ?? 0);
        const pmDeskId = activePeriod === Period.AllDay || activePeriod === Period.PM ? (deskId ?? 0) : (updatedMovements.tuesdayPmDeskLocation ?? 0);
        const amParking = activePeriod === Period.AllDay || activePeriod === Period.AM ? editMovementsState.userRequiresParking : !!updatedMovements.tuesdayAmGeneralParking;
        const pmParking = activePeriod === Period.AllDay || activePeriod === Period.PM ? editMovementsState.userRequiresParking : !!updatedMovements.tuesdayPmGeneralParking;
        const amParkingSpaceId = activePeriod === Period.AllDay || activePeriod === Period.AM ? (parkingSpaceId ?? 0) : updatedMovements.tuesdayAmParkingSpaceId;
        const pmParkingSpaceId = activePeriod === Period.AllDay || activePeriod === Period.PM ? (parkingSpaceId ?? 0) : updatedMovements.tuesdayPmParkingSpaceId;
        updatedMovements = { ...updatedMovements,
          tuesdayLocationId: amLocationId, tuesdayPmLocationId: pmLocationId,
          tuesdayAmDeskLocation: amDeskId, tuesdayPmDeskLocation: pmDeskId,
          tuesdayAmGeneralParking: amParking, tuesdayPmGeneralParking: pmParking,
          tuesdayAmParkingSpaceId: amParkingSpaceId, tuesdayPmParkingSpaceId: pmParkingSpaceId,
        };

      } else if (matchesDay(focusedEditingWhereabouts.date, activeDay, 2)) {
        const amLocationId = activePeriod === Period.AllDay || activePeriod === Period.AM ? (data.locationId ?? 0) : (updatedMovements.wednesdayLocationId ?? 0);
        const pmLocationId = activePeriod === Period.AllDay || activePeriod === Period.PM ? (data.locationId ?? 0) : (updatedMovements.wednesdayPmLocationId ?? 0);
        const amDeskId = activePeriod === Period.AllDay || activePeriod === Period.AM ? (deskId ?? 0) : (updatedMovements.wednesdayAmDeskLocation ?? 0);
        const pmDeskId = activePeriod === Period.AllDay || activePeriod === Period.PM ? (deskId ?? 0) : (updatedMovements.wednesdayPmDeskLocation ?? 0);
        const amParking = activePeriod === Period.AllDay || activePeriod === Period.AM ? editMovementsState.userRequiresParking : !!updatedMovements.wednesdayAmGeneralParking;
        const pmParking = activePeriod === Period.AllDay || activePeriod === Period.PM ? editMovementsState.userRequiresParking : !!updatedMovements.wednesdayPmGeneralParking;
        const amParkingSpaceId = activePeriod === Period.AllDay || activePeriod === Period.AM ? (parkingSpaceId ?? 0) : updatedMovements.wednesdayAmParkingSpaceId;
        const pmParkingSpaceId = activePeriod === Period.AllDay || activePeriod === Period.PM ? (parkingSpaceId ?? 0) : updatedMovements.wednesdayPmParkingSpaceId;
        updatedMovements = { ...updatedMovements,
          wednesdayLocationId: amLocationId, wednesdayPmLocationId: pmLocationId,
          wednesdayAmDeskLocation: amDeskId, wednesdayPmDeskLocation: pmDeskId,
          wednesdayAmGeneralParking: amParking, wednesdayPmGeneralParking: pmParking,
          wednesdayAmParkingSpaceId: amParkingSpaceId, wednesdayPmParkingSpaceId: pmParkingSpaceId,
        }

      } else if (matchesDay(focusedEditingWhereabouts.date, activeDay, 3)) {
        const amLocationId = activePeriod === Period.AllDay || activePeriod === Period.AM ? (data.locationId ?? 0) : (updatedMovements.thursdayLocationId ?? 0);
        const pmLocationId = activePeriod === Period.AllDay || activePeriod === Period.PM ? (data.locationId ?? 0) : (updatedMovements.thursdayPmLocationId ?? 0);
        const amDeskId = activePeriod === Period.AllDay || activePeriod === Period.AM ? (deskId ?? 0) : (updatedMovements.thursdayAmDeskLocation ?? 0);
        const pmDeskId = activePeriod === Period.AllDay || activePeriod === Period.PM ? (deskId ?? 0) : (updatedMovements.thursdayPmDeskLocation ?? 0);
        const amParking = activePeriod === Period.AllDay || activePeriod === Period.AM ? editMovementsState.userRequiresParking : !!updatedMovements.thursdayAmGeneralParking;
        const pmParking = activePeriod === Period.AllDay || activePeriod === Period.PM ? editMovementsState.userRequiresParking : !!updatedMovements.thursdayPmGeneralParking;
        const amParkingSpaceId = activePeriod === Period.AllDay || activePeriod === Period.AM ? (parkingSpaceId ?? 0) : updatedMovements.thursdayAmParkingSpaceId;
        const pmParkingSpaceId = activePeriod === Period.AllDay || activePeriod === Period.PM ? (parkingSpaceId ?? 0) : updatedMovements.thursdayPmParkingSpaceId;
        updatedMovements = { ...updatedMovements,
          thursdayLocationId: amLocationId, thursdayPmLocationId: pmLocationId,
          thursdayAmDeskLocation: amDeskId, thursdayPmDeskLocation: pmDeskId,
          thursdayAmGeneralParking: amParking, thursdayPmGeneralParking: pmParking,
          thursdayAmParkingSpaceId: amParkingSpaceId, thursdayPmParkingSpaceId: pmParkingSpaceId,
        }

      } else if (matchesDay(focusedEditingWhereabouts.date, activeDay, 4)) {
        const amLocationId = activePeriod === Period.AllDay || activePeriod === Period.AM ? (data.locationId ?? 0) : (updatedMovements.fridayLocationId ?? 0);
        const pmLocationId = activePeriod === Period.AllDay || activePeriod === Period.PM ? (data.locationId ?? 0) : (updatedMovements.fridayPmLocationId ?? 0);
        const amDeskId = activePeriod === Period.AllDay || activePeriod === Period.AM ? (deskId ?? 0) : (updatedMovements.fridayAmDeskLocation ?? 0);
        const pmDeskId = activePeriod === Period.AllDay || activePeriod === Period.PM ? (deskId ?? 0) : (updatedMovements.fridayPmDeskLocation ?? 0);
        const amParking = activePeriod === Period.AllDay || activePeriod === Period.AM ? editMovementsState.userRequiresParking : !!updatedMovements.fridayAmGeneralParking;
        const pmParking = activePeriod === Period.AllDay || activePeriod === Period.PM ? editMovementsState.userRequiresParking : !!updatedMovements.fridayPmGeneralParking;
        const amParkingSpaceId = activePeriod === Period.AllDay || activePeriod === Period.AM ? (parkingSpaceId ?? 0) : updatedMovements.fridayAmParkingSpaceId;
        const pmParkingSpaceId = activePeriod === Period.AllDay || activePeriod === Period.PM ? (parkingSpaceId ?? 0) : updatedMovements.fridayPmParkingSpaceId;
        updatedMovements = { ...updatedMovements,
          fridayLocationId: amLocationId, fridayPmLocationId: pmLocationId,
          fridayAmDeskLocation: amDeskId, fridayPmDeskLocation: pmDeskId,
          fridayAmGeneralParking: amParking, fridayPmGeneralParking: pmParking,
          fridayAmParkingSpaceId: amParkingSpaceId, fridayPmParkingSpaceId: pmParkingSpaceId,
        }

      } else if (matchesDay(focusedEditingWhereabouts.date, activeDay, 5)) {
        const amLocationId = activePeriod === Period.AllDay || activePeriod === Period.AM ? (data.locationId ?? 0) : (updatedMovements.saturdayLocationId ?? 0);
        const pmLocationId = activePeriod === Period.AllDay || activePeriod === Period.PM ? (data.locationId ?? 0) : (updatedMovements.saturdayPmLocationId ?? 0);
        const amDeskId = activePeriod === Period.AllDay || activePeriod === Period.AM ? (deskId ?? 0) : (updatedMovements.saturdayAmDeskLocation ?? 0);
        const pmDeskId = activePeriod === Period.AllDay || activePeriod === Period.PM ? (deskId ?? 0) : (updatedMovements.saturdayPmDeskLocation ?? 0);
        const amParking = activePeriod === Period.AllDay || activePeriod === Period.AM ? editMovementsState.userRequiresParking : !!updatedMovements.saturdayAmGeneralParking;
        const pmParking = activePeriod === Period.AllDay || activePeriod === Period.PM ? editMovementsState.userRequiresParking : !!updatedMovements.saturdayPmGeneralParking;
        const amParkingSpaceId = activePeriod === Period.AllDay || activePeriod === Period.AM ? (parkingSpaceId ?? 0) : updatedMovements.saturdayAmParkingSpaceId;
        const pmParkingSpaceId = activePeriod === Period.AllDay || activePeriod === Period.PM ? (parkingSpaceId ?? 0) : updatedMovements.saturdayPmParkingSpaceId;
        updatedMovements = { ...updatedMovements,
          saturdayLocationId: amLocationId, saturdayPmLocationId: pmLocationId,
          saturdayAmDeskLocation: amDeskId, saturdayPmDeskLocation: pmDeskId,
          saturdayAmGeneralParking: amParking, saturdayPmGeneralParking: pmParking,
          saturdayAmParkingSpaceId: amParkingSpaceId, saturdayPmParkingSpaceId: pmParkingSpaceId,
        }

      } else if (matchesDay(focusedEditingWhereabouts.date, activeDay, 6)) {
        const amLocationId = activePeriod === Period.AllDay || activePeriod === Period.AM ? (data.locationId ?? 0) : (updatedMovements.sundayLocationId ?? 0);
        const pmLocationId = activePeriod === Period.AllDay || activePeriod === Period.PM ? (data.locationId ?? 0) : (updatedMovements.sundayPmLocationId ?? 0);
        const amDeskId = activePeriod === Period.AllDay || activePeriod === Period.AM ? (deskId ?? 0) : (updatedMovements.sundayAmDeskLocation ?? 0);
        const pmDeskId = activePeriod === Period.AllDay || activePeriod === Period.PM ? (deskId ?? 0) : (updatedMovements.sundayPmDeskLocation ?? 0);
        const amParking = activePeriod === Period.AllDay || activePeriod === Period.AM ? editMovementsState.userRequiresParking : !!updatedMovements.sundayAmGeneralParking;
        const pmParking = activePeriod === Period.AllDay || activePeriod === Period.PM ? editMovementsState.userRequiresParking : !!updatedMovements.sundayPmGeneralParking;
        const amParkingSpaceId = activePeriod === Period.AllDay || activePeriod === Period.AM ? (parkingSpaceId ?? 0) : updatedMovements.sundayAmParkingSpaceId;
        const pmParkingSpaceId = activePeriod === Period.AllDay || activePeriod === Period.PM ? (parkingSpaceId ?? 0) : updatedMovements.sundayPmParkingSpaceId;
        updatedMovements = { ...updatedMovements,
          sundayLocationId: amLocationId, sundayPmLocationId: pmLocationId,
          sundayAmDeskLocation: amDeskId, sundayPmDeskLocation: pmDeskId,
          sundayAmGeneralParking: amParking, sundayPmGeneralParking: pmParking,
          sundayAmParkingSpaceId: amParkingSpaceId, sundayPmParkingSpaceId: pmParkingSpaceId,
        }

      }

      await thunkAPI.dispatch(updateUsersMovements(updatedMovements));

      if (stickOnDay) {
        return;
      }

      const isWeekendWhereaboutsEnabled = currentUser?.companyEntity.sevenDayWhereaboutsWeekEnabled;
      const diff = activeDay.diff(moment(focusedEditingWhereabouts.date), 'days')
      if ((activePeriod === Period.PM || activePeriod === Period.AllDay) && ((isWeekendWhereaboutsEnabled && diff >= 6) || (!isWeekendWhereaboutsEnabled && diff >= 4))) {
        thunkAPI.dispatch(endEditingWhereabouts());
      } else {
        if (activePeriod === Period.AM) {
          await thunkAPI.dispatch(setActivePeriod(Period.PM));
        } else {
          // try to load
          const nextActiveDay = activeDay.clone().add(1, 'days')
          await thunkAPI.dispatch(setActiveDay(nextActiveDay));
          await thunkAPI.dispatch(setActivePeriod(Period.AllDay));
        }
      }
    }
  }
)

const editMovementsSlice = createSlice({
  name: 'editMovements',
  initialState: initialEditMovementsState,
  reducers: {
    resetEditMovements: () => ({...initialEditMovementsState}),
    markAsDirty: (state) => ({...state, dirty: true}),
    markAsMagicLink: (state) => ({...state, usingMagicLink: true}),
    setUserId: (state, action) => ({...state, focusedUserId: action.payload}),
    setActiveDay: (state, action) => ({...state, activeDay: action.payload}),
    setActivePeriod: (state, action) => ({...state, activePeriod: action.payload, activeView: OfficeWhereaboutsView.Office}),
    updateSavingState: (state, action) => ({...state, savingState: {...state.savingState, ...action.payload}}),
    setActiveView: (state, action) => ({...state, activeView: action.payload}),
    setUserRequiresParking: (state, action) => ({...state, userRequiresParking: action.payload}),
    setRepeatWhereaboutsSchedule: (state, action) => ({...state, repeatWhereabouts: action.payload}),
    updateUsersMovements: (state, action) => {
      return {
        ...state,
        focusedEditingWhereabouts: action.payload,
        dirty: true,
      }
    },
  },
  extraReducers: {

    // eslint-disable-next-line
    ['editMovements/saveMagicLinkMovements/fulfilled']: (state, action) => ({
      ...state,
      activeDay: undefined,
      activePeriod: Period.AllDay,
      savingState: action.payload.savingState,
      dirty: false,
    }),
    // eslint-disable-next-line
    ['editMovements/setEditing/fulfilled']: (state, action) => ({...state, ...action.payload,}),
    [updateMovement.fulfilled]: (state) => ({...state, dirty: true}),

    [endEditingWhereabouts.pending]: (state) => ({ ...state, savingState: {isComplete: false, isProcessing: true, error: ''} }),
    [endEditingWhereabouts.failed]: (state) => ({ ...state, savingState: {isComplete: false, isProcessing: false, error: ''} }),
    [endEditingWhereabouts.fulfilled]: (state) => ({
      ...state,
      dirty: false,
      focusedUserId: undefined,
      savingState: {isComplete: true, isProcessing: false, error: ''}
    }),

    [startEditingWhereabouts.fulfilled]: (state, action) => ({
      ...state,
      activeDay: action.payload.date,
      focusedUserId: action.payload.userId,
      activePeriod: action.payload.period,
      userRequiresParking: action.payload.userRequiresParking,
      focusedEditingWhereabouts: action.payload.whereabouts,
      dirty: false,
    }),

    [clearEditingWhereabouts.fulfilled]: (state, action) => ({
      ...state,
      dirty: true,
      focusedEditingWhereabouts: action.payload,
    }),
  }
})

export default editMovementsSlice.reducer;
export const {
  resetEditMovements,
  setActiveDay,
  setActivePeriod,
  setActiveView,
  updateUsersMovements,
  markAsDirty,
  setUserId,
  setUserRequiresParking,
  setRepeatWhereaboutsSchedule,
} = editMovementsSlice.actions;

// Selectors
export const selectActiveDay = (state: AppState) => state.editMovements.activeDay;
export const selectActivePeriod = (state: AppState) => state.editMovements.activePeriod;
export const selectSavingState = (state: AppState) => state.editMovements.savingState;
export const selectWhereaboutsForActiveDay = (state: AppState) => {
  if (!state.editMovements.activeDay || !state.editMovements.focusedEditingWhereabouts) return;
  const activeDay = state.editMovements.activeDay;
  const activePeriod = state.editMovements.activePeriod;
  const focusedEditingWhereabouts = state.editMovements.focusedEditingWhereabouts
  if (matchesDay(focusedEditingWhereabouts.date, activeDay, 0)) {
    return activePeriod === Period.AM ? focusedEditingWhereabouts.mondayAmStatus : focusedEditingWhereabouts.mondayPmStatus;
  } else if (matchesDay(focusedEditingWhereabouts.date, activeDay, 1)) {
    return activePeriod === Period.AM ? focusedEditingWhereabouts.tuesdayAmStatus : focusedEditingWhereabouts.tuesdayPmStatus;
  } else if (matchesDay(focusedEditingWhereabouts.date, activeDay, 2)) {
    return activePeriod === Period.AM ? focusedEditingWhereabouts.wednesdayAmStatus : focusedEditingWhereabouts.wednesdayPmStatus;
  } else if (matchesDay(focusedEditingWhereabouts.date, activeDay, 3)) {
    return activePeriod === Period.AM ? focusedEditingWhereabouts.thursdayAmStatus : focusedEditingWhereabouts.thursdayPmStatus;
  } else if (matchesDay(focusedEditingWhereabouts.date, activeDay, 4)) {
    return activePeriod === Period.AM ? focusedEditingWhereabouts.fridayAmStatus : focusedEditingWhereabouts.fridayPmStatus;
  } else if (matchesDay(focusedEditingWhereabouts.date, activeDay, 5)) {
    return activePeriod === Period.AM ? focusedEditingWhereabouts.saturdayAmStatus : focusedEditingWhereabouts.saturdayPmStatus;
  } else if (matchesDay(focusedEditingWhereabouts.date, activeDay, 6)) {
    return activePeriod === Period.AM ? focusedEditingWhereabouts.sundayAmStatus : focusedEditingWhereabouts.sundayPmStatus;
  }
}

export const selectFocusedEditingWhereabouts: any = (state: AppState) => state.editMovements.focusedEditingWhereabouts;
export const selectFocusedUserId = (state: AppState) => state.editMovements.focusedUserId;
export const selectActiveView = (state: AppState) => state.editMovements.activeView;
export const selectUserRequiredParking = (state: AppState) => state.editMovements.userRequiresParking;
export const selectRepeatWeeklySchedule = (state: AppState) => state.editMovements.repeatWhereabouts;
