import React, { useMemo } from 'react';
import { HolidayGrouping } from "../../models/Holidays.model";
import { BodyRegular } from "../../../../UI/atoms/fonts/Body";
import moment from "moment";
import { OrangeChip, OutlineChip, PrimaryChip } from "../../../../UI/atoms/Chip";
import IconButton from "../../../../UI/molecules/icon-button/IconButton";
import styled from "styled-components/macro";
import { ApprovalState } from "../../../../../models/approval-requests.models";
import { User } from "../../../../../models/user.models";
import { IconTypes } from "../../../../UI/atoms/icon/Icon";
import { useSelector } from "react-redux";
import { selectHolidayUserInfo } from "../../../../../store/ducks/holidays-v2.duck";
import { isCompanyAdminUser } from "../../../../../utils/AccessControl";

export function UsersHolidayBookingItem(props: Props) {
  const userInfo = useSelector(selectHolidayUserInfo);
  const {holiday, currentUser, isLineReportOfCurrentUser} = props;

  const status = useMemo(() => {
    const isPending = holiday.holidayBookingList.find(h => h.state === ApprovalState.pending);
    const isApproved = holiday.holidayBookingList.find(h => h.state === ApprovalState.approved);
    return !!isPending ? ApprovalState.pending : isApproved ? ApprovalState.approved : '';
  }, [holiday]);

  const dateRange = useMemo(() => {
    return `${moment(holiday.fromDate).format('Do MMM')} - ${moment(holiday.toDate).format('Do MMM')}`
  }, [holiday]);

  const numberOfDays = useMemo(() => {
    return holiday.holidayBookingList.map(hd => {
      let count = 0;
      if (hd.am) count++;
      if (hd.pm) count++;
      return count;
    }).reduce((prev, curr) => prev + curr) / 2
  }, [holiday]);

  const showPendingChip = useMemo(() => {
    return currentUser?.companyEntity.holidayApprovalEnabled && status === ApprovalState.pending;
  }, [currentUser, status]);

  const showApprovedChip = useMemo(() => {
    return currentUser?.companyEntity.holidayApprovalEnabled && status === ApprovalState.approved;
  }, [currentUser, status]);

  const showEditLink = useMemo(() => {
    if (isCompanyAdminUser(currentUser) || isLineReportOfCurrentUser) {
      return true;
    }

    if (userInfo.userId === currentUser?.id) {
      return !moment(holiday.fromDate).isBefore(moment());
    }

    // Different user who is not line manager or company admin
    return false;
  }, [currentUser, holiday.fromDate, isLineReportOfCurrentUser, userInfo.userId]);

  const editLink = !!userInfo?.userId ? `/holidays/edit/${holiday.fromDate}/${userInfo?.userId}` : `/holidays/edit/${holiday.fromDate}/${currentUser?.id}`

  return (
    <>
      <SpaceBetweenRow>
        <BodyRegular weight={600}>{dateRange}</BodyRegular>
        {showEditLink && <IconButton icon={IconTypes.Edit} link={editLink}/>}
      </SpaceBetweenRow>
      <ChipRow>
        {showPendingChip && <OrangeChip size={'small'} label={'button.pending'} dataTest={"pending-chip"} onClick={() => {}} />}
        {showApprovedChip && <PrimaryChip size={'small'} label={'button.approved'} dataTest={"approved-chip"} onClick={() => {}} />}
        {numberOfDays > 0 && <OutlineChip label={`${numberOfDays} days`} onClick={() => {}} size={'small'} />}
      </ChipRow>
    </>
  )
}

interface Props {
  holiday: HolidayGrouping;
  currentUser?: User;
  isLineReportOfCurrentUser: boolean;
}

export const SpaceBetweenRow = styled.div`
  display: flex;
  justify-content: space-between;
`

const ChipRow = styled.div`
  display: flex;
  &>* {
    margin-right: 8px;
  }
`
