import React, { useEffect, useState } from 'react';
import { HeadlineLarge, HeadlineSmall } from "../../../../UI/atoms/fonts/Headline";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { loadMeetingRooms } from "./meeting-rooms-settings.duck";
import { PrimaryButton } from "../../../../UI/atoms/buttons/PrimaryButton";
import TextField from "../../../../UI/atoms/TextField";
import { Checkbox } from "../../../../UI/atoms/Checkbox";
import styled from "styled-components/macro";
import { isNumber } from "../../../../../utils/TypeUtils";
import { failureNotification, successNotification } from "../../../../../store/ducks/notification.duck";
import { createMeetingRoom } from "../../../../../services/MeetingRoomsService";
import { MeetingRoomFacilities } from "../../../meeting-rooms/models/MeetingRooms";
import { redirectTo } from "../../../../../store/ducks/auth.duck";
import { OfficeDropDownList } from "../../../../UI/organisms/OfficeDropDownList";
import { OfficeEntity } from "../../../../../services/AdvanceHotDeskingService";
import { BodyRegular } from "../../../../UI/atoms/fonts/Body";
import Colours from "../../../../UI/atoms/Colours";
import { GoBackButton } from "../../../../UI/atoms/buttons/GoBackButton";
import { selectAllOfficesForMeetingRooms } from "../../../../../store/ducks/advanceHotDeskingSetup.duck";

export function AddMeetingRoomPage(props: Props) {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [capacity, setCapacity] = useState('');
  const [tv, setTv] = useState(false);
  const [confCall, setConfCall] = useState(false);
  const [whiteboard, setWhiteboard] = useState(false);
  const [chargePoints, setChargePoints] = useState(false);
  const [office, setOffice] = useState<OfficeEntity | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const allOfficesForMeetingRooms = useSelector(selectAllOfficesForMeetingRooms);
  const {t} = useTranslation();

  useEffect(() => {
    dispatch(loadMeetingRooms());
  }, [dispatch]);

  const setCapacityWithCheck = (val: string) => {
    console.log(val)
    if (isNumber(val)) {
      setCapacity(val);
    }
  }

  const save = async () => {
    if (!capacity) {
      dispatch(failureNotification('Capacity needs to be set'));
      return;
    }

    if (!name) {
      dispatch(failureNotification('Name needs to be set'));
      return;
    }

    if (!office) {
      dispatch(failureNotification('Select an office'));
      return;
    }

    try {
      setLoading(true);
      let facilities: MeetingRoomFacilities[] = [];

      if (confCall) facilities.push(MeetingRoomFacilities.ConferenceCall);
      if (tv) facilities.push(MeetingRoomFacilities.TV);
      if (chargePoints) facilities.push(MeetingRoomFacilities.ChargePoints);
      if (whiteboard) facilities.push(MeetingRoomFacilities.WhiteBoard);

      // @ts-ignore
      await createMeetingRoom(office, name, parseInt(capacity), facilities);
      dispatch(successNotification("Meeting room created"));
      dispatch(redirectTo("/manage/meeting-rooms"));

    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <GoBackButton url={'/manage/meeting-rooms'} />
      <HeadlineLarge style={{marginBottom: 16}}>{t('settings.meeting-rooms.create')}</HeadlineLarge>
      <TextField disabled={loading}
                 value={name}
                 onChange={setName}
                 fullWidth={true}
                 label={"settings.meeting-rooms.name"} />
      <TextField disabled={loading}
                 value={capacity}
                 onChange={setCapacityWithCheck}
                 fullWidth={true}
                 label={"settings.meeting-rooms.capacity"} />
      <BodyRegular weight={600} colour={Colours.darkGrey}>{t('settings.meeting-rooms.office')}</BodyRegular>
      <OfficeDropDownList onSelect={setOffice} disabled={loading} offices={allOfficesForMeetingRooms} />
      <HeadlineSmall style={{marginTop: 24}}>{t('room-finder.facilities.title')}</HeadlineSmall>
      <FacilitiesList>
        <Checkbox disabled={loading} value={tv} onChange={setTv} text={'room-finder.facilities.tv'} />
        <Checkbox disabled={loading} value={confCall} onChange={setConfCall} text={'room-finder.facilities.conference-call'} />
        <Checkbox disabled={loading} value={whiteboard} onChange={setWhiteboard} text={'room-finder.facilities.whiteboard'} />
        <Checkbox disabled={loading} value={chargePoints} onChange={setChargePoints} text={'room-finder.facilities.charge-points'} />
      </FacilitiesList>
      <PrimaryButton disabled={loading} fullWidth={true} size={'large'} style={{marginTop: 24}} click={save}
                     text={'button.create'} />
    </>
  )
}

interface Props {
}

const FacilitiesList = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: 12px;
  }
`
