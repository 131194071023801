import React, { useMemo } from 'react';
import Header from '../header/Header';
import Footer from "../footer/Footers";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../store/ducks/auth.duck";
import { AccountSuspendedBanner } from "../stripe/AccountSuspendedBanner";
import { isInTeams } from "../../../../utils/TeamsUtils";

export default function Layout(props: Props) {
  const user = useSelector(selectCurrentUser);

  const bodyClassName = useMemo(() => {
    let className = 'content';

    if (props.stretch) className += ' content--stretch';
    if (props.className) className += ` ${props.className}`;
    if (props.fullScreenView) {
      if (isInTeams()) {
        className += ` content--fullscreen--teams`;
      } else {
        className += ` content--fullscreen`;
      }
    }

    return className;
  }, [props]);

  return (
    <>
      {user && user.companyEntity && user.companyEntity.overduePayment && <AccountSuspendedBanner /> }
      {props.hideHeader ? null : <Header fullScreenView={props.fullScreenView} />}
      <div style={{...(props.style ?? {})}} className={bodyClassName}>
        {props.children}
      </div>
      {props.hideFooter ? null : <Footer />}
    </>
  );
}

export function Section(props: SectionProps) {
  return (<div className={`section ${props.hideBorder ? 'section--borderless' : null}`.trim()}>{props.children}</div>)
}

interface Props {
  style?: any;
  className?: string;
  stretch?: boolean;
  children: any;
  hideFooter?: boolean;
  hideHeader?: boolean;
  fullScreenView?: boolean;
  isInTeams?: boolean;
}

interface SectionProps {
  hideBorder?: boolean;
  children: any;
}
