import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadTeamEvents } from "../components/pages/wall-planner/wallPlanner.duck";
import { selectCalendarDate } from "../store/ducks/dashboard.duck";
import { selectFollowingTeams } from "../store/ducks/followingTeamsAndTags.duck";

export function WithWorkPlannerTeamEvents() {
  const dispatch = useDispatch();
  const followedTeams = useSelector(selectFollowingTeams);
  const calendarDate = useSelector(selectCalendarDate);
  useEffect(() => {
    const id = setTimeout(() => {
      followedTeams.forEach((team) => dispatch(loadTeamEvents(team?.id)))
    }, 250);

    return () => {
      clearTimeout(id);
    }
  }, [dispatch, followedTeams, calendarDate]);
}
