import styled from "styled-components/macro";
import { smallTablet } from "./MediaQueries";

export const ContentCard = styled.div`
  width: 100%;
  box-shadow: 0 12px 32px 2px rgba(0, 87, 255, 0.15);
  background-color: white;
  padding: 16px 24px;
  border-radius: 20px;
  @media (${smallTablet}) {
    padding: 48px 56px;
  }
`
