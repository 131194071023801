import React from 'react';
import styled from "styled-components/macro";
import { HalfHourSlotCell, HourSlotCell } from "./StructuralComponents";

export function SwimLane(props: Props) {
  return (
    <Container className={'swimLane__container'}>
      <HourSlotCell onClick={() => props.onClick(0, 0)} />
      <HalfHourSlotCell onClick={() => props.onClick(0, 30)} />
      <HourSlotCell onClick={() => props.onClick(1, 0)} />
      <HalfHourSlotCell onClick={() => props.onClick(1, 30)} />
      <HourSlotCell onClick={() => props.onClick(2, 0)} />
      <HalfHourSlotCell onClick={() => props.onClick(2, 30)} />
      <HourSlotCell onClick={() => props.onClick(3, 0)} />
      <HalfHourSlotCell onClick={() => props.onClick(3, 30)} />
      <HourSlotCell onClick={() => props.onClick(4, 0)} />
      <HalfHourSlotCell onClick={() => props.onClick(4, 30)} />
      <HourSlotCell onClick={() => props.onClick(5, 0)} />
      <HalfHourSlotCell onClick={() => props.onClick(5, 30)} />
      <HourSlotCell onClick={() => props.onClick(6, 0)} />
      <HalfHourSlotCell onClick={() => props.onClick(6, 30)} />
      <HourSlotCell onClick={() => props.onClick(7, 0)} />
      <HalfHourSlotCell onClick={() => props.onClick(7, 30)} />
      <HourSlotCell onClick={() => props.onClick(8, 0)} style={{background: '#F2F6FF'}} />
      <HalfHourSlotCell onClick={() => props.onClick(8, 30)} style={{background: '#F2F6FF'}} />
      <HourSlotCell onClick={() => props.onClick(9, 0)} style={{background: '#F2F6FF'}} />
      <HalfHourSlotCell onClick={() => props.onClick(9, 30)} style={{background: '#F2F6FF'}} />
      <HourSlotCell onClick={() => props.onClick(10, 0)} style={{background: '#F2F6FF'}} />
      <HalfHourSlotCell onClick={() => props.onClick(10, 30)} style={{background: '#F2F6FF'}} />
      <HourSlotCell onClick={() => props.onClick(11, 0)} style={{background: '#F2F6FF'}} />
      <HalfHourSlotCell onClick={() => props.onClick(11, 30)} style={{background: '#F2F6FF'}} />
      <HourSlotCell onClick={() => props.onClick(12, 0)} style={{background: '#F2F6FF'}} />
      <HalfHourSlotCell onClick={() => props.onClick(12, 30)} style={{background: '#F2F6FF'}} />
      <HourSlotCell onClick={() => props.onClick(13, 0)} style={{background: '#F2F6FF'}} />
      <HalfHourSlotCell onClick={() => props.onClick(13, 30)} style={{background: '#F2F6FF'}} />
      <HourSlotCell onClick={() => props.onClick(14, 0)} style={{background: '#F2F6FF'}} />
      <HalfHourSlotCell onClick={() => props.onClick(14, 30)} style={{background: '#F2F6FF'}} />
      <HourSlotCell onClick={() => props.onClick(15, 0)} style={{background: '#F2F6FF'}} />
      <HalfHourSlotCell onClick={() => props.onClick(15, 30)} style={{background: '#F2F6FF'}} />
      <HourSlotCell onClick={() => props.onClick(16, 0)} style={{background: '#F2F6FF'}} />
      <HalfHourSlotCell onClick={() => props.onClick(16, 30)} style={{background: '#F2F6FF'}} />
      <HourSlotCell onClick={() => props.onClick(17, 0)} style={{background: '#F2F6FF'}} />
      <HalfHourSlotCell onClick={() => props.onClick(17, 30)} style={{background: '#F2F6FF'}} />
      <HourSlotCell onClick={() => props.onClick(18, 0)} />
      <HalfHourSlotCell onClick={() => props.onClick(18, 30)} />
      <HourSlotCell onClick={() => props.onClick(19, 0)} />
      <HalfHourSlotCell onClick={() => props.onClick(19, 30)} />
      <HourSlotCell onClick={() => props.onClick(20, 0)} />
      <HalfHourSlotCell onClick={() => props.onClick(20, 30)} />
      <HourSlotCell onClick={() => props.onClick(21, 0)} />
      <HalfHourSlotCell onClick={() => props.onClick(21, 30)} />
      <HourSlotCell onClick={() => props.onClick(22, 0)} />
      <HalfHourSlotCell onClick={() => props.onClick(22, 30)} />
      <HourSlotCell onClick={() => props.onClick(23, 0)} />
      <HalfHourSlotCell onClick={() => props.onClick(23, 30)} />
    </Container>
  )
}

interface Props {
    onClick: (hour: number, minutes: number) => any;
}

const Container = styled.div`
    width: 100%;
    &:last-child {
      border: none;
    }
`
