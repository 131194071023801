import React, { useMemo } from 'react';
import styled from "styled-components/macro";
import Colours from "../atoms/Colours";
import { BodyRegular, BodyVerySmall } from "../atoms/fonts/Body";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectAllOfficeEntities } from "../../../store/ducks/advanceHotDeskingSetup.duck";
import { OfficeEntity } from "../../../services/AdvanceHotDeskingService";
import { Pill } from "../atoms/Pill";
import Icon, { IconTypes } from "../atoms/icon/Icon";
import IconButton from "./icon-button/IconButton";
import { isInTeams } from "../../../utils/TeamsUtils";
import { Checkbox } from "../atoms/Checkbox";

export function TeamContentCard(props: Props) {
  const {officeIds, showEditIcon} = props;
  const {t} = useTranslation();
  const allOffices = useSelector(selectAllOfficeEntities);

  const linkedOffices = useMemo(() => {
    if (!officeIds) return [];
    return allOffices.filter((o: OfficeEntity) => officeIds.includes(o.id));
  }, [allOffices, officeIds]);

  const onCheckboxChange = (val: boolean) => {
    if (props.onCheckboxChecked) {
      props.onCheckboxChecked(val);
    }
  }

  const onEditClick = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if (!!props.onEditClick) {
      props.onEditClick();
    }
  }

  return (
    <CompanyViewContentCardWrapper>

      {showEditIcon && <>
        {!!props.editLink && !isInTeams() && <EditButton>
          <IconButton icon={IconTypes.Edit} color={'darkGrey'} link={props.editLink} />
        </EditButton>}
        {!!props.onEditClick && <EditButton onClick={onEditClick}>
          <IconButton icon={IconTypes.Edit} color={'darkGrey'} onClick={onEditClick} />
        </EditButton>}
      </>}

      <BodyRegular weight={600}>{props.label}</BodyRegular>
      <BodyRegular weight={400} colour={Colours.darkGrey}>{props.memberCount} {t('general.team-members')}</BodyRegular>
      {linkedOffices.map((lo: OfficeEntity, key: number) => (
        <Pill key={key} colour={Colours.black}
              title={lo.label}
              style={{marginLeft: 0, border: 'none'}}
              backgroundColour={'#E6FCFF'}
              className={'teamContentCard__officePill'}>
          <Icon icon={IconTypes.Office} />
          <BodyVerySmall weight={400}>{lo.label}</BodyVerySmall>
        </Pill>))}
      {props?.checkboxEnabled && <Checkbox className="teamContentCard__checkbox"
                                           value={!!props?.isChecked}
                                           onChange={(val: boolean) => onCheckboxChange(val)} />}
    </CompanyViewContentCardWrapper>
  )
}

interface Props {
  label: string;
  editLink?: string;
  showEditIcon: boolean;
  memberCount: number
  officeIds?: number[];
  checkboxEnabled?: boolean;
  onCheckboxChecked?: (val: boolean) => any;
  onEditClick?: () => any;
  isChecked?: boolean;
}

const EditButton = styled.div`
  position: absolute;
  bottom: 4px;
  right: 0;
`

export const CompanyViewContentCardWrapper = styled.div`
  position: relative;
  padding: 24px;
  border: 1px solid ${Colours.mildGrey};
  border-radius: 12px;
  background-color: white;
  width: 100%;
  min-height: 148px;
  cursor: pointer;
  &:hover {
    border: 2px solid ${Colours.blue};
    padding: 23px;
    .teamContentCard__checkbox {
      top: 15px;
      right: 3px;
    }
  }
  .teamContentCard__officePill {
    max-width: 125px;
    margin-right: 8px;
    padding: 4px 8px 4px 12px;
    p {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    i {
      font-size: 16px;
      margin-right: 6px;
    }
  }
  .teamContentCard__checkbox {
    position: absolute;
    top: 16px;
    right: 4px;
  }
`
