import moment, { Moment } from 'moment';
import { HolidayRecurringDay } from '../models/Holiday.model';
import { agent } from './agent';
import { getFirstMondayInMonth } from "../utils/DateUtils";
import { WhereaboutsOption } from "./WhereaboutOptions";
import { TeamEntity } from "../models/team.models";
import { TagWithCount } from "../models/tags.models";
import { AccountInfo } from "./AccountService";
import { OfficeEntity } from "./AdvanceHotDeskingService";
import { MeetingRoom } from "../components/pages/meeting-rooms/models/MeetingRooms";

export class ConfigEntity {
  constructor(
    public holidayAllowance: boolean,
    public numberOfDesksAvailable: number,
    public companyHolidayAllowance: number,
    public holidayYearRecurringMonth: number,
    public holidayYearRecurringDay: HolidayRecurringDay,
    public carParkingEnabled?: boolean,
    public repeatWeeklySchedule?: boolean,
    public repeatScheduleWeeksLimit?: number,
    public restrictBookingWindowForTeamAdmins?: boolean,
    public visitorBookingEnabled?: boolean,
    public holidayYearEnd?: string,
    public holidayYearStart?: string,) {}

    public getHolidayYearEndDate(date?: Moment): Moment | null {
      let endDate = date ?? moment();
      const holidayStartDate = this.getHolidayYearStartDate(endDate);
      endDate = holidayStartDate.add(1, 'years');
      endDate = endDate.subtract(1, 'days')
      return endDate
    }

    public getHolidayYearStartDate(date?: Moment): Moment {
      const now = moment();
      let startDate = date ?? moment();
      const holidayMonthWithOffset = this.holidayYearRecurringMonth - 1

      const holidayYear = now.month() < holidayMonthWithOffset ? startDate.year() - 1 : startDate.year();

      startDate = startDate.year(holidayYear);
      startDate =  startDate.month(holidayMonthWithOffset) // Zero based by moment is not
      startDate = this.holidayYearRecurringDay === HolidayRecurringDay.firstDayOfMonth ? startDate.date(1) : getFirstMondayInMonth(startDate);

      return startDate;
    }
}

export const DefaultConfigEntity: ConfigEntity = new ConfigEntity(false,
  0,
  1,
  0,
  HolidayRecurringDay.firstDayOfMonth)

export async function getConfig(): Promise<ConfigEntity> {
  const response = await agent.requests.get('/api/config');
  return new ConfigEntity(
    response.holidayAllowance,
    response.numberOfDesksAvailable,
    response.companyHolidayAllowance,
    response.holidayYearRecurringMonth,
    response.holidayYearRecurringDay,
    response.carParkingEnabled,
    response.repeatWeeklySchedule,
    response.repeatScheduleWeeksLimit,
    response.restrictBookingWindowForTeamAdmins,
    response.visitorBookingEnabled,
    response.holidayYearEndDate,
    response.holidayYearStartDate,
  )
}

export async function postUpdateConfig(configEntity: ConfigEntity): Promise<ConfigEntity> {
  const response = await agent.requests.post(`/api/config`, configEntity);
  return new ConfigEntity(
    response.holidayAllowance,
    response.numberOfDesksAvailable,
    response.companyHolidayAllowance,
    response.holidayYearRecurringMonth,
    response.holidayYearRecurringDay,
    response.carParkingEnabled,
    response.repeatWeeklySchedule,
    response.repeatScheduleWeeksLimit,
    response.restrictBookingWindowForTeamAdmins,
    response.visitorBookingEnabled,
    response.holidayYearEndDate,
    response.holidayYearStartDate,
  )
}

export async function setAdvanceHotDesking(value: boolean): Promise<ConfigEntity> {
  const response = await agent.requests.post(`/api/config/advance-hot-desking`, {advanceHotDesking: value});
  return new ConfigEntity(
    response.holidayAllowance,
    response.numberOfDesksAvailable,
    response.companyHolidayAllowance,
    response.holidayYearRecurringMonth,
    response.holidayYearRecurringDay,
    response.carParkingEnabled,
    response.repeatWeeklySchedule,
    response.repeatScheduleWeeksLimit,
    response.restrictBookingWindowForTeamAdmins,
    response.visitorBookingEnabled,
    response.holidayYearEndDate,
    response.holidayYearStartDate,
  )
}

export async function getAppConfig(): Promise<AppConfig> {
  const response = await agent.requests.get('/api/v2/config');
  return response;
}

export interface AppConfig {
  config: ConfigEntity;
  whereaboutsOptions: WhereaboutsOption[];
  userConfig: any;
  teams: TeamEntity[];
  workGroups: TagWithCount[];
  accountInfo: AccountInfo;
  offices: OfficeEntity[];
  meetingRooms: MeetingRoom[];
}
