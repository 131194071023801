import React, { useEffect } from 'react';
import Layout from "../../../UI/organisms/layout/Layout";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import { RoomFinderView } from "../components/RoomFinderView";
import { selectedOffice, setOffice } from "../models/room-finder.duck";
import { selectCurrentUser } from "../../../../store/ducks/auth.duck";
import { selectAllOfficesForMeetingRooms } from "../../../../store/ducks/advanceHotDeskingSetup.duck";
import { findOfficeInOffices } from "../../../../utils/OfficeHelper";
import { isInTeams } from "../../../../utils/TeamsUtils";
import { loadMeetingRooms } from "../../settings/pages/meeting-rooms/meeting-rooms-settings.duck";
import { addOrReplaceQueryParam, deleteQueryParam, getParameterByName } from "../../../../utils/UrlUtils";
import { breakpoints } from "../../../UI/atoms/MediaQueries";
import isMobile from "is-mobile";
import { RoomFinderSidebar } from "../components/RoomFinderSidebar";
import { WithDefaultOfficeSelection } from "../../../../hooks/WithDefaultOfficeSelection";

export function RoomFinderPage(props: Props) {
  const office = useSelector(selectedOffice)
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const allOfficesForMeetingRooms = useSelector(selectAllOfficesForMeetingRooms);

  WithDefaultOfficeSelection(true);

  useEffect(() => {
    dispatch(loadMeetingRooms());
  }, [dispatch]);

  useEffect(() => {
    if (office) return;

    const officeQueryParam = getParameterByName('office', window.location.href);
    if (officeQueryParam) {
      try {
        const officeId = parseInt(officeQueryParam);
        const officeToSet = findOfficeInOffices(allOfficesForMeetingRooms, officeId);
        if (officeToSet) {
          dispatch(setOffice(officeToSet));
        }
      } catch (e) {
        deleteQueryParam('office');
      }
    } else if (!office && !!currentUser?.defaultLocationId) {
      const defaultOffice = findOfficeInOffices(allOfficesForMeetingRooms, currentUser.defaultLocationId);
      if (defaultOffice) {
        dispatch(setOffice(defaultOffice));
      }
    } else {
      if (allOfficesForMeetingRooms.length > 0) {
        const defaultMeetingRoom = allOfficesForMeetingRooms[0];
        dispatch(setOffice(defaultMeetingRoom));
      }
    }
  }, [currentUser, office, dispatch, allOfficesForMeetingRooms]);

  useEffect(() => {
    if (office) {
      addOrReplaceQueryParam('office', office.id.toString());
    }
  }, [office]);



  return (
    <>
      {isMobile() && <RoomFinderSidebar />}
      <Layout hideFooter={true} fullScreenView={true} isInTeams={isInTeams()}>
        <RoomFinderContainer>
          <RoomFinderView />
        </RoomFinderContainer>
      </Layout>
    </>
  )
}

interface Props {
}

const RoomFinderContainer = styled.div`
  max-height: inherit;
  position: relative;
  width: 100%;
  margin: 0 auto;
  max-width: ${breakpoints.desktopMidWide}px;
`
