import React, { useMemo } from 'react';
import { Moment } from "moment";
import { User } from "../../../../../models/user.models";
import { Period } from "../../../../../models/movements.models";
import styled from "styled-components/macro";
import Colours from "../../../../UI/atoms/Colours";
import { Row, SpaceBetweenRow } from "../../../../UI/atoms/StructuralLayout";
import { BodyRegular } from "../../../../UI/atoms/fonts/Body";
import { OutlineButton } from "../../../../UI/atoms/buttons/OutlineButton";
import { PrimaryButton } from "../../../../UI/atoms/buttons/PrimaryButton";
import { cancelDeskReservation, makeDeskReservation } from "../../../../../store/ducks/deskBooking.duck";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../../store/ducks/auth.duck";
import { isCompanyAdminUser } from "../../../../../utils/AccessControl";
import { OfficeEntity } from "../../../../../services/AdvanceHotDeskingService";
import Icon, { IconTypes } from "../../../../UI/atoms/icon/Icon";
import {
  selectFavouriteDesks,
  selectFavouriteDesksLoading,
  toggleFavoriteDesk
} from "../../../../../store/ducks/advanceHotDeskingSetup.duck";
import { DialogIdentifiers, openDialogWithPayload } from "../../../../../store/ducks/dialog.duck";
import { warningNotification } from "../../../../../store/ducks/notification.duck";
import { CarParkingAvailability } from "./CarParkingAvailability";
import { SimpleSpinner } from "../../../../UI/atoms/SimpleSpinner";


interface Props {
  currentDate: Moment;
  activeDeskSpot: any;
  onSelect: any;
  currentUser?: User;
  activePeriod: Period;
  office: OfficeEntity;
}

export function SingleDeskSpotOverlay(props: Props) {
  const { activeDeskSpot, onSelect, office } = props;
  const dispatch = useDispatch();
  const usersFavouritedDesks = useSelector(selectFavouriteDesks);
  const currentUser = useSelector(selectCurrentUser);
  const isFavouriteDeskLoading = useSelector(selectFavouriteDesksLoading);
  const isCompanyAdmin = isCompanyAdminUser(currentUser);
  const isOwnBooking = activeDeskSpot.amReservation.userId === currentUser?.id && activeDeskSpot.pmReservation.userId === currentUser?.id;
  const isBooked = !!activeDeskSpot?.amReservation.id || !!activeDeskSpot?.pmReservation.id;

  const isDeskFavouriteByUser = useMemo(() => {
    return activeDeskSpot?.deskId && usersFavouritedDesks.find(d => d.deskId === activeDeskSpot?.deskId);
  }, [usersFavouritedDesks, activeDeskSpot]);

  const onBookDesk = (e: any, period: Period) => {
    e.stopPropagation();
    onSelect(undefined);
    dispatch(makeDeskReservation({
      office: office,
      deskId: activeDeskSpot.id,
      period: period,
      date: props.currentDate
    }));
  }

  const onCancelDeskBooking = (e: any, period: Period) => {
    e.stopPropagation();
    onSelect(undefined);
    dispatch(cancelDeskReservation({
      office: office,
      deskId: activeDeskSpot.id,
      period: period,
      date: props.currentDate,
      bookingUserId: period === Period.AllDay || period === Period.AM ? activeDeskSpot.amReservation.userId : period === Period.PM ? activeDeskSpot.pmReservation.userId : undefined,
    }));
  }

  const onEditDeskClick = () => {
    if (activeDeskSpot.id && activeDeskSpot.officeId) {
      onSelect(undefined);
      dispatch(openDialogWithPayload({
        payload: { deskId: activeDeskSpot.id, officeId: activeDeskSpot.officeId },
        activeDialog: DialogIdentifiers.EditDeskDialog,
      }));
    } else {
      dispatch(warningNotification('Unable able to edit office'));
    }
  }

  const onFavouriteDeskClicked = () => {
    if (!isFavouriteDeskLoading) {
      dispatch(toggleFavoriteDesk(activeDeskSpot?.deskId ?? activeDeskSpot.id));
    }
  }

  const captureClick = (e: Event) => {
    e.stopPropagation();
  }

  if (!activeDeskSpot) {
    return null;
  }

  return (
    <Popup isVisible={!activeDeskSpot.isAmBooked} style={{ left: activeDeskSpot.x + 50, top: activeDeskSpot.y - 0 }} onClick={(e: any) => captureClick(e)}>

      <ControlHeader>
        <FavButton onClick={onFavouriteDeskClicked}>
          {isFavouriteDeskLoading ? <SimpleSpinner size={24} /> : <img src={isDeskFavouriteByUser ? '/assets/icons/StarActive.svg' : '/assets/icons/Star.svg'} alt="Favourite"/>}
        </FavButton>
        {isCompanyAdmin && <Icon icon={IconTypes.Edit} style={{marginLeft: 16}} onClick={onEditDeskClick} />}
      </ControlHeader>
      <PopupContent>
        {isBooked && <>

          {(activeDeskSpot.amReservation || activeDeskSpot.pmReservation) && <>
            {activeDeskSpot?.amReservation.id === activeDeskSpot?.pmReservation.id ? <>
              <BodyRegular weight={600}>{`${activeDeskSpot?.amReservation.firstName} ${activeDeskSpot?.amReservation.lastName}`.trim()}</BodyRegular>
            </> : <>
              {!!activeDeskSpot?.amReservation.id && <BodyRegular weight={600}>{`AM: ${activeDeskSpot?.amReservation.firstName} ${activeDeskSpot?.amReservation.lastName}`.trim()}</BodyRegular>}
              {!!activeDeskSpot?.pmReservation.id && <BodyRegular weight={600}>{`PM: ${activeDeskSpot?.pmReservation.firstName} ${activeDeskSpot?.pmReservation.lastName}`.trim()}</BodyRegular>}
            </> }
          </>}
        </>}
        <SpaceBetweenRow style={{padding: 0, margin: 0, marginBottom: 16}}>
          <BodyRegular weight={600}>{office.label}: {activeDeskSpot.label}</BodyRegular>
        </SpaceBetweenRow>

        <CarParkingAvailability />

        {isBooked && <>
          {(isCompanyAdmin || isOwnBooking) && <>
            {(activeDeskSpot.amReservation || activeDeskSpot.pmReservation) && <>
              {activeDeskSpot?.amReservation.id === activeDeskSpot?.pmReservation.id ? <>
                <OutlineButton click={(e: any) => onCancelDeskBooking(e, Period.AllDay) } size={'small'} fullWidth={true} text={`Cancel booking`} />
              </> : <>
                {!!activeDeskSpot?.amReservation.id && <OutlineButton style={{marginBottom: 8}} click={(e: any) => onCancelDeskBooking(e, Period.AM) } size={'small'} fullWidth={true} text={`Cancel AM booking`} />}
                {!!activeDeskSpot?.pmReservation.id && <OutlineButton click={(e: any) => onCancelDeskBooking(e, Period.PM) } size={'small'} fullWidth={true} text={`Cancel PM booking`} />}
              </> }
            </>}
          </>}
        </>}


        <PrimaryButton click={(e: any) => onBookDesk(e, Period.AllDay)}
                       disabled={!!activeDeskSpot?.amReservation.id || !!activeDeskSpot?.pmReservation.id}
                       size={'medium'}
                       style={{marginTop: 16, marginBottom: 16}}
                       fullWidth={true}
                       text="button.book-all-day" />

        <Row style={{marginBottom: 16}}>
          <OutlineButton disabled={!!activeDeskSpot?.amReservation.id}
                         text="button.book-am"
                         style={{marginRight: 8}}
                         size={'small'}
                         click={(e: Event) => onBookDesk(e, Period.AM)}  />
          <OutlineButton disabled={!!activeDeskSpot?.pmReservation.id}
                         text="button.book-pm"
                         style={{marginLeft: 8}}
                         size={'small'}
                         click={(e: Event) => onBookDesk(e, Period.PM)} />
        </Row>

      </PopupContent>
    </Popup>
  )
}

const FavButton = styled.div`
  cursor: pointer;
  min-height: 16px;
  min-width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ControlHeader = styled.div<any>`
  background-color: ${Colours.veryLightGrey};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  position: relative;
  padding: 8px 24px;
`

const Popup = styled.div<any>`
  width: 260px;
  min-height: 120px;
  background-color: white;
  border: 1px solid ${Colours.veryLightGrey};
  box-shadow: 0 22px 24px 2px rgba(0, 87, 255, 0.15);
  border-radius: 16px;
  position: absolute;
  z-index: 150;
  overflow: hidden;
`

const PopupContent = styled.div<any>`
  padding: 24px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`
