import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit'
import { AppState } from '../state/app.state';
import { OfficeEntity } from "../../services/AdvanceHotDeskingService";
import moment, { Moment } from "moment";
import { getUsersInOfficeOn } from "../../services/Movements";
import { OfficeWithUsers } from "../../models/movements.models";
import { addOrReplaceQueryParam } from "../../utils/UrlUtils";
import { findOfficeInOffices, getRootOffice } from "../../utils/OfficeHelper";
import { CapacityResponse, getOfficeCapacity } from "../../services/OfficeCapacityService";
import { CarParkingPlan, CarParkSpace, requestCarParkingPlan } from "../../services/CarParkingPlanService";

export enum OfficeView {
  List, DeskView
}
export enum MapView {
  Office, CarPark
}

export interface OfficeViewState {
  selectedOffice?: OfficeEntity;
  selectedDate: Moment;
  loading: boolean;
  allUsersInOfficeLoaded: boolean;
  officeWithUsers: OfficeWithUsers[];
  activeView: OfficeView;
  selectedArea?: OfficeEntity;
  rootOfficeCapacity?: CapacityResponse;
  selectedMapView: MapView;
  carParkingPlansForActiveOffice: CarParkingPlan[];
  carParkingPlansLoading: boolean;
  selectedCarParkPlan?: CarParkingPlan;
  selectedCarParkSpace?: CarParkSpace;
  officeFloorPlan?: any;
}

export const initialOfficeViewState: OfficeViewState = {
  selectedDate: moment(),
  loading: false,
  allUsersInOfficeLoaded: false,
  officeWithUsers: [],
  activeView: OfficeView.List,
  selectedMapView: MapView.Office,
  carParkingPlansForActiveOffice: [],
  carParkingPlansLoading: false,
}

export const loadUsersInOffice: any = createAsyncThunk(
  'officeView/loadUsersInOffice',
  async (params: {date: Moment, office: OfficeEntity}, thunkAPI) => {
    const area = (thunkAPI.getState() as AppState).officeView.selectedArea;
    const {date, office} = params;

    thunkAPI.dispatch(loadDefaultOfficeCapacity(office));

    if (date && office) {
      return [{
        users: await getUsersInOfficeOn(date, office.id),
        office: office,
        area: area,
      }];
    } else {
      console.warn('Not loaded', date, office, area)
    }
  }
)

export const loadDefaultOfficeCapacity: any = createAsyncThunk(
  'officeView/loadDefaultOfficeCapacity',
  async (selectedOffice: OfficeEntity, thunkAPI) => {
    const state = thunkAPI.getState() as AppState;
    const selectedDate = state.editMovements.activeDay;
    const offices = state.advanceHotDeskingSetup.offices;
    const rootOffice = getRootOffice(offices, selectedOffice);

    if (!rootOffice.carParkCapacity) {
      return;
    }

    if (!!selectedOffice) {
      // @ts-ignore
      const cap = await getOfficeCapacity(rootOffice.id, selectedDate);
      return cap
    }
  }
)

export const selectOffice: any = createAsyncThunk(
  'officeView/selectOffice',
  async (selectedOffice: OfficeEntity, thunkAPI) => {
    const state = thunkAPI.getState() as AppState;
    const selectedDate = state.editMovements.activeDay;

    const office = selectedOffice.area ? findOfficeInOffices(state.advanceHotDeskingSetup.offices, selectedOffice.parentId) : selectedOffice;
    const area = selectedOffice.area ? selectedOffice : undefined;

    if (!office) {
      throw new Error('Could not find office');
    }

    // If no date selected return and wait for next pass
    if (!selectedDate) {
      return {
        office: office,
        area: area,
        officeWithUsers: [],
      }
    }

    /**
     * Fetches all the floors if floors exist else just gets the office.
     */
    const usersInOffice = [{
      users: [],//await getUsersInOfficeOn(selectedDate, office.id),
      office: office,
      area: area,
    }];

    addOrReplaceQueryParam('office', `${!!area ? area.id : office.id}`);

    return {
      office: office,
      area: area,
      officeWithUsers: usersInOffice,
    }
  }
)

export const loadCarParkingPlansForActiveOffice: any = createAsyncThunk(
  'officeView/loadCarParkingPlansForActiveOffice',
  async (params: { rootOfficeId: number, defaultToAnyPlan?: boolean }, thunkAPI) => {
    const plans = await requestCarParkingPlan(params.rootOfficeId);
    const state = (thunkAPI.getState() as AppState).officeView;
    let selectedCarParkPlan = plans.find((p: CarParkingPlan) => p.id === state.selectedCarParkPlan?.id);
    if (!selectedCarParkPlan && params.defaultToAnyPlan) {
      selectedCarParkPlan = plans.length > 0 ? plans[0] : undefined;
    }
    return {
      carParkingPlansForActiveOffice: plans,
      selectedCarParkPlan: selectedCarParkPlan,
    }
  }
)

const officeUsageSlice = createSlice({
  name: 'officeUsage',
  initialState: initialOfficeViewState,
  reducers: {
    resetOfficeView: () => ({...initialOfficeViewState}),
    setActiveView: ((state, action) => ({...state, activeView: action.payload})),
    setOfficeFloorPlan: ((state, action) => ({...state, officeFloorPlan: action.payload})),
    clearCarParkingPlans: ((state) => ({...state, selectedCarParkPlan: undefined, carParkingPlansForActiveOffice: [],  })),
    setSelectedCarPark: ((state, action) => ({...state, selectedCarParkPlan: action.payload})),
    setSelectedCarParkSpace: ((state, action) => ({...state, selectedCarParkSpace: action.payload})),
    setSelectedMapView: ((state, action) => ({...state, selectedMapView: action.payload})),
    setSelectedArea: ((state, action) => ({...state, selectedArea: action.payload})),
    clearSelectedArea: ((state) => ({...state, selectedArea: undefined})),
    clearSelectedOffice: ((state) => ({...state, selectedOffice: undefined})),
  },
  extraReducers: {
    [selectOffice.pending]: (state) => ({...state, loading: true, officeWithUsers: []}),
    [selectOffice.rejected]: (state) => ({...state, loading: false, officeWithUsers: []}),
    [selectOffice.fulfilled]: (state, action) => ({...state,
      loading: false,
      selectedOffice: action.payload.office,
      selectedArea: action.payload.area,
    }),

    [loadUsersInOffice.pending]: (state) => ({...state, allUsersInOfficeLoaded: true, officeWithUsers: []}),
    [loadUsersInOffice.rejected]: (state) => ({...state, allUsersInOfficeLoaded: false, officeWithUsers: []}),
    [loadUsersInOffice.fulfilled]: (state, action) => ({...state,
      allUsersInOfficeLoaded: false,
      officeWithUsers: action.payload,
    }),

    [loadCarParkingPlansForActiveOffice.pending]: (state) => ({...state, carParkingPlansLoading: true, carParkingPlansForActiveOffice: []}),
    [loadCarParkingPlansForActiveOffice.rejected]: (state) => ({...state, carParkingPlansLoading: false, carParkingPlansForActiveOffice: []}),
    [loadCarParkingPlansForActiveOffice.fulfilled]: (state, action) => ({...state,
      carParkingPlansLoading: false,
      carParkingPlansForActiveOffice: action.payload.carParkingPlansForActiveOffice,
      selectedCarParkPlan: action.payload.selectedCarParkPlan
    }),

    [loadDefaultOfficeCapacity.pending]: (state) => ({...state, rootOfficeCapacity: undefined}),
    [loadDefaultOfficeCapacity.fulfilled]: (state, action) => ({ ...state, rootOfficeCapacity: action.payload, }),
  }
})

export default officeUsageSlice.reducer;
export const {
  resetOfficeView,
  setActiveView,
  setSelectedArea,
  clearSelectedArea,
  clearSelectedOffice,
  setSelectedMapView,
  setSelectedCarPark,
  clearCarParkingPlans,
  setOfficeFloorPlan,
  setSelectedCarParkSpace,
} = officeUsageSlice.actions;

// Selectors
export const selectedOffice = (state: AppState) => state.officeView.selectedOffice;
export const selectedRootOffice = (state: AppState) => {
  const getParent = (office: OfficeEntity) => {
    if (office.parentId) {
      const parentOffice = state.advanceHotDeskingSetup.offices.find((o: OfficeEntity) => o.id === office.parentId);
      return !!parentOffice ? parentOffice : office;
    } else {
      return office;
    }
  }
  return !!state.officeView.selectedOffice ? getParent(state.officeView.selectedOffice) : undefined;
};

export const selectIsOfficeListLoading = (state: AppState) => state.officeView.loading;
export const selectIsUsersInOfficeLoading = (state: AppState) => state.officeView.allUsersInOfficeLoaded;
export const selectOfficeUsers = (state: AppState) => state.officeView.officeWithUsers;
export const selectActiveView = (state: AppState) => state.officeView.activeView;
export const selectSelectedArea = (state: AppState) => state.officeView.selectedArea;
export const selectSelectedOffice = (state: AppState) => state.officeView.selectedOffice;
export const selectRootOfficeCapacity = (state: AppState) => state.officeView.rootOfficeCapacity;
export const selectSelectedMapView = (state: AppState) => state.officeView.selectedMapView;
export const selectCarParkingPlans = (state: AppState) => state.officeView.carParkingPlansForActiveOffice;
export const selectIsCarParkingPlansRequestLoading = (state: AppState) => state.officeView.carParkingPlansLoading;
export const selectSelectedCarParkingPlan = (state: AppState) => state.officeView.selectedCarParkPlan;
export const selectOfficeFloorPlan = (state: AppState) => state.officeView.officeFloorPlan;
export const selectSelectedCarParkSpace = (state: AppState) => state.officeView.selectedCarParkSpace;
