import React from 'react';
import { Moment } from "moment";
import styled from "styled-components/macro";
import { HeadlineSmall } from "../../../UI/atoms/fonts/Headline";
import { useTranslation } from "react-i18next";
import DateFlicker, { DateScale } from "../../../UI/molecules/DateFlicker";
import { DownloadHighLevelHolidayReportButton } from "./DownloadHighLevelHolidayReportButton";
import { DownloadDetailedHolidayReportButton } from "./DownloadDetailedHolidayReportButton";

export function DownloadHolidayReport(props: Props) {
  const {selectedDate} = props;
  const {t} = useTranslation();

  return (
    <>
      <Wrapper>
        <HeadlineSmall style={{marginBottom: 8}}>{t('reports-page.download-holiday-summary')}</HeadlineSmall>
        <Row>
          <DateFlicker className={'dateFlicker'} dateScale={DateScale.Year} updateGlobalDate={false} onChange={date => props.setDate(date)} />
          <DownloadHighLevelHolidayReportButton selectedDate={selectedDate} />
          <DownloadDetailedHolidayReportButton selectedDate={selectedDate} />
        </Row>
      </Wrapper>
    </>
  )
}

interface Props {
  selectedDate: Moment;
  selectedTeamId: string;
  setDate: (date: Moment) => void;
}

const Wrapper = styled.div`
  margin: 36px 0 24px 0;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-right: 24px;
    padding-right: 24px;
  }
  button {
    margin-left: 32px;
  }
  .dateFlicker {
    max-width: 300px;
  }
`
