import React, { useEffect } from 'react';
import PrivateRoute from '../../routing/PrivateRoute';
import { Switch } from 'react-router';
import './user-management.scss';
import { HolidayCreateEditPage } from "../holidays-v2/HolidayCreateEditPage";
import { HolidaySelectUserPage } from "../holidays-v2/HolidaySelectUserPage";
import { HolidayCreatedSuccessfullyPage } from "../holidays-v2/HolidayCreatedSuccessfullyPage";
import { UserAnnualHolidayView } from "../holidays-v2/user-annual-holidays/UserAnnualHolidayView";
import { TeamsMonthlyHolidayView } from "../holidays-v2/teams-monthly-holidays/TeamsMonthlyHolidayView";
import HolidayApprovalsView from "../holidays-v2/approvals/HolidayApprovalsView";
import HolidayReportsView from "../holidays-v2/holiday-reports-page/HolidayReportsView";
import { useDispatch } from "react-redux";
import { loadLineReports } from "../../../store/ducks/companyStructure.duck";

export default function HolidaysRouting() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadLineReports());
  }, [dispatch]);

  return (
    <Switch>
      <PrivateRoute exact path="/holidays/add" component={HolidaySelectUserPage}/>
      <PrivateRoute exact path="/holidays/:user/add" component={HolidayCreateEditPage}/>
      <PrivateRoute exact path="/holidays/:user/success/:from/:to" component={HolidayCreatedSuccessfullyPage}/>
      <PrivateRoute exact path="/holidays/:user/edit/:date" component={HolidayCreateEditPage}/>

      <PrivateRoute exact path="/holidays" component={TeamsMonthlyHolidayView}/>
      <PrivateRoute exact path="/holidays/add" component={HolidayCreateEditPage}/>
      <PrivateRoute exact path="/holidays/edit/:date/:user" component={HolidayCreateEditPage}/>

      <PrivateRoute exact path="/holidays/leave-planner" component={TeamsMonthlyHolidayView}/>
      <PrivateRoute exact path="/holidays/view-approvals" component={HolidayApprovalsView}/>
      <PrivateRoute exact path="/holidays/reports" component={HolidayReportsView}/>
      <PrivateRoute exact path="/holidays/me" component={UserAnnualHolidayView}/>
      <PrivateRoute exact path="/holidays/:userId" component={UserAnnualHolidayView}/>
    </Switch>
  )
}
