import { useEffect, useState } from "react";
import { MeetingRoom, MeetingRoomBooking } from "../models/MeetingRooms";
import { getMeetingRoomBookingsForMeetingRoom } from "../../../../services/MeetingRoomsService";
import { Moment } from "moment";

export function LoadMeetingRoomBookings(meetingRoom?: MeetingRoom, date?: Moment) {
  const [bookings, setBookings] = useState<MeetingRoomBooking[]>([]);
  const [loading, setLoading] = useState(false);
  const [hasRequested, setHasRequested] = useState(false);

  useEffect(() => {
    if (hasRequested) return;
    if (!meetingRoom || loading || !date || hasRequested) return;

    setLoading(true);
    setHasRequested(true);
    getMeetingRoomBookingsForMeetingRoom(meetingRoom, date)
      .then(resp => setBookings(resp))
      .finally(() => {
        setLoading(false)
      });

  }, [date, hasRequested, loading, meetingRoom]);

  return {
    bookingsLoading: loading,
    bookings,
    setHasRequested,
  }
}
