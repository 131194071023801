import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateMovement } from '../../../../../store/ducks/editMovements.duck';
import { BodyRegular } from "../../../atoms/fonts/Body";
import { Moment } from "moment";
import { MovementImage } from "../../../atoms/MovementImage";
import ActivityAdvanceHotDeskingOffice from "./ActivityAdvanceHotDeskingOffice";
import ActivitySimpleOffice from "./ActivitySimpleOffice";
import { selectOffices } from "../../../../../store/ducks/advanceHotDeskingSetup.duck";
import { selectAllSelectableWhereaboutsOptions } from "../../../../../store/ducks/whereaboutsOptions.duck";
import { FreeBusyStatus, WhereaboutsOption } from "../../../../../services/WhereaboutOptions";
import { iconPackPath } from "../../../../../utils/WhereaboutsHelper";
import { selectIconPack } from "../../../../../store/ducks/auth.duck";

export default function ActivityList(props: { activeDate?: Moment }) {
  const dispatch = useDispatch();
  const activities = useSelector(selectAllSelectableWhereaboutsOptions);
  const iconPack = useSelector(selectIconPack);

  const updateSelectedDay = (e: any, option: WhereaboutsOption) => {
    e.stopPropagation();
    dispatch(updateMovement({selectedOption: option, locationId: 0}));
  }

  return (
    <div className="activityList">
      <WhereaboutsOffices activeDate={props.activeDate}/>

      {activities.map((activity, key) => (
        <div className="activity" onClick={(e: any) => updateSelectedDay(e, activity)} key={key} data-test={`activity-movement-icon-${activity.key.toLowerCase()}`} >
          <MovementImage src={iconPackPath(iconPack, activity.image, FreeBusyStatus.FREE)} alt={activity.alt}/>
          <BodyRegular weight={600}>{activity.label}</BodyRegular>
        </div>
      ))}
    </div>
  )
}

function WhereaboutsOffices(props: {activeDate: any}) {
  const {activeDate} = props;
  const offices = useSelector(selectOffices);

  if (offices.length > 0) {
    return <ActivityAdvanceHotDeskingOffice activeDate={activeDate} />
  } else {
    return <ActivitySimpleOffice activeDate={activeDate} offices={offices} />
  }
}
