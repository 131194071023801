export interface CompanyEntity {
  id: string;
  name: string;
  enabled: boolean;
  userLimit: number;
  numberOfTeamsLimit: number;
  enableHolidayBooking: boolean;
  deskBookingEnabled: boolean;
  lineManagementEnabled: boolean;
  sevenDayWhereaboutsWeekEnabled: boolean;
  whereaboutsApprovalsEnabled: boolean;
  domainNameSignupsEnabled: boolean;
  domainNameSignupPending: boolean;
  holidayApprovalEnabled: boolean;
  calendarSyncEnabled: boolean;
  trial: boolean;
  domainName: string;
  logoUrl?: string;
  holidayApprovalNotificationsEnabled: boolean;
  tenantBasedAuthEnabled?: boolean;
  weeklyNotesEnabled?: boolean;
  disableEditingPreviousWhereabouts: boolean;
  officeAttendanceConfirmationEnabled: boolean;
  disableNewScimUserEmail: boolean;
  overduePayment: boolean;
  meetingRoomLimit: number;
  iconPack: IconPack;
  visitorBookingEnabled: boolean;
}

export enum IconPack {
  STANDARD = 'STANDARD',
  BASIC = 'BASIC',
}
