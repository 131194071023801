import { TeamWithCount } from "../../../../models/team.models";
import { OfficeEntity } from "../../../../services/AdvanceHotDeskingService";
import i18next from "i18next";
import { MenuOptionType, TeamDropDownItem, WorkGroupDropDownItem } from "./TeamMultiSelect";
import { findAllRootOffices, findOfficeInOffices } from "../../../../utils/OfficeHelper";
import { Tag } from "../../../../models/tags.models";

function teamToMenuItem(team: any) {
  return {value: team, label: team.name, type: MenuOptionType.Team};
}

function workgroupToMenuItem(workgroup: Tag) {
  return {value: workgroup, label: workgroup.label, type: MenuOptionType.WorkGroup};
}

export function groupWorkgroups(workgroups: Tag[], offices: OfficeEntity[]) {
  const workgroupsNotInOffice = workgroups
    .filter(t => !t.officeIds || t.officeIds.length === 0)
    .map(t => workgroupToMenuItem(t));

  const rootOfficesWithWorkgroups = {}
  findAllRootOffices(offices).forEach(rootOffice => {
    const workgroupsInOffice = [...workgroups.filter(wg => (wg?.officeIds ?? []).includes(rootOffice.id))]
    if (workgroupsInOffice.length > 0) {
      // @ts-ignore
      rootOfficesWithWorkgroups[rootOffice.id as number] = workgroupsInOffice;
    }
  })

  let groupedTags: WorkGroupDropDownItem[] = [];

  for (const groupedTeamsByOfficeKey in rootOfficesWithWorkgroups) {
    // @ts-ignore
    const workgroupInOffice = rootOfficesWithWorkgroups[groupedTeamsByOfficeKey];
    const office = findOfficeInOffices(offices, parseInt(groupedTeamsByOfficeKey));
    if (office) {
      const menuItems = workgroupInOffice.map((t: any) => workgroupToMenuItem(t));
      groupedTags.push({ label: office.label, value: undefined, type: MenuOptionType.OfficeTitle });
      groupedTags = [...groupedTags, ...(menuItems as WorkGroupDropDownItem[])]
      groupedTags.push({ label: '', value: undefined, type: MenuOptionType.Blank });
    }
  }

  if (workgroupsNotInOffice.length > 0) {
    groupedTags.push({ label: 'Other workgroups', value: undefined, type: MenuOptionType.OfficeTitle });
  }

  groupedTags = [...groupedTags, ...workgroupsNotInOffice];
  return [{ label: i18next.t('team-view.workgroup'), options: groupedTags}];
}

export function groupTeams(teams: TeamWithCount[], offices: OfficeEntity[]) {
  const teamsNotInOffice: TeamDropDownItem[] = teams
    .filter(t => !t.officeIds || t.officeIds.length === 0)
    .map(t => teamToMenuItem(t)) ?? [];

  const rootOfficesWithTeams = {}
  findAllRootOffices(offices).forEach(rootOffice => {
    const teamsInOffice = [...teams.filter(twc => (twc?.officeIds ?? []).includes(rootOffice.id))]
    if (teamsInOffice.length > 0) {
      // @ts-ignore
      rootOfficesWithTeams[rootOffice.id as number] = teamsInOffice;
    }
  })


  let groupedTeams: TeamDropDownItem[] = []

  for (const groupedTeamsByOfficeKey in rootOfficesWithTeams) {
    // @ts-ignore
    const teamsInOffice = rootOfficesWithTeams[groupedTeamsByOfficeKey];
    const office = findOfficeInOffices(offices, parseInt(groupedTeamsByOfficeKey));
    if (office) {
      const menuItems = teamsInOffice.map((t: any) => teamToMenuItem(t));
      groupedTeams.push({ label: office.label, value: undefined, type: MenuOptionType.OfficeTitle });
      groupedTeams = [...groupedTeams, ...(menuItems as TeamDropDownItem[])]
      groupedTeams.push({ label: '', value: undefined, type: MenuOptionType.Blank });
    }
  }

  if (groupedTeams.length > 0) {
    groupedTeams.push({ label: 'Other teams', value: undefined, type: MenuOptionType.OfficeTitle });
  }
  groupedTeams = [...groupedTeams, ...teamsNotInOffice]
  return [{ label: i18next.t('team-view.teams'), options: groupedTeams}];
}
