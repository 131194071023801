import React, { useEffect, useState } from 'react';
import Dialog from "../../../UI/molecules/Dialog";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../../store/ducks/dialog.duck";
import DialogHeader from "../../../dialogs/DialogHeader";
import { FlatContentCard } from "../../../UI/atoms/FlatContentCard";
import { BodyRegular } from "../../../UI/atoms/fonts/Body";
import { PRETTY_DATE_FORMAT } from "../../../../utils/DateUtils";
import { Center, Column } from "../../../UI/atoms/StructuralLayout";
import Icon, { IconTypes } from "../../../UI/atoms/icon/Icon";
import styled from "styled-components/macro";
import { WithOfficeInformation } from "../../../../hooks/WithOfficeInformation";
import TextField from "../../../UI/atoms/TextField";
import { TimeRangeSelector } from "../components/TimeRangeSelector";
import { PrimaryButton } from "../../../UI/atoms/buttons/PrimaryButton";
import {
  failureNotification,
  successNotification,
  warningNotification
} from "../../../../store/ducks/notification.duck";
import { Moment } from "moment";
import { cancelMeetingRoomBooking, updateMeetingRoomBooking } from "../../../../services/MeetingRoomsService";
import { LoadingSpinner } from "../../../UI/atoms/LoadingSpinner";
import { refreshBookings, selectMeetingRoomById } from "../models/room-finder.duck";
import { MeetingRoomBooking } from "../models/MeetingRooms";
import { AppState } from "../../../../store/state/app.state";
import { OutlineButton } from "../../../UI/atoms/buttons/OutlineButton";
import { loadBookings } from "../models/meeting-room.duck";
import { Switch } from "../../../UI/atoms/Switch";
import { IsMeetingRoomBookingFromOutlook } from "../hooks/IsMeetingRoomBookingFromOutlook";
import { useTranslation } from "react-i18next";

export function EditMeetingRoomBookingDialog(props: Props) {
  const {booking} = props.payload;
  const dispatch = useDispatch();
  const meetingRoom = useSelector((state: AppState) => selectMeetingRoomById(state, booking.meetingRoomId))
  const [meetingName, setMeetingName] = useState(booking.name);
  const [fromTime, setFromTime] = useState<Moment | undefined>(booking.startDateTime);
  const [toTime, setToTime] = useState<Moment | undefined>(booking.endDateTime);
  const [loading, setLoading] = useState(true);
  const [addToCalendar, setAddToCalendar] = useState(true);
  const officeInfo = WithOfficeInformation(meetingRoom?.officeId);
  const isMeetingRoomBookingFromOutlook = IsMeetingRoomBookingFromOutlook(booking);
  const {t} = useTranslation();

  useEffect(() => {
    if (meetingRoom) {
      setLoading(false)
    }
  }, [dispatch, meetingRoom]);

  const close = () => {
    dispatch(closeDialog());
  }

  const onMeetingNameChange = (meetingName: string) => {
    setMeetingName(meetingName);
  }

  const cancelBooking = async () => {
    if (!booking.id) {
      dispatch(warningNotification('No booking found'));
      return;
    }

    try {
      setLoading(true);
      await cancelMeetingRoomBooking(booking.id);
      dispatch(refreshBookings());
      dispatch(closeDialog());
      dispatch(successNotification('Booking cancelled'));
    } catch (e) {
      dispatch(failureNotification('Failed to cancel'));
    } finally {
      setLoading(false);
    }
  }

  const confirmBooking = async () => {
    if (!fromTime || !toTime) {
      dispatch(warningNotification('No date or times identified'));
      return;
    }

    if (!meetingRoom) {
      dispatch(warningNotification('No meeting room found'));
      return;
    }

    if (!booking.id) {
      dispatch(warningNotification('No booking found'));
      return;
    }

    const bookingFromDateTime = booking.startDateTime.clone();
    const bookingToDateTime = booking.endDateTime.clone();
    bookingFromDateTime.hour(fromTime.hour());
    bookingFromDateTime.minutes(fromTime.minutes());
    bookingFromDateTime.seconds(0);
    bookingFromDateTime.milliseconds(0);
    bookingToDateTime.hour(toTime.hour());
    bookingToDateTime.minutes(toTime.minutes());
    bookingToDateTime.seconds(0);
    bookingToDateTime.milliseconds(0);

    try {
      setLoading(true);
      await updateMeetingRoomBooking(booking.id, meetingRoom, bookingFromDateTime, bookingToDateTime, meetingName, addToCalendar);
      dispatch(loadBookings());
      close();
      dispatch(successNotification('Booking updated'));
    } catch (e: any) {
      dispatch(failureNotification('Failed to update'));
      TrackJS?.track(e);
    } finally {
      setLoading(false);
    }
  }

  const onTimeChange = (from: Moment, to?: Moment) => {
    if (!to) return;
    setFromTime(from);
    setToTime(to);
  }

  return (
    <>
      <Dialog isOpen={true} onClose={close} showLogo={true}>
        <DialogHeader title={'room-booking-dialog.edit-booking-title'} />
        {loading ? <Center>
          <LoadingSpinner hideBorder={true} hideText={true} />
        </Center> : <>

          {isMeetingRoomBookingFromOutlook && <>
            <BodyRegular weight={600}>{t('room-booking-dialog.booked-in-outlook-title')}</BodyRegular>
            <BodyRegular style={{marginBottom: 24}}>{t('room-booking-dialog.booked-in-outlook-description')}</BodyRegular>
          </>}

          <SummaryBox>
            <Icon icon={IconTypes.AppIcon} size={'mediumlarge'} />
            <Column>
              <BodyRegular weight={600}>{meetingRoom?.name ?? '???'}</BodyRegular>
              <BodyRegular>{booking.startDateTime.format(PRETTY_DATE_FORMAT)}</BodyRegular>
              <BodyRegular>{(officeInfo?.officeNamePath ?? []).join(', ')}</BodyRegular>
              <BodyRegular>Approx. {meetingRoom?.capacity ?? '???'} people</BodyRegular>
            </Column>
          </SummaryBox>

          <TextField onChange={onMeetingNameChange}
                     label={'room-booking-dialog.meeting-title'}
                     disabled={isMeetingRoomBookingFromOutlook}
                     value={meetingName} />
          <Switch value={addToCalendar}
                  onChange={setAddToCalendar}
                  disabled={isMeetingRoomBookingFromOutlook}
                  label={'room-booking-dialog.add-to-calendar'}
                  style={{marginBottom: 12}} />
          <TimeRangeSelector style={{marginBottom: 80}}
                             hour={booking.startDateTime.hour()}
                             minutes={booking.startDateTime.minutes()}
                             toHour={booking.endDateTime.hour()}
                             toMinutes={booking.endDateTime.minutes()}
                             onTimeChange={onTimeChange}
                             disabled={isMeetingRoomBookingFromOutlook} />
          {!isMeetingRoomBookingFromOutlook && <>
            <OutlineButton click={cancelBooking}
                           style={{marginBottom: 16}}
                           text={'room-booking-dialog.cancel-booking'}
                           fullWidth={true} />
            <PrimaryButton click={confirmBooking}
                           text={'room-booking-dialog.save-changes'}
                           fullWidth={true} />
          </>}
        </>}
      </Dialog>
    </>
  )
}

interface Props {
  payload: {
    booking: MeetingRoomBooking,
  };
}

const SummaryBox = styled<any>(FlatContentCard)`
  display: flex;
  padding: 24px;
  margin-bottom: 24px;
  i {
    margin-right: 16px;
  }
`
