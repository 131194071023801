import { agent } from "./agent";
import { Period, SideOfDay } from "../models/movements.models";


export async function requestCarParkingPlan(officeId: number): Promise<CarParkingPlan[]> {
  return await agent.requests.get(`/api/car-parking/${officeId}`);
}

export async function requestCarParkPlanBookingsInCarPark(carParkPlanId: number, dateString: string): Promise<CarParkPlanBooking[]> {
  return await agent.requests.get(`/api/car-parking/bookings/plan/${carParkPlanId}/date/${dateString}`);
}

export async function requestCarParkPlanBookingsInOffice(officeId: number, dateString: string): Promise<CarParkPlanBooking[]> {
  return await agent.requests.get(`/api/car-parking/bookings/office/${officeId}/date/${dateString}`);
}

export async function requestCarParkingSpace(officeId: number, date: string): Promise<CarParkSpace> {
  return await agent.requests.get(`/api/car-parking/default-space/${officeId}/${date}`)
}

export async function requestUpdateCarParkingSpace(carParkingSpace: CarParkSpace): Promise<CarParkSpace> {
  return await agent.requests.post(`/api/car-parking/parking-space`, carParkingSpace)
}

export async function requestBookCarParkingSpace(userId: string, date: string, parkingSpaceId: number, period: Period): Promise<CarParkSpace> {
  const request = {
    userId: userId,
    date: date,
    parkingSpaceId: parkingSpaceId,
    period: period,
  }
  return await agent.requests.post(`/api/car-parking/parking-space/book`, request);
}

export async function requestCancelCarParkBooking(userId: string, date: string, parkingSpaceId: number): Promise<CarParkSpace> {
  const request = {
    userId: userId,
    date: date,
    parkingSpaceId: parkingSpaceId,
  }
  return await agent.requests.post(`/api/car-parking/parking-space/cancel`, request);
}

export interface CarParkPlanBooking {
  userId: string;
  spaceId: number;
  label: string;
  firstName: string;
  lastName: string;
  carReg?: string;
  sideOfDay: SideOfDay;
}

export interface CarParkingPlan {
  id: number;
  officeId: number;
  name: string;
  carParkPlan: string;
  parkingSpaces: CarParkSpace[];
}

export interface CarParkSpace {
  id: number;
  carParkPlanId: number;
  label: string;
  active: boolean;
  width: number;
  height: number;
  x: number;
  y: number;
  rotation: number;
}
