import React, { useEffect } from 'react';
import { UsersAnnualLeaveHeader } from "./components/UsersAnnualLeaveHeader";
import { YearContainer } from "./components/YearContainer";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { selectCalendarDate } from "../../../../store/ducks/dashboard.duck";
import { usePageName } from "../../../../hooks/UsePageName";
import { desktopSmall } from "../../../UI/atoms/MediaQueries";
import {
  loadUsersHolidays,
  selectCompanyHolidays,
  selectUsersBookedHolidays
} from "../../../../store/ducks/holidays-v2.duck";
import { HolidaysTakenOverview } from "./components/HolidaysTakenOverview";
import { HolidaysBookingsList } from "./components/HolidaysBookingsList";
import { Sidebar } from "../../../UI/atoms/Sidebar";
import { BiggerThanTabletOnly } from "../../../UI/atoms/DeviceSpecific";
import { WorkPlannerLayout } from "../../../UI/molecules/work-planner/WorkPlannerLayout";
import { useParams } from "react-router";

export function UserAnnualHolidayView(props: Props) {
  const dispatch = useDispatch();
  const calendarDate = useSelector(selectCalendarDate);
  const holidays = useSelector(selectUsersBookedHolidays);
  const companyHolidays = useSelector(selectCompanyHolidays);
  const params = useParams<any>();
  const year = calendarDate.year();
  const userId = params.userId;

  usePageName('Team Today - Your holidays');

  useEffect(() => {
    dispatch(loadUsersHolidays({holidayYear: year, userId: userId}));
  }, [dispatch, year, userId]);

  return (
    <WorkPlannerLayout pageContentStyles={{paddingTop: 24}} numberOfWeeksInMonth={6}>
      <UsersAnnualLeaveHeader />
      <Content>
        <Sidebar>
          <HolidaysTakenOverview />
          <HolidaysBookingsList />
        </Sidebar>
        <BiggerThanTabletOnly>
          <YearContainer holidaysBooked={holidays}
                         companyHolidays={companyHolidays}
                         startDate={calendarDate.clone().startOf('year')} />
        </BiggerThanTabletOnly>
      </Content>
    </WorkPlannerLayout>
  )
}

interface Props {
}

const Content = styled.div`
  margin: 24px 0 0 0;
  display: flex;
  flex-direction: column-reverse;

  @media (${desktopSmall}) {
    flex-direction: row;
  }
`
