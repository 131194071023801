import React from 'react';
import './movements-wall-chart.scss';
import { TagWithMovements } from "../../../../models/movements.models";
import { useSelector } from "react-redux";
import MovementsList from "./components/team-movements-list/MovementsList";
import { selectCurrentUser } from "../../../../store/ducks/auth.duck";
import { isCompanyAdminUser } from "../../../../utils/AccessControl";
import EmptyTagView from "./components/empty-team-view/EmptyTagView";
import { HeadlineSmall } from "../../atoms/fonts/Headline";
import { EditTagMovementsRow } from "./components/EditTagMovementsRow";
import { Center } from "../../atoms/StructuralLayout";
import { LoadingSpinner } from "../../atoms/LoadingSpinner";

interface Props {
  tagWithMovements?: TagWithMovements;
  showHeader?: boolean;
  showFooter?: boolean;
}

export default function TagMovementsWallChart(props: Props) {
  const {tagWithMovements} = props;
  const currentUser = useSelector(selectCurrentUser);
  return (
    <>
      {tagWithMovements?.movements ? <>
        {(tagWithMovements.movements.length > 0) ? <>

            {props.showHeader && <HeadlineSmall>{tagWithMovements.tag.label}</HeadlineSmall>}
            <MovementsList movements={tagWithMovements.movements}
                           enableSetMovements={isCompanyAdminUser(currentUser)}/>

            {props.showFooter && <EditTagMovementsRow tag={tagWithMovements.tag} />}
          </> :
          <EmptyTagView tag={tagWithMovements.tag} />
        }
      </> : <>
        <Center>
          <LoadingSpinner hideText={true} hideBorder={true} style={{marginTop: 50}} />
        </Center>
      </>}
    </>
  )
}

