import styled from "styled-components/macro";
import Colours from "../../../UI/atoms/Colours";

export const HALF_HOUR_TIME_SLOT_HEIGHT = 40;
export const HOUR_TIME_SLOT_HEIGHT = HALF_HOUR_TIME_SLOT_HEIGHT * 2;
export const SWIM_LANE_HEIGHT = HALF_HOUR_TIME_SLOT_HEIGHT * 2 * 24;
export const MEETING_ROOM_HEADER_HEIGHT = 82;
export const TIME_BOX_WIDTH_MOBILE = 70;
export const TIME_BOX_WIDTH = 110;
export const MEETING_ROOM_SWIMLANE_WIDTH = 218;
export const TIME_SLOT_BACKGROUND_COLOUR = '#E6EEFF';
export const BORDER_SOLID = '1px solid rgba(0, 87, 255, 0.3)';
export const BORDER_DOTTED = '1px dashed rgba(0, 87, 255, 0.3)';

export const HalfHourSlotCell = styled.div`
  height: ${HALF_HOUR_TIME_SLOT_HEIGHT}px;
  width: 100%;
  background-color: ${TIME_SLOT_BACKGROUND_COLOUR};
  border-bottom: ${BORDER_SOLID};
  &:hover {
    background-color: ${Colours.blue50} !important;
  }
`

export const HourSlotCell = styled.div`
  width: 100%;
  height: ${HALF_HOUR_TIME_SLOT_HEIGHT}px;
  background-color: ${TIME_SLOT_BACKGROUND_COLOUR};
  border-bottom: ${BORDER_DOTTED};
  &:hover {
    background-color: ${Colours.blue50} !important;
  }
`
