import React, { useMemo, useState } from 'react';
import { Column, Row } from "../../../../UI/atoms/StructuralLayout";
import TeamDropDownList from "../../../../UI/organisms/team-drop-down-list/TeamDropDownList";
import { SimpleSpinner } from "../../../../UI/atoms/SimpleSpinner";
import { TeamWithCount } from "../../../../../models/team.models";
import { UserInfo } from "../../../../../models/user.models";
import { useDispatch, useSelector } from "react-redux";
import { selectIsEditUserLoading, setEditingUser } from "../../../../../store/ducks/editUser.duck";
import { selectCurrentUser, updateCurrentUser } from "../../../../../store/ducks/auth.duck";
import { UpdateUserField, updateUserField } from "../../../../../services/UserService";
import { successNotification, warningNotification } from "../../../../../store/ducks/notification.duck";

export function ChangeUsersTeamDropDownComponent(props: Props) {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const {teams, editingUser} = props;
  const isEditUserLoading = useSelector(selectIsEditUserLoading);
  const [requestProcessing, setRequestProcessing] = useState(false);

  const isLoading = useMemo(() => isEditUserLoading || requestProcessing, [isEditUserLoading, requestProcessing]);

  const onTeamSelected = async (teamId: string) => {
    if (isLoading) return;

    try {
      setRequestProcessing(true);
      await updateUserField(editingUser.userId, UpdateUserField.team, teamId);
      dispatch(setEditingUser({
        ...editingUser,
        teamId: teamId,
      }));
      // If user is editing themselves refresh current user.
      if (editingUser.userId === currentUser?.id) {
        dispatch(updateCurrentUser(true));
      }

      dispatch(successNotification('Users team updated'));
    } catch (e) {
      dispatch(warningNotification('Failed to update users team'));
    } finally {
      setRequestProcessing(false);
    }
  }

  return (
    <Row>
      <Column style={{flex: 1, marginRight: 8}}>
        <TeamDropDownList disabled={isLoading}
                          label="settings-page.edit-user.member-of-team"
                          onSelect={(teamId: string) => onTeamSelected(teamId)}
                          teams={teams}
                          selectedTeamId={editingUser.teamId} />
      </Column>
      {isLoading && <SimpleSpinner style={{marginBottom: -12}} size={22} />}
    </Row>
  )
}

interface Props {
  teams: TeamWithCount[];
  editingUser: UserInfo;
}
