import React, { useMemo } from 'react';
import './row.scss';
import { MovementsDailies } from "../StructuralComponents";
import { MovementsDay } from "../movements-day/MovementsDay";
import { Movement, UsersCarParkBooking } from "../../../../../../models/movements.models";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectAllWhereaboutsOptions } from "../../../../../../store/ducks/whereaboutsOptions.duck";
import { selectIconPack } from "../../../../../../store/ducks/auth.duck";
import { selectWeekCommencingCalendarDate } from "../../../../../../store/ducks/dashboard.duck";

interface Props {
  movements: Movement | any;
  areUsersMovements: boolean;
  enableSetMovements?: boolean;
  isEditing: boolean;
  userId: string;
  sevenDayWeekEnabled?: boolean;
  carParking: UsersCarParkBooking[];
  style?: any;
}

export default function MovementsWeek(props: Props) {
  const {movements, areUsersMovements, userId, isEditing, carParking} = props;
  const whereaboutsOptions = useSelector(selectAllWhereaboutsOptions);
  const iconPack = useSelector(selectIconPack);
  const weekCommencingCalendarDate = useSelector(selectWeekCommencingCalendarDate);

  const getOptionFor = (option: string) => {
    return whereaboutsOptions.find((whereaboutsOption) => whereaboutsOption.key === option);
  }

  const date = useMemo(() => {
    let retVal = weekCommencingCalendarDate;
    if (movements?.date) {
      retVal = moment(movements.date);
    }
    return retVal;
  }, [movements, weekCommencingCalendarDate])

  if (!movements) {
    return null;
  }

  return (
    <MovementsDailies style={props?.style ?? {}}>
      <MovementsDay amStatus={getOptionFor(movements.mondayAmStatus)}
                    pmStatus={getOptionFor(movements.mondayPmStatus)}
                    isEditing={isEditing}
                    officeAmLocationId={movements.mondayLocationId}
                    deskAmLabel={movements.mondayAmDeskLabel}
                    officePmLocationId={movements.mondayPmLocationId}
                    deskPmLabel={movements.mondayPmDeskLabel}
                    amDeskId={movements.mondayAmDeskLocation}
                    pmDeskId={movements.mondayPmDeskLocation}
                    dataTest="whereabouts-monday"
                    className="whereabouts-monday"
                    momentDate={date}
                    isUsersMovement={areUsersMovements}
                    enableSetMovements={props.enableSetMovements}
                    carParking={carParking}
                    iconPack={iconPack}
                    userId={userId}
                    carParkingRequestedAm={movements.mondayAmGeneralParking}
                    carParkingRequestedPm={movements.mondayPmGeneralParking}
                    amCarParkSpaceId={movements.mondayAmParkingSpaceId}
                    pmCarParkSpaceId={movements.mondayPmParkingSpaceId}
                    amParkingSpaceLabel={movements.mondayAmParkingSpaceLabel}
                    pmParkingSpaceLabel={movements.mondayPmParkingSpaceLabel} />

      <MovementsDay amStatus={getOptionFor(movements.tuesdayAmStatus)}
                    pmStatus={getOptionFor(movements.tuesdayPmStatus)}
                    isEditing={isEditing}
                    officeAmLocationId={movements.tuesdayLocationId}
                    deskAmLabel={movements.tuesdayAmDeskLabel}
                    officePmLocationId={movements.tuesdayPmLocationId}
                    deskPmLabel={movements.tuesdayPmDeskLabel}
                    amDeskId={movements.tuesdayAmDeskLocation}
                    pmDeskId={movements.tuesdayPmDeskLocation}
                    dataTest="whereabouts-tuesday"
                    className="whereabouts-tuesday"
                    momentDate={date.clone().add(1, 'days')}
                    isUsersMovement={areUsersMovements}
                    enableSetMovements={props.enableSetMovements}
                    carParking={carParking}
                    iconPack={iconPack}
                    userId={userId}
                    carParkingRequestedAm={movements.tuesdayAmGeneralParking}
                    carParkingRequestedPm={movements.tuesdayPmGeneralParking}
                    amCarParkSpaceId={movements.tuesdayAmParkingSpaceId}
                    pmCarParkSpaceId={movements.tuesdayPmParkingSpaceId}
                    amParkingSpaceLabel={movements.tuesdayAmParkingSpaceLabel}
                    pmParkingSpaceLabel={movements.tuesdayPmParkingSpaceLabel} />

      <MovementsDay amStatus={getOptionFor(movements.wednesdayAmStatus)}
                    pmStatus={getOptionFor(movements.wednesdayPmStatus)}
                    isEditing={isEditing}
                    officeAmLocationId={movements.wednesdayLocationId}
                    deskAmLabel={movements.wednesdayAmDeskLabel}
                    officePmLocationId={movements.wednesdayPmLocationId}
                    deskPmLabel={movements.wednesdayPmDeskLabel}
                    amDeskId={movements.wednesdayAmDeskLocation}
                    pmDeskId={movements.wednesdayPmDeskLocation}
                    dataTest="whereabouts-wednesday"
                    className="whereabouts-wednesday"
                    momentDate={date.clone().add(2, 'days')}
                    isUsersMovement={areUsersMovements}
                    enableSetMovements={props.enableSetMovements}
                    carParking={carParking}
                    iconPack={iconPack}
                    userId={userId}
                    carParkingRequestedAm={movements.wednesdayAmGeneralParking}
                    carParkingRequestedPm={movements.wednesdayPmGeneralParking}
                    amCarParkSpaceId={movements.wednesdayAmParkingSpaceId}
                    pmCarParkSpaceId={movements.wednesdayPmParkingSpaceId}
                    amParkingSpaceLabel={movements.wednesdayAmParkingSpaceLabel}
                    pmParkingSpaceLabel={movements.wednesdayPmParkingSpaceLabel} />

      <MovementsDay amStatus={getOptionFor(movements.thursdayAmStatus)}
                    pmStatus={getOptionFor(movements.thursdayPmStatus)}
                    isEditing={isEditing}
                    officeAmLocationId={movements.thursdayLocationId}
                    deskAmLabel={movements.thursdayAmDeskLabel}
                    officePmLocationId={movements.thursdayPmLocationId}
                    deskPmLabel={movements.thursdayPmDeskLabel}
                    amDeskId={movements.thursdayAmDeskLocation}
                    pmDeskId={movements.thursdayPmDeskLocation}
                    carParkingRequestedAm={movements.thursdayAmGeneralParking}
                    carParkingRequestedPm={movements.thursdayPmGeneralParking}
                    dataTest="whereabouts-thursday"
                    className="whereabouts-thursday"
                    momentDate={date.clone().add(3, 'days')}
                    isUsersMovement={areUsersMovements}
                    enableSetMovements={props.enableSetMovements}
                    carParking={carParking}
                    iconPack={iconPack}
                    userId={userId}
                    amCarParkSpaceId={movements.thursdayAmParkingSpaceId}
                    pmCarParkSpaceId={movements.thursdayPmParkingSpaceId}
                    amParkingSpaceLabel={movements.thursdayAmParkingSpaceLabel}
                    pmParkingSpaceLabel={movements.thursdayPmParkingSpaceLabel} />

      <MovementsDay amStatus={getOptionFor(movements.fridayAmStatus)}
                    pmStatus={getOptionFor(movements.fridayPmStatus)}
                    isEditing={isEditing}
                    officeAmLocationId={movements.fridayLocationId}
                    deskAmLabel={movements.fridayAmDeskLabel}
                    officePmLocationId={movements.fridayPmLocationId}
                    deskPmLabel={movements.fridayPmDeskLabel}
                    amDeskId={movements.fridayAmDeskLocation}
                    pmDeskId={movements.fridayPmDeskLocation}
                    carParkingRequestedAm={movements.fridayAmGeneralParking}
                    carParkingRequestedPm={movements.fridayPmGeneralParking}
                    dataTest="whereabouts-friday"
                    className="whereabouts-friday"
                    momentDate={date.clone().add(4, 'days')}
                    isUsersMovement={areUsersMovements}
                    enableSetMovements={props.enableSetMovements}
                    carParking={carParking}
                    iconPack={iconPack}
                    userId={userId}
                    amCarParkSpaceId={movements.fridayAmParkingSpaceId}
                    pmCarParkSpaceId={movements.fridayPmParkingSpaceId}
                    amParkingSpaceLabel={movements.fridayAmParkingSpaceLabel}
                    pmParkingSpaceLabel={movements.fridayPmParkingSpaceLabel} />

      {props.sevenDayWeekEnabled && <>

        <MovementsDay amStatus={getOptionFor(movements.saturdayAmStatus)}
                      pmStatus={getOptionFor(movements.saturdayPmStatus)}
                      isEditing={isEditing}
                      officeAmLocationId={movements.saturdayLocationId}
                      deskAmLabel={movements.saturdayAmDeskLabel}
                      officePmLocationId={movements.saturdayPmLocationId}
                      deskPmLabel={movements.saturdayPmDeskLabel}
                      amDeskId={movements.saturdayAmDeskLocation}
                      pmDeskId={movements.saturdayPmDeskLocation}
                      carParkingRequestedAm={movements.saturdayAmGeneralParking}
                      carParkingRequestedPm={movements.saturdayPmGeneralParking}
                      dataTest="whereabouts-saturday"
                      className="whereabouts-saturday"
                      momentDate={date.clone().add(5, 'days')}
                      isUsersMovement={areUsersMovements}
                      enableSetMovements={props.enableSetMovements}
                      carParking={carParking}
                      iconPack={iconPack}
                      userId={userId}
                      amCarParkSpaceId={movements.saturdayAmParkingSpaceId}
                      pmCarParkSpaceId={movements.saturdayPmParkingSpaceId}
                      amParkingSpaceLabel={movements.saturdayAmParkingSpaceLabel}
                      pmParkingSpaceLabel={movements.saturdayPmParkingSpaceLabel} />


        <MovementsDay amStatus={getOptionFor(movements.sundayAmStatus)}
                      pmStatus={getOptionFor(movements.sundayPmStatus)}
                      isEditing={isEditing}
                      officeAmLocationId={movements.sundayLocationId}
                      deskAmLabel={movements.sundayAmDeskLabel}
                      officePmLocationId={movements.sundayPmLocationId}
                      deskPmLabel={movements.sundayPmDeskLabel}
                      amDeskId={movements.sundayAmDeskLocation}
                      pmDeskId={movements.sundayPmDeskLocation}
                      carParkingRequestedAm={movements.sundayAmGeneralParking}
                      carParkingRequestedPm={movements.sundayPmGeneralParking}
                      dataTest="whereabouts-sunday"
                      className="whereabouts-sunday"
                      momentDate={date.clone().add(6, 'days')}
                      isUsersMovement={areUsersMovements}
                      enableSetMovements={props.enableSetMovements}
                      carParking={carParking}
                      iconPack={iconPack}
                      userId={userId}
                      amCarParkSpaceId={movements.sundayAmParkingSpaceId}
                      pmCarParkSpaceId={movements.sundayPmParkingSpaceId}
                      amParkingSpaceLabel={movements.sundayAmParkingSpaceLabel}
                      pmParkingSpaceLabel={movements.sundayPmParkingSpaceLabel} />
      </>}

    </MovementsDailies>
  )
}
