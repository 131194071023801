import React, { useEffect } from 'react';
import { HolidayApprovalsHeader } from "./components/HolidayApprovalsHeader";
import styled from "styled-components/macro";
import { ApprovalsCalendarList } from "./components/ApprovalsCalendarList";
import { useSelector } from "react-redux";
import { selectCalendarDate } from "../../../../store/ducks/dashboard.duck";
import Colours from "../../../UI/atoms/Colours";
import { ApprovalsUserSectionHeader } from "./components/ApprovalsUserSectionHeader";
import { ApprovalsList } from "./components/ApprovalsList";
import { selectIsApprovalsLoading } from "../../../../store/ducks/approvalRequests.duck";
import { Center } from "../../../UI/atoms/StructuralLayout";
import { LoadingSpinner } from "../../../UI/atoms/LoadingSpinner";
import { tablet } from "../../../UI/atoms/MediaQueries";
import { WorkPlannerLayout } from "../../../UI/molecules/work-planner/WorkPlannerLayout";
import { selectCurrentUser } from "../../../../store/ducks/auth.duck";
import { ApprovalsNotEnabled } from "./components/ApprovalsNotEnabled";

export default function HolidayApprovalsView() {
  const calendarDate = useSelector(selectCalendarDate);
  const isLoading = useSelector(selectIsApprovalsLoading);
  const currentUser = useSelector(selectCurrentUser);

  useEffect(() => {
    // Get elements and necessary element heights
    const contentDiv = document.getElementById("content");

    // Get view height
    const viewportHeight = document.getElementsByTagName('body')[0].clientHeight;

    // Compute the content height - we want to fill the whole remaining area
    // in browser window
    if (contentDiv && viewportHeight) {
      contentDiv.style.height = `${viewportHeight - contentDiv.offsetTop}px`;
    }
  }, []);

  const areApprovalsEnabled = currentUser?.companyEntity.holidayApprovalEnabled;

  return (
    <WorkPlannerLayout pageContentStyles={{paddingTop: 24}} numberOfWeeksInMonth={6}>
      {areApprovalsEnabled ? <>
        <HolidayApprovalsHeader />
        <ApprovalsBody id={'content'}>
          <Content>
            <ApprovalsUserSectionHeader />
            <ApprovalsList />
            {isLoading && <Center><LoadingSpinner hideText={true} hideBorder={true} /></Center>}
          </Content>
          <Sidebar>
            <ApprovalsCalendarList startDate={calendarDate} />
          </Sidebar>
        </ApprovalsBody>
      </> : <>
        <ApprovalsNotEnabled />
      </>}
    </WorkPlannerLayout>
  )
}

const Content = styled.div`
  flex: 1;
  height: 100%;
  overflow-y: auto;
`

const Sidebar = styled.div`
  display: none;
  height: 100%;
  min-width: 200px;
  overflow-y: auto;
  border-left: solid 1px ${Colours.mildGrey};

  @media (${tablet}) {
    display: block;
  }
`

const ApprovalsBody = styled.div`
  display: flex;
`
