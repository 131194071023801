import React, { useMemo } from 'react';
import { SwimLane } from "./SwimLane";
import styled from "styled-components/macro";
import { MeetingRoom, MeetingRoomBooking } from "../models/MeetingRooms";
import { MeetingRoomBookingContentCard } from "./MeetingRoomBookingContentCard";
import { useDispatch, useSelector } from "react-redux";
import { DialogIdentifiers, openDialogWithPayload } from "../../../../store/ducks/dialog.duck";
import { filteredMeetingRoomBookings } from "../models/room-finder.duck";
import { BORDER_SOLID, MEETING_ROOM_SWIMLANE_WIDTH } from "./StructuralComponents";
import { MeetingRoomTitle } from "./MeetingRoomTitle";

export function DailyMeetingRoomSchedule(props: Props) {
  const {meetingRoom} = props;
  const dispatch = useDispatch();
  const allMeetingRoomBookings = useSelector(filteredMeetingRoomBookings);

  const bookings = useMemo(() => allMeetingRoomBookings.filter(booking => booking.meetingRoomId === meetingRoom.id), [allMeetingRoomBookings, meetingRoom.id])

  const onClick = (hour: number, minutes: number) => {
    dispatch(openDialogWithPayload({
      payload: {hour, minutes, meetingRoom},
      activeDialog: DialogIdentifiers.BookMeetingRoom,
    }))
  }

  return (
    <Container className={props?.className ?? ''}>
      <MeetingRoomTitle capacity={meetingRoom.capacity} name={meetingRoom.name} />
      <SwimLane onClick={onClick} />
      {bookings.map((booking: MeetingRoomBooking, key: number) => <MeetingRoomBookingContentCard key={key} booking={booking} />)}
    </Container>
  )
}

interface Props {
  meetingRoom: MeetingRoom;
  className?: string;
}

const Container = styled.div`
  position: relative;
  border-right: ${BORDER_SOLID};
  width: ${MEETING_ROOM_SWIMLANE_WIDTH}px;
  min-width: ${MEETING_ROOM_SWIMLANE_WIDTH}px;
  max-width: ${MEETING_ROOM_SWIMLANE_WIDTH}px;
`
