import React from 'react';
import styled from "styled-components/macro";
import Colours from "../../atoms/Colours";
import { useTranslation } from "react-i18next";
import { BodyRegular, BodySmall } from "../../atoms/fonts/Body";
import { useSelector } from "react-redux";
import { selectConfig } from "../../../../store/ducks/config.duck";
import { OutlineButton } from "../../atoms/buttons/OutlineButton";
import { OfficeEntity } from "../../../../services/AdvanceHotDeskingService";
import { Moment } from "moment/moment";
import { DATE_FORMAT } from "../../../../utils/DateUtils";

export function DailyOfficeVisitors(props: Props) {
  const {t} = useTranslation();
  const config = useSelector(selectConfig)

  const officeId = props.office?.id;
  const dateString = props.date?.format(DATE_FORMAT);

  if (!config.visitorBookingEnabled || !officeId || !dateString) {
    return null;
  }

  return (
    <Wrapper>
      <TitleWrapper>
        <BodyRegular weight={600} style={{maxWidth: '80%', marginBottom: 12}}>{t('visitor-booking.daily-visitors')}</BodyRegular>
      </TitleWrapper>
      <BodySmall style={{marginBottom: 16}} weight={600}>{t('visitor-booking.visitors-in-today', {count: 0})}</BodySmall>
      <OutlineButton text={'visitor-booking.manage-visitors'}
                     fullWidth={true}
                     link={`/whereabouts/visitor-booking/${officeId}?date=${dateString}`} />
    </Wrapper>
  )
}

interface Props {
  office?: OfficeEntity;
  date?: Moment;
}

const TitleWrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid ${Colours.iconGrey};
  margin-bottom: 16px;
`

const Wrapper = styled.div`
  width: 100%;
  min-height: 100px;
  padding: 24px;
  background-color: ${Colours.veryLightGrey};
  border-radius: 24px;
  margin-bottom: 16px;
`
