import React from 'react';
import styled from "styled-components/macro";
import Colours from "../atoms/Colours";
import { UNKNOWN_OPTION, WhereaboutsOption } from "../../../services/WhereaboutOptions";
import { IconPack } from "../../../models/company.models";
import { iconPackPath } from "../../../utils/WhereaboutsHelper";

export interface MovementIconProps {
  amStatus?: WhereaboutsOption;
  pmStatus?: WhereaboutsOption;
  amOfficeLocationId?: number;
  pmOfficeLocationId?: number;
  focused?: boolean;
  disabled?: boolean;
  className?: string;
  officeAmLabel?: string;
  officePmLabel?: string;
  onClick?: () => any;
  iconPack: IconPack;
}

export function MovementIcon(props: MovementIconProps) {
  const am = props.amStatus ?? UNKNOWN_OPTION;
  const pm = props.pmStatus ?? UNKNOWN_OPTION;
  const className = props.className || '';
  const onClick = props.onClick ?? (() => {});

  const movementClassName = `movement__icon--${am.key.toLowerCase()}`;
  const movementsDisabledClass = props.disabled ? 'movement__icon--disabled' : '';
  const focusedClassName = props.focused ? 'movements__icon--focused' : '';

  if (am !== pm || props.amOfficeLocationId !== props.pmOfficeLocationId) {
    return (
      <MovementHalfDayIcon amStatus={am} pmStatus={pm}
                           disabled={props.disabled}
                           focused={props.focused}
                           className={`${focusedClassName} ${props.className}`.trim()}
                           officeAmLabel={props.officeAmLabel}
                           iconPack={props.iconPack}
                           officePmLabel={props.officePmLabel} onClick={onClick} />
    )
  } else {

    return (
      <div className={`movement__icon ${movementClassName} movement__icon--${am.colour.toLowerCase()} ${focusedClassName} ${movementsDisabledClass} ${className}`.trim()}
           data-test={am.dataTest}
           onClick={onClick}
           title={am.label}
           aria-hidden="true">
        {!!am.image && <img src={iconPackPath(props.iconPack, am.image, am.freeBusyStatus)} alt={am.alt} />}
        {props.officeAmLabel && <OfficeLabel data-test={'movementIcon-officeLabel'}>{props.officeAmLabel.substring(0, 3).toUpperCase()}</OfficeLabel>}
      </div>
    )
  }
}

export function MovementHalfDayIcon(props: MovementIconProps) {
  const {iconPack} = props;
  const amOption = props.amStatus ?? UNKNOWN_OPTION;
  const pmOption = props.pmStatus ?? UNKNOWN_OPTION;
  const movementsDisabledClass = props.disabled ? 'movement__icon--disabled' : '';
  const onClick = props.onClick ?? (() => {});

  return (
    <div className={`movement__icon movement__iconHalfDay`} aria-hidden="true" onClick={onClick}>
      <div data-test={amOption.dataTest}
           title={amOption.label}
           className={`movement__icon--am movement__icon--${amOption.key.toLowerCase()}  movement__icon--${amOption.colour.toLowerCase()} ${movementsDisabledClass}`.trim()}>
        {amOption.key !== UNKNOWN_OPTION.key && <img src={iconPackPath(iconPack, amOption.image, amOption.freeBusyStatus)} alt={amOption.alt} />}
      </div>
      <div data-test={pmOption.dataTest}
           title={pmOption.label}
           className={`movement__icon--pm movement__icon--${pmOption.key.toLowerCase()} movement__icon--${pmOption.colour.toLowerCase()} ${movementsDisabledClass}`.trim()}>
        {pmOption.key !== UNKNOWN_OPTION.key && <img src={iconPackPath(iconPack, pmOption.image, pmOption.freeBusyStatus)} alt={pmOption.alt} />}
      </div>
      {props.officeAmLabel && <OfficeLabel data-test={'movementIcon-officeLabel'}>{props.officeAmLabel.substring(0, 3).toUpperCase()}</OfficeLabel>}
      {props.officePmLabel && <OfficeLabel style={{left: 'unset', right: 0}} data-test={'movementIcon-officeLabel'}>{props.officePmLabel.substring(0, 3).toUpperCase()}</OfficeLabel>}
    </div>
  )
}

const OfficeLabel = styled.span`
  font-family: "soleil", sans-serif;
  font-weight: 600;
  font-size: 10px;
  line-height: 18px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: ${Colours.blue};
  color: white;
  width: 40%;
  text-align: center;
`;
