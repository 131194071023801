import React from 'react';
import './_button.scss';
import styled from "styled-components/macro";
import Icon, { IconTypes } from "../icon/Icon";
import { Column, Row } from '../StructuralLayout';
import { BodyVerySmall } from "../fonts/Body";
import Colours from "../Colours";
import { useTranslation } from "react-i18next";


export function Button({
                         text = '',
                         fullWidth = false,
                         dataTest = null,
                         buttonType = 'button',
                         className = '',
                         type = 'primary',
                         size = 'small',
                         disabled = false,
                         onClick = () => {},
                         icon = undefined,
                         imageUrl = undefined, marginTop = 0, subtext
                       }: ButtonProps) {
  const buttonTypeClass = !!type ? `button--${type}` : '';
  const buttonSizeClass = !!size ? `button--${size}` : '';
  const disabledClass = disabled ? `button--disabled` : '';
  const fullWidthClass = fullWidth ? `button--full-width` : '';
  const {t} = useTranslation();

  const click = (e: any) => {
    if (!disabled) onClick(e);
  }

  let buttonAttributes = {};
  if (!!dataTest) {
    // @ts-ignore
    buttonAttributes['data-test'] = dataTest;
  }

  return (
    <button {...buttonAttributes} type={buttonType} style={{marginTop: marginTop}}
            className={`button ${fullWidthClass} ${buttonTypeClass} ${buttonSizeClass} ${disabledClass} noSelect ${className}`.trim()}
            onClick={(e) => click(e)}>
      <Column style={{justifyContent: 'center'}}>
        <Row>
          {icon && <Icon style={{marginRight: 8}} icon={icon}/>}
          {imageUrl && <ButtonImage src={imageUrl} alt="image" aria-hidden={true}/>}
          <span>{t(text)}</span>
        </Row>
        {subtext && <BodyVerySmall colour={Colours.darkGrey} style={{margin: 0}}>{subtext}</BodyVerySmall>}
      </Column>
    </button>
  );
}

export const BigMobileButton = styled<typeof Button>(Button)`
  height: 50px;
`

export const ButtonImage = styled.img`
  position: absolute;
  left: 16px;
  top: 25%;
`


export interface ButtonProps {
  text?: string;
  subtext?: string;
  buttonType?: 'button' | 'submit' | 'reset';
  size?: 'large' | 'medium' | 'small';
  fullWidth?: boolean;
  type?: 'primary' | 'outline-black' | 'grey' | 'green' | 'outline-borderless' | 'error';
  onClick?: any;
  link?: string | null;
  className?: string | null;
  disabled?: boolean;
  dataTest?: string | null;
  icon?: IconTypes;
  imageUrl?: string;
  marginTop?: number;
}
