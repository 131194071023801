import React, { useEffect, useMemo } from 'react';
import { Accordian } from "../../../../animations/AnimationVariants";
import { OfficeEntity } from "../../../../services/AdvanceHotDeskingService";
import { FloorAndAreaButton } from "./FloorAndAreaButton";
import moment from "moment/moment";
import { GenericDropDownList } from "../../../UI/molecules/GenericDropDownList";
import styled from "styled-components/macro";
import { findOfficeInOffices, getOfficeChildren, getRootOffice, sortOffice } from "../../../../utils/OfficeHelper";
import {
  clearCarParkingPlans,
  clearSelectedArea,
  loadCarParkingPlansForActiveOffice,
  MapView,
  selectCarParkingPlans,
  selectedRootOffice,
  selectOffice,
  setSelectedMapView
} from "../../../../store/ducks/officeView.duck";
import { useDispatch, useSelector } from "react-redux";
import {
  selectActiveOffices,
  selectAllOfficeEntities,
  selectIsAdvanceHotDeskingLoading
} from "../../../../store/ducks/advanceHotDeskingSetup.duck";
import { selectActiveDay } from "../../../../store/ducks/editMovements.duck";
import { Column } from "../../../UI/atoms/StructuralLayout";
import { iconPackPath } from "../../../../utils/WhereaboutsHelper";
import { FreeBusyStatus } from "../../../../services/WhereaboutOptions";
import { HeadlineSmall } from "../../../UI/atoms/fonts/Headline";
import { BodyRegular } from "../../../UI/atoms/fonts/Body";
import { OutlineButton } from "../../../UI/atoms/buttons/OutlineButton";
import { selectIconPack } from "../../../../store/ducks/auth.duck";
import { useTranslation } from "react-i18next";
import { OfficeCarParkPlanButton } from "./OfficeCarParkPlanButton";
import { setSelectedParkingPlanId } from "../ducks/carParkingMapView.duck";

export function ChooseOfficeControls(props: Props) {
  const dispatch = useDispatch();
  const isOfficesLoading = useSelector(selectIsAdvanceHotDeskingLoading);
  const offices = useSelector(selectActiveOffices);
  const activeDay = useSelector(selectActiveDay);
  const activeOffice = useSelector(selectedRootOffice);
  const allOffices = useSelector(selectAllOfficeEntities);
  const iconPack = useSelector(selectIconPack);
  const carParkingPlans = useSelector(selectCarParkingPlans);
  const {t} = useTranslation();

  const activeParentOffice = useMemo(() =>
    (!!activeOffice?.parentId ? getRootOffice(allOffices, activeOffice) : activeOffice), [allOffices, activeOffice])

  const activeChildOffices = useMemo(() =>
    (allOffices
      .filter((o: OfficeEntity) => o.parentId === activeParentOffice?.id && o.active)
      .sort(sortOffice)), [allOffices, activeParentOffice]);

  const rootOfficeHasParkingPlan = activeParentOffice?.hasCarParkPlan;
  const rootOfficeId = activeParentOffice?.id;

  useEffect(() => {
    if (rootOfficeHasParkingPlan && rootOfficeId) {
      dispatch(loadCarParkingPlansForActiveOffice({rootOfficeId: rootOfficeId}));
    }
  }, [rootOfficeHasParkingPlan, dispatch, rootOfficeId]);

  const officesForDropdown = useMemo(() => offices.map((office: OfficeEntity) => ({...office, name: office.label})), [offices]);

  const onSelect = (officeId: any) => {
    const office = findOfficeInOffices(allOffices, parseInt(officeId));
    const officeChildren = getOfficeChildren(parseInt(officeId), allOffices);

    dispatch(setSelectedMapView(MapView.Office));
    dispatch(clearCarParkingPlans());
    dispatch(setSelectedParkingPlanId(0));

    console.log('onSelect', officeId)

    if (officeChildren.length > 0) {
      dispatch(selectOffice(officeChildren[0]));
      dispatch(clearSelectedArea());
    } else if (office) {
      dispatch(selectOffice(office));
      dispatch(clearSelectedArea());
    } else {
      console.warn('No office found for', officeId);
    }
  }

  if (isOfficesLoading) {
    return null;
  } else if (offices.length === 0) {
    return (
      <Column>
        <OfficeImage style={{marginBottom: 24}} src={iconPackPath(iconPack, 'Office.svg', FreeBusyStatus.FREE)} alt="Ofifce" aria-hidden={false} />
        <HeadlineSmall style={{marginBottom: 8}}>{t('company-view-page.create-an-office.title')}</HeadlineSmall>
        <BodyRegular style={{marginBottom: 32}}>{t('company-view-page.create-an-office.description')}</BodyRegular>
        <OutlineButton size="large" click={() => window.location.href = '/manage/hot-desking'} text="button.create-office" />
      </Column>)
  } else {
    return (
      <>
        <OfficeSelectDropDown selectedId={activeOffice?.id}
                              onSelect={(office: any) => onSelect(office)}
                              items={officesForDropdown}
                              placeholder="textinput.choose-an-office" />

        {activeChildOffices && activeChildOffices.length > 0 && <Accordian isOpen={!!activeParentOffice} style={{marginTop: 8}}>
          {activeChildOffices.map((floor: OfficeEntity, key: number) => (
            <FloorAndAreaButton key={key}
                                data-test={`floor-button-${floor.label}`}
                                office={floor}
                                onSelect={props.onSelect}
                                date={activeDay ?? moment()} />
          ))}
        </Accordian>}

        {carParkingPlans && carParkingPlans.length > 0 && <>
          {carParkingPlans.map((plan, key) => (
            <OfficeCarParkPlanButton key={key} plan={plan} />))}
        </>}
      </>
    )
  }
}

interface Props {
  onSelect?: () => any;
}


const OfficeSelectDropDown = styled<any>(GenericDropDownList)<any>`
  width: 100%;
`

const OfficeImage = styled.img`
  align-self: center;
  width: 96px;
`

