import { useSelector } from "react-redux";
import { selectCurrentUser } from "../store/ducks/auth.duck";
import { useMemo } from "react";

export function IsSuperAdmin() {
  const current = useSelector(selectCurrentUser);

  const superAdminButtonsEnabled = useMemo(() => {
    if (!current) return false;
    return ['robin@team-today.com', 'isherwood88@gmail.com', 'andrew@team-tracker.com', 'lucy@team-today.com'].includes((current?.email ?? '' as string));
  }, [current]);

  return superAdminButtonsEnabled;
}
