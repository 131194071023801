import React from 'react';
import { FloatingUserCell } from "./FloatingUserCell";
import { BorderDayCell, ROW_HEIGHT, WallPlannerRow, WeekCell } from '../WallPlannerStructuralComponents';
import { HeadlineSmall } from "../../../UI/atoms/fonts/Headline";
import Colours from "../../../UI/atoms/Colours";
import styled from "styled-components";
import { smallTablet } from "../../../UI/atoms/MediaQueries";
import { WithWeeksInCurrentCalendarMonth } from "../../../../hooks/WithWeekInCurrentCalendarMonth";


interface Props {
  teamName: string;
  isFirst: boolean;
}

export function WallPlannerTeamRow(props: Props) {
  const weeksInMonth = WithWeeksInCurrentCalendarMonth();
  return (
    <WallPlannerTeamRowContainer weeksInMonth={weeksInMonth}>
      <FloatTeamCell style={{}}>
        <HeadlineSmall>{props.teamName}</HeadlineSmall>
      </FloatTeamCell>
      {weeksInMonth.map((timeFrame, k) => (
        <WeekCell key={k}>
          <BorderDayCell/>
          <BorderDayCell/>
          <BorderDayCell/>
          <BorderDayCell/>
          <BorderDayCell/>
          <BorderDayCell weekend={true}/>
          <BorderDayCell weekend={true}/>
        </WeekCell>
      ))}
    </WallPlannerTeamRowContainer>
  )
}

export const WallPlannerTeamRowContainer = styled<any>(WallPlannerRow)<any>`
  height: ${ROW_HEIGHT}px;
  width: 100%;
  h3 {
    padding-top: 12px;
  }
  @media (${smallTablet}) {
    h3 {
      padding-top: 0;
    }
  }
`

export const FloatTeamCell = styled(FloatingUserCell)<any>`
  @media (${smallTablet}) {
    border-bottom: 1px solid ${Colours.mildGrey};
  }
`
