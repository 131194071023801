import { Moment } from "moment";

export interface MeetingRoom {
  id: string;
  capacity: number;
  name: string;
  officeId: number;
  facilities: MeetingRoomFacilities[];
  microsoftRoomId?: string;
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  rotate?: number;
}

export interface MeetingRoomBooking {
  id?: number;
  meetingRoomId: string;
  startDateTime: Moment;
  endDateTime: Moment;
  bookerName: string;
  name: string;
  updateCalendar?: boolean;
  status: MeetingRoomStatus;
  source: RoomBookingSource;
}

export enum MeetRoomCapacityBands {
  ANY='ANY', OneToTwo='OneToTwo', ThreeToFive='ThreeToFive', SixToEight='SixToEight', MoreThanEight='MoreThanEight',
}

export enum MeetingRoomStatus {
  PENDING='PENDING', COMPLETE='COMPLETE', FAILED='FAILED',
}

export enum RoomBookingSource {
  TEAM_TODAY='TEAM_TODAY', OUTLOOK='OUTLOOK', SYSTEM_LINKED_TO_OUTLOOK='SYSTEM_LINKED_TO_OUTLOOK'
}

export enum MeetingRoomFacilities {
  TV = 'TV',
  ConferenceCall = 'CONFERENCE_CALL',
  WhiteBoard = 'WHITEBOARD',
  ChargePoints = 'CHARGE_POINTS'
}

export interface EnrichedMeetingRoomBooking extends MeetingRoomBooking {
  firstName: string;
  lastName: string;
  dateString: string;
}
