import React, { useEffect, useMemo } from 'react';
import { formatGroupLabel, formatOptionLabel, MenuOptionType, TeamMultiSelectProps } from "./TeamMultiSelect";
import { TeamEntity } from "../../../../models/team.models";
import { Tag } from "../../../../models/tags.models";
import { useDispatch, useSelector } from "react-redux";
import {
  loadFollowingGroups,
  selectFollowingWorkGroups,
  selectIsLoading,
  updateFollowingTeamsAndWorkgroups
} from "../../../../store/ducks/followingTeamsAndTags.duck";
import styled from "styled-components/macro";
import Select from "react-select";
import { DROP_DOWN_STYLES } from "../drop-down-list/DropDownStyles";
import makeAnimated from "react-select/animated";
import { useTranslation } from "react-i18next";
import { groupTeams, groupWorkgroups } from "./GroupTeamsAndWorkgroupsByOffices";
import { selectAllOfficeEntities } from "../../../../store/ducks/advanceHotDeskingSetup.duck";
import { WithFollowedTeams } from "./WithFollowedTeams";
import { dedup } from "../../../../utils/ArrayUtils";

export enum Side { Left, Right }

const animatedComponents = makeAnimated();

interface Props extends TeamMultiSelectProps {
  style?: any;
  outerClassName?: string;
  reloadTeamWhereaboutsOnSelect: boolean;
}

export function TeamMultiSelectWithFavourites(props: Props) {
  const {teams, workGroups, reloadTeamWhereaboutsOnSelect} = props;
  const dispatch = useDispatch();
  const selectedWorkGroups = useSelector(selectFollowingWorkGroups);
  const selectedTeams = WithFollowedTeams();
  const isLoading = useSelector(selectIsLoading);
  const allOffices = useSelector(selectAllOfficeEntities);
  const {t} = useTranslation();

  useEffect(() => {
    dispatch(loadFollowingGroups(false));
  }, [dispatch]);

  const groupedOptions = useMemo(() => {
    let groupedTeams = !!teams ? groupTeams(teams, allOffices) : [];
    let groupedWorkgroups = !!workGroups ? groupWorkgroups(workGroups, allOffices) : [];

    return [
      ...groupedTeams,
      ...groupedWorkgroups,
    ]
  }, [allOffices, teams, workGroups]);

  const onGroupSelected = (selectedTeams: TeamEntity[], selectedWorkGroups: Tag[]) => {
    dispatch(updateFollowingTeamsAndWorkgroups({teams: selectedTeams, workGroups: selectedWorkGroups, reloadTeamWhereabouts: reloadTeamWhereaboutsOnSelect}))
    props.onSelect(selectedTeams, selectedWorkGroups, []);
  }

  const selectedItems = useMemo(() => {
    const selectedTeamIds = selectedTeams.map(st => st.id);
    const selectedWorkGroupIds = selectedWorkGroups.map(st => st.id);

    const flatGroupOptions = groupedOptions.filter(go => go.options)
      .map(go => go.options)
      .flat()
      .filter(go => go.value)

    const selectedTeamOptions = flatGroupOptions.filter((ddt: any) => selectedTeamIds.includes(ddt?.value?.id ?? ''))
    const selectedGroupOptions = flatGroupOptions.filter((ddt: any) => selectedWorkGroupIds.includes(ddt?.value?.id ?? ''))

    return [
      ...selectedTeamOptions,
      ...selectedGroupOptions,
    ]
  }, [groupedOptions, selectedTeams, selectedWorkGroups]);

  const onItemSelected = (selection: any) => {
    const filteredSelection = dedup(selection, (p1: any, p2: any) => p1.value.id === p2.value.id)
      .filter((item: any) => item.type === MenuOptionType.Team || item.type === MenuOptionType.WorkGroup)
    if (Array.isArray(filteredSelection)) {
      const teams = filteredSelection.filter(s => s.type === MenuOptionType.Team).map(i => i.value);
      const workGroups = filteredSelection.filter(s => s.type === MenuOptionType.WorkGroup).map(i => i.value);
      onGroupSelected(teams, workGroups);
    }
  }

  return (
    <Row style={props?.style ?? {}} className={props?.outerClassName ?? ''}>
      <Select
        className={props.multiSelectClassName ?? ' teamMultiSelect__Dropdown'}
        value={selectedItems}
        options={groupedOptions as any}
        isDisabled={isLoading}
        isLoading={isLoading}
        formatGroupLabel={formatGroupLabel as any}
        formatOptionLabel={formatOptionLabel}
        onChange={onItemSelected}
        styles={DROP_DOWN_STYLES}
        placeholder={t('Select...')}
        closeMenuOnSelect={!!props.singleSelect}
        components={{ ...animatedComponents, ClearIndicator:() => null, IndicatorSeparator:() => null }}
        isMulti={!props.singleSelect}
      />
    </Row>
  )
}

const Row = styled.div`
  display: flex;
  .teamMultiSelect__Dropdown {
    min-width: 325px !important;
    & > div {
      min-width: 325px !important;
    }
  }
`
