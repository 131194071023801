import { agent } from './agent';

export async function loadCompanyRequest() {
    return await agent.requests.get('/api/company');
}

export async function updateCompany(company) {
    return await agent.requests.put('/api/company', company);
}

export async function updateCompanyName(companyName) {
    return await agent.requests.put('/api/company/name', {name: companyName});
}

export async function requestCompanyInfo() {
    return await agent.requests.get('/api/company-info')
}

export async function requestDomainNameSignUp(enabled) {
    return await agent.requests.post('/api/company/domain-name', { enabled })
}

export async function requestTenantIdSignUp(enabled, tenantId) {
    return await agent.requests.post('/api/company/tenant-id', { enabled: enabled, tenantId: tenantId || null })
}

export async function updateScimSignupEmails(disableNewScimUserEmail) {
    return await agent.requests.post('/api/company/scim', { disableNewScimUserEmail: disableNewScimUserEmail })
}
