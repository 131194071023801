import React, { useEffect } from 'react';
import styled from "styled-components/macro";
import Colours from "../../../../UI/atoms/Colours";
import TextField from "../../../../UI/atoms/TextField";
import { Checkbox } from "../../../../UI/atoms/Checkbox";
import { IconTypes } from "../../../../UI/atoms/icon/Icon";
import { useDispatch, useSelector } from "react-redux";
import {
  getApprovals,
  selectNameFilter,
  selectSelectedGroupId,
  selectShowApproved,
  selectShowDeclined,
  selectShowPending,
  setNameFilter,
  setShowApproved,
  setShowDeclined,
  setShowPending
} from "../../../../../store/ducks/approvalRequests.duck";
import { selectCalendarDate } from "../../../../../store/ducks/dashboard.duck";
import { tablet } from "../../../../UI/atoms/MediaQueries";

export function ApprovalsUserSectionHeader(props: Props) {
  const dispatch = useDispatch();
  const calendarDate = useSelector(selectCalendarDate);
  const selectedGroupId = useSelector(selectSelectedGroupId);
  const showPending = useSelector(selectShowPending);
  const showApproved = useSelector(selectShowApproved);
  const showDeclined = useSelector(selectShowDeclined);
  const nameFilter = useSelector(selectNameFilter);

  const onTextChange = (val: any) => {
    dispatch(setNameFilter(val));
  }

  useEffect(() => {
    dispatch(getApprovals({
      pending: showPending,
      approved: showApproved,
      rejected: showDeclined,
      calendarDate: calendarDate,
      selectedGroupId: selectedGroupId
    }));
  }, [dispatch, calendarDate, selectedGroupId, showPending, showApproved, showDeclined]);

  return (
    <Wrapper>
      <TextField className={'userSectionHeader__search'} onChange={onTextChange} value={nameFilter} icon={IconTypes.Search} placeholder={'textinput.filter-users'} />
      <VisibleApprovalsWrapper>
        <Checkbox value={showPending}
                  onChange={(val: boolean) => dispatch(setShowPending(val))}
                  text={'button.pending'} />
        <Checkbox value={showApproved}
                  onChange={(val: boolean) => dispatch(setShowApproved(val))}
                  text={'button.approved'} />
        <Checkbox value={showDeclined}
                  onChange={(val: boolean) => dispatch(setShowDeclined(val))}
                  text={'button.declined'} />
      </VisibleApprovalsWrapper>
    </Wrapper>
  )
}

interface Props {
}


const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: solid 1px ${Colours.mildGrey};
  align-items: center;
  padding: 24px 0 14px 0;
  .textField {
    margin-bottom: 0;
  }
  .userSectionHeader__search {
    display: none;
    @media (${tablet}) {
      display: block;
    }
  }
`

const VisibleApprovalsWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-right: 16px;
  width: 100%;
  max-width: 100%;
  justify-content: space-evenly;
  @media (${tablet}) {
    justify-content: flex-end;
    &>* {
      margin-left: 32px;
    }
  }
`
