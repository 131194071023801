import React, { useState } from 'react';
import { Column } from "../../../../../UI/atoms/StructuralLayout";
import { BodySmall } from "../../../../../UI/atoms/fonts/Body";
import TeamMultiSelect from "../../../../../UI/organisms/team-drop-down-list/TeamMultiSelect";
import { UserSearchDropDownList } from "../../../../../UI/molecules/UserSearchDropDownList";
import { PrimaryButton } from "../../../../../UI/atoms/buttons/PrimaryButton";
import { TeamEntity } from "../../../../../../models/team.models";
import { BasicUserInfo } from "../../../../../../models/user.models";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectAllTeams } from "../../../../../../store/ducks/config.duck";
import { failureNotification } from "../../../../../../store/ducks/notification.duck";
import { moveUser, selectIsLoading, selectSelectedTeam, setSelectedTeam } from "../ducks/add-users.duck";

export function AddExistingUserForm(props: Props) {
  const dispatch = useDispatch();
  const teams = useSelector(selectAllTeams);
  const loading = useSelector(selectIsLoading);
  const selectedTeam = useSelector(selectSelectedTeam);
  const [user, setUser] = useState<BasicUserInfo | undefined>();
  const {t} = useTranslation();

  const onTeamSelect = (teams: TeamEntity[]) => {
    if (teams.length > 0) {
      const team = teams.find(t => t === teams[0]);
      dispatch(setSelectedTeam(team));
    }
  }

  const onUserSelect = (user: BasicUserInfo) => {
    setUser(user);
  }

  const onMoveTeamMemberClicked = () => {
    if (user && selectedTeam) {
      dispatch(moveUser({user, team: selectedTeam}))
    } else {
      dispatch(failureNotification('Select a team and a user'))
    }
  }

  return (
    <>
      <Column style={{marginBottom: 32}}>
        <BodySmall className="textField__label" weight={600}>{t('search-for-existing-user')}</BodySmall>
        <UserSearchDropDownList closeOnSelect={true}
                                disabled={loading}
                                onSelect={onUserSelect} />
      </Column>

      <Column style={{marginBottom: 72}}>
        <BodySmall className="textField__label" weight={600}>{t('select-team-to-add-user-to')}</BodySmall>
        <TeamMultiSelect onSelect={onTeamSelect}
                         teams={teams}
                         disabled={loading}
                         singleSelect={true}
                         selectedTeams={selectedTeam ? [selectedTeam] : []} />
      </Column>

      <PrimaryButton fullWidth={true}
                     size="large"
                     dataTest="moveTeamMember"
                     disabled={loading}
                     buttonType="submit"
                     style={{marginTop: 'auto'}}
                     text="button.add-to-team"
                     click={onMoveTeamMemberClicked}/>
    </>
  )
}

interface Props {
}
