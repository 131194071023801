import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit'
import { AppState } from "../../../../store/state/app.state";
import { requestWhereaboutsV2 } from "../../../../services/Movements";
import { WhereaboutsTeamViewResponse } from "../../../../models/api.models";
import { TagWithMovements, TeamWithMovements } from "../../../../models/movements.models";
import moment, { Moment } from "moment";

export interface TeamMovementsState {
  loading: boolean;
  visibleDate: Moment;
  teamWhereabouts: TeamWithMovements[];
  workgroupWhereabouts: TagWithMovements[];
}

export const initialTeamMovementsState: TeamMovementsState = {
  loading: false,
  visibleDate: moment(0),
  teamWhereabouts: [],
  workgroupWhereabouts: []
}

export const loadWhereabouts: any = createAsyncThunk(
  'teamMovements/loadWhereabouts',
  async (params: {calendarDate: Moment}, thunkAPI) => {
    const state = (thunkAPI.getState() as AppState);
    const calendarDate = params?.calendarDate ?? state.dashboard.calendarDate;

    const response: WhereaboutsTeamViewResponse = await requestWhereaboutsV2(calendarDate);
    const workgroups = (response?.workgroupsWhereabouts ?? []).map(ww => new TagWithMovements(ww.workGroup, true, true, ww.whereabouts));
    const teams = (response?.teamWhereabouts ?? []).map(tw => new TeamWithMovements(tw.teamEntity, true, true, tw.teamMovements, tw.userDesks))

    return {
      teamWhereabouts: teams,
      workgroupWhereabouts: workgroups,
      visibleDate: calendarDate,
    }
  }
)

const teamMovementsSlice = createSlice({
  name: 'teamMovements',
  initialState: initialTeamMovementsState,
  reducers: {
    reset: () => initialTeamMovementsState,
  },
  extraReducers: {
    [loadWhereabouts.pending]: (state) => ({...state, loading: true,}),
    [loadWhereabouts.reject]: (state) => ({...state, loading: false,}),
    [loadWhereabouts.fulfilled]: (state, action) => ({
      ...state,
      teamWhereabouts: action.payload.teamWhereabouts,
      workgroupWhereabouts: action.payload.workgroupWhereabouts,
      visibleDate: action.payload.visibleDate,
    }),
  }
})

export default teamMovementsSlice.reducer;
export const {
  reset,
} = teamMovementsSlice.actions;

// Selectors
export const selectIsTeamMovementsLoading = (state: AppState) => state.teamMovements.loading;
export const selectWhereabouts = (state: AppState) => ({
  workgroups: state.teamMovements.workgroupWhereabouts,
  teams: state.teamMovements.teamWhereabouts,
})
