
export interface TeamEntity {
  value: TeamEntity;
  companyId: string;
  id: string;
  name: string;
  officeIds?: number[];
}

export interface TeamWithCount {
  id: string;
  name: string;
  memberCount: number;
  officeIds?: number[];
}

export interface TeamInfo {
  team: TeamEntity;
  members: number;
}

export enum GroupType {
  Team, WorkGroup
}
