import React from 'react';
import { BodyRegular, BodySmall } from '../atoms/fonts/Body';
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../store/ducks/auth.duck";
import styled from "styled-components/macro";
import { Permission } from "../../../models/user.models";
import IconButton from "./icon-button/IconButton";
import Colours from "../atoms/Colours";
import { deepLinkTo, isInTeams, isInTeamsSettingsTab } from "../../../utils/TeamsUtils";
import { teamsTabs, uiUrl } from "../../../services/EnvironmentVariables";
import { isCompanyAdminUser, isOfficeAdminUser, isTeamAdminUser } from "../../../utils/AccessControl";
import { IconTypes } from "../atoms/icon/Icon";
import { useTranslation } from "react-i18next";

export function CurrentUserAvatarAndName(props: OptionalProps) {
  const current = useSelector(selectCurrentUser);
  return <>
    <UserAvatarAndName firstName={current?.firstname || ''} lastName={current?.lastname || ''} {...props} />
  </>
}

export function UserAvatarAndName({userId = '', isAdmin = false, colour = 'none',
                                    className = '', onClick = () => {},
                                    hideName = false, showEditLink = false, onHoverIcon = undefined,
                                    firstName = '', lastName = '', mouseOver = () => {}, mouseLeave = () => {}}: Props) {
  const currentUser = useSelector(selectCurrentUser);
  const name = firstName + ' ' + lastName;
  const initials = !!firstName && !!lastName ? firstName[0] + lastName[0] : '';
  const colourClass = `userAvatarAndName__avatar--${colour}`;
  const textColourClass = `userAvatarAndName--${colour}`;
  const showEditLinkClass = (showEditLink || onHoverIcon) ? `userAvatarAndName__editLink--hover` : '';
  const {t} = useTranslation();

  const click = () => {
    onClick();
    if (showEditLink && userId && (isTeamAdminUser(currentUser) || isCompanyAdminUser(currentUser) || isOfficeAdminUser(currentUser))) {
      if (isInTeams() && !isInTeamsSettingsTab()) {
        const externalUrl = `${uiUrl}/manage/user/${userId}`
        deepLinkTo(teamsTabs.settings, externalUrl, 'user', userId);
      } else {
        window.location.href = `/manage/user/${userId}`;
      }
    }
  }

  const onMouseOver = () => {
    mouseOver();
  }

  const onMouseLeave = () => {
    mouseLeave();
  }

  return (
    <div className={`userAvatarAndName ${className} ${showEditLinkClass}`.trim()}
         onMouseOver={onMouseOver}
         onMouseLeave={onMouseLeave}
         onTouchStart={onMouseOver}
         onTouchEnd={onMouseLeave}
         onTouchCancel={onMouseLeave}
         onClick={click}>
      <Initials className={`userAvatarAndName__avatar ${colourClass}`.trim()}>{initials.toUpperCase()}</Initials>
      {!hideName && <>
        <BodyRegular className="userAvatarAndName__name" title={name}>{name}</BodyRegular>
        <BodySmall colour={Colours.darkGrey} style={{marginLeft: 8}} className={`userAvatarAndName__access ${textColourClass}`.trim()} hidden={!isAdmin}>{t('general.admin')}</BodySmall>

        {showEditLink && userId && (currentUser?.role === Permission.CompanyAdmin || currentUser?.role === Permission.TeamAdmin) &&
          <div className="userAvatarAndName__editLink">
            <IconButton icon={IconTypes.Edit} />
          </div>}

        {onHoverIcon && <div className="userAvatarAndName__editLink">
          <IconButton icon={IconTypes.Holiday} />
        </div>}
      </>}
    </div>
  )
}

interface OptionalProps {
  hideName?: boolean;
  className?: string;
  isAdmin?: boolean;
  colour?: 'blue' | 'green' | 'none' | 'darkBlue' | 'grey' | 'lightGrey' | 'transparent' | 'visitor';
  onClick?: any;
  mouseOver?: () => any;
  mouseLeave?: () => any;
}

interface Props extends OptionalProps {
  userId?: string;
  firstName: string;
  lastName: string;
  showEditLink?: boolean;
  onHoverIcon?: IconTypes;
}

const Initials = styled.p`
  font-family: "soleil", sans-serif;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
`
