import React from 'react';
import { BodyRegular, BodyVerySmall } from "../../../UI/atoms/fonts/Body";
import Colours from "../../../UI/atoms/Colours";
import { Row } from "../../../UI/atoms/StructuralLayout";
import Icon, { IconTypes } from "../../../UI/atoms/icon/Icon";
import styled from "styled-components/macro";
import { BORDER_SOLID, MEETING_ROOM_HEADER_HEIGHT, MEETING_ROOM_SWIMLANE_WIDTH } from "./StructuralComponents";

export function MeetingRoomTitle(props: Props) {
  return (
    <Header>
      <BodyRegular style={{marginBottom: 2}} colour={Colours.veryDarkBlue} weight={600}>{props.name}</BodyRegular>
      <Row>
        <Icon style={{marginRight: 8}} icon={IconTypes.Profile} />
        <BodyVerySmall weight={400} colour={Colours.darkGrey}>{props.capacity}</BodyVerySmall>
      </Row>
    </Header>
  )
}

interface Props {
  name: string;
  capacity: number;
}

const Header = styled.div`
  box-shadow: 0 4px 2px -2px rgba(46, 74, 148, 0.08);
  padding: 12px 16px;
  width: ${MEETING_ROOM_SWIMLANE_WIDTH}px;
  min-width: ${MEETING_ROOM_SWIMLANE_WIDTH}px;
  max-width: ${MEETING_ROOM_SWIMLANE_WIDTH}px;
  height: ${MEETING_ROOM_HEADER_HEIGHT}px;
  overflow: hidden;
  background-color: white;
  border-right: ${BORDER_SOLID};
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 9;
`
