import { MeetingRoom, MeetingRoomFacilities } from "./MeetingRooms";

export function findMeetingRoomInMeetingRooms(meetingRooms: MeetingRoom[], meetingRoomId?: string): MeetingRoom | undefined {
  if (!meetingRoomId) return undefined;
  return meetingRooms.find((mr) => mr.id === meetingRoomId);
}

export function facilityToLabel(facility: MeetingRoomFacilities): string {
  switch (facility) {
    case MeetingRoomFacilities.ChargePoints: return 'room-finder.facilities.charge-points';
    case MeetingRoomFacilities.TV: return 'room-finder.facilities.tv';
    case MeetingRoomFacilities.ConferenceCall: return 'room-finder.facilities.conference-call';
    case MeetingRoomFacilities.WhiteBoard: return 'room-finder.facilities.whiteboard';
  }
  return facility;
}
