
const darkGrey = '#647478';
const mildGrey = '#BCC6C7';
const opaqueMildGrey = 'RGBA(188, 198, 199, .5)';
const lightGrey = '#E5E5E5';
const veryLightGrey = '#FAFAFA';
const iconGrey = '#EDEDED';
const white = 'white';
const black = '#081E22';
const darkBlue = '#0013BE';
const veryDarkBlue = '#1C2684';
const blue = 'RGBA(0, 87, 255, 1)';
const outlookBlue = '#1C7BC8';
const lightBlue = '#1EE1FF';
const outlineBlue = '#064CD2';
const blue02 = 'RGBA(0, 87, 255, 0.02)';
const blue05 = 'RGBA(0, 87, 255, .05)';
const blue10 = 'RGBA(0, 87, 255, .1)';
const blue15 = 'RGBA(0, 87, 255, .15)';
const blue20 = 'RGBA(0, 87, 255, .2)';
const blue50 = 'RGBA(0, 87, 255, .5)';
const darkGreen = '#1AA85C';
const green = '#20CC6F';
const plannerGreen = '#BDFAC8';
const green2 = 'rgba(32, 204, 111, 0.02);';
const red = '#F0224F';
const orange = '#f08222';
const plannerOrange = '#FFF6A1';
const orange10 = 'RGBA(255, 141, 27, .1)';
const orange20 = 'RGBA(255, 141, 27, .2)';
const orange50 = 'RGBA(255, 141, 27, .5)';
const transparent = 'transparent';
const black50 = 'RGBA(0, 0, 0, .5)';

const Colours = {
  darkGrey,
  mildGrey,
  opaqueMildGrey,
  lightGrey,
  veryLightGrey,
  iconGrey,
  white,
  black,
  darkBlue,
  veryDarkBlue,
  blue,
  outlookBlue,
  lightBlue,
  outlineBlue,
  blue02,
  blue05,
  blue10,
  blue15,
  blue20,
  blue50,
  darkGreen,
  green2,
  green,
  plannerGreen,
  red,
  plannerOrange,
  orange,
  orange10,
  orange20,
  orange50,
  transparent,
  black50
}

export default Colours;
