import moment, { Moment } from "moment";
import { Movement, MovementEntity, Period } from "../models/movements.models";
import { FixedWhereabouts, FreeBusyStatus, UNKNOWN_OPTION, WhereaboutsOption } from "../services/WhereaboutOptions";

export function EmptyMovement(date: Moment, userId: string): Movement {
  return {
    date: date,
    localDate: date.format('YYYY-MM-DD'),
    userId: userId,
    mondayAmStatus: UNKNOWN_OPTION.key,
    mondayPmStatus: UNKNOWN_OPTION.key,
    mondayLocationId: 0,
    mondayPmLocationId: 0,
    tuesdayAmStatus: UNKNOWN_OPTION.key,
    tuesdayPmStatus: UNKNOWN_OPTION.key,
    tuesdayLocationId: 0,
    tuesdayPmLocationId: 0,
    wednesdayAmStatus: UNKNOWN_OPTION.key,
    wednesdayPmStatus: UNKNOWN_OPTION.key,
    wednesdayLocationId: 0,
    wednesdayPmLocationId: 0,
    thursdayAmStatus: UNKNOWN_OPTION.key,
    thursdayPmStatus: UNKNOWN_OPTION.key,
    thursdayLocationId: 0,
    thursdayPmLocationId: 0,
    fridayAmStatus: UNKNOWN_OPTION.key,
    fridayPmStatus: UNKNOWN_OPTION.key,
    fridayLocationId: 0,
    fridayPmLocationId: 0,
    saturdayAmStatus: UNKNOWN_OPTION.key,
    saturdayPmStatus: UNKNOWN_OPTION.key,
    saturdayLocationId: 0,
    saturdayPmLocationId: 0,
    sundayAmStatus: UNKNOWN_OPTION.key,
    sundayPmStatus: UNKNOWN_OPTION.key,
    sundayLocationId: 0,
    sundayPmLocationId: 0,
  }
}

export function getDailyMovementFor(date: Moment, weeklyMovements: Movement) {
  const weekCommencingDate = moment(weeklyMovements.date);
  const diff = date.diff(weekCommencingDate, 'days');
  if (diff === 0) {
    return {amStats: weeklyMovements.mondayAmStatus, pmStatus: weeklyMovements.mondayPmStatus, date: date,}
  } else if (diff === 1) {
    return {amStats: weeklyMovements.tuesdayAmStatus, pmStatus: weeklyMovements.tuesdayPmStatus, date: date,}
  } else if (diff === 2) {
    return {amStats: weeklyMovements.wednesdayAmStatus, pmStatus: weeklyMovements.wednesdayPmStatus, date: date,}
  } else if (diff === 3) {
    return {amStats: weeklyMovements.thursdayAmStatus, pmStatus: weeklyMovements.thursdayPmStatus, date: date,}
  } else if (diff === 4) {
    return {amStats: weeklyMovements.fridayAmStatus, pmStatus: weeklyMovements.fridayPmStatus, date: date,}
  } else if (diff === 5) {
    return {amStats: weeklyMovements.saturdayAmStatus, pmStatus: weeklyMovements.saturdayPmStatus, date: date,}
  } else if (diff === 6) {
    return {amStats: weeklyMovements.sundayAmStatus, pmStatus: weeklyMovements.sundayPmStatus, date: date,}
  } else {
    throw new Error('Could not identify movement');
  }
}

export const shouldUpdateStatus = (activePeriod: Period, activeDate: Moment, movement: Movement, dayOffset: number, thisPeriod: Period) => {
  if (dayOffset < 0 || dayOffset > 6) return false;
  const matchesPeriod = activePeriod === thisPeriod || activePeriod === Period.AllDay;
  const movementDate = moment(movement.date).add(dayOffset, 'days');
  const matchesDay = activeDate.format('YYYY-MM-DD') === movementDate.format('YYYY-MM-DD');
  return matchesDay && matchesPeriod;
}

export const matchesDay = (date: Moment, activeDate: Moment, dayOffset: number) => {
  const movementDate = moment(date).add(dayOffset, 'days');
  return activeDate.format('YYYY-MM-DD') === movementDate.format('YYYY-MM-DD');
}

export function compareMovementTypesIfMatching(movementA?: WhereaboutsOption, movementB?: WhereaboutsOption) {
  return movementA?.freeBusyStatus === movementB?.freeBusyStatus;
}

export function companyMovementsForConjoiningTypes(optionA?: WhereaboutsOption, optionB?: WhereaboutsOption) {
  return optionA?.key === optionB?.key;
}

export function isBusy(location?: WhereaboutsOption) {
  return !!location && (location.freeBusyStatus === FreeBusyStatus.BUSY || location.freeBusyStatus === FreeBusyStatus.OOF);
}

export function isWorking(location?: WhereaboutsOption) {
  return !!location &&
    (location.freeBusyStatus === FreeBusyStatus.FREE);
}

export function isBusyInWork(location?: WhereaboutsOption) {
  return !!location &&
    (location.freeBusyStatus === FreeBusyStatus.TENTATIVE || location.freeBusyStatus === FreeBusyStatus.WORKING_ELSEWHERE);
}

export function isWorkingFromHome(location?: WhereaboutsOption) {
  return !!location &&
    (location.key === FixedWhereabouts.Home || location.key === FixedWhereabouts.HomeApproved);
}

export function isWorkingFromOffice(location?: WhereaboutsOption) {
  return !!location &&
    (location.key === FixedWhereabouts.Office);
}

export function containsAnySetMovements(movements: MovementEntity | Movement | any) {
  if (!movements) return false;
  return movements?.mondayAmStatus !== UNKNOWN_OPTION.key ||
    movements?.mondayPmStatus !== UNKNOWN_OPTION.key ||
    movements?.tuesdayAmStatus !== UNKNOWN_OPTION.key ||
    movements?.tuesdayPmStatus !== UNKNOWN_OPTION.key ||
    movements?.wednesdayAmStatus !== UNKNOWN_OPTION.key ||
    movements?.wednesdayPmStatus !== UNKNOWN_OPTION.key ||
    movements?.thursdayAmStatus !== UNKNOWN_OPTION.key ||
    movements?.thursdayPmStatus !== UNKNOWN_OPTION.key ||
    movements?.fridayAmStatus !== UNKNOWN_OPTION.key ||
    movements?.fridayPmStatus !== UNKNOWN_OPTION.key ||
    movements?.saturdayAmStatus !== UNKNOWN_OPTION.key ||
    movements?.saturdayPmStatus !== UNKNOWN_OPTION.key ||
    movements?.sundayAmStatus !== UNKNOWN_OPTION.key ||
    movements?.sundayPmStatus !== UNKNOWN_OPTION.key;
}
