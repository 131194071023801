import React, { useEffect, useState } from 'react';
import styled from "styled-components/macro";
import Colours from "../../UI/atoms/Colours";
import { SpaceBetweenRow } from "../settings/StyleComponents";
import DateFlicker, { DateScale } from "../../UI/molecules/DateFlicker";
import moment, { Moment } from "moment";
import { getWhereaboutsSummary } from "../../../services/ReportingService";
import { LoadingSpinner } from "../../UI/atoms/LoadingSpinner";
import { Center } from "../../UI/atoms/StructuralLayout";
import { MountedContentCard, MountedContentCardContent } from "../../UI/atoms/MountedContentCard";
import { BodyLarge, BodyRegular } from "../../UI/atoms/fonts/Body";
import { GoBackButton } from "../../UI/atoms/buttons/GoBackButton";
import { WhereaboutsSummaryBarChart } from "./components/WhereaboutsSummaryBarChart";
import { WhereaboutsOption } from "../../../services/WhereaboutOptions";
import TeamDropDownList, { ALL_TEAMS } from "../../UI/organisms/team-drop-down-list/TeamDropDownList";
import { useSelector } from "react-redux";
import { WhereaboutsBookingDownloadReportBar } from "./components/WhereaboutsBookingDownloadReportBar";
import { Spacer } from '../../UI/atoms/VerticalSpacer';
import { ToggleableOutlineButton } from "../../UI/atoms/buttons/ToggleableOutlineButton";
import { SingleUserReporting } from "./SingleUserReporting";
import { HeadlineLarge } from "../../UI/atoms/fonts/Headline";
import { useTranslation } from "react-i18next";
import { selectAllTeams } from "../../../store/ducks/config.duck";

enum View {
  TeamReport, UserReport
}

function transformWhereaboutsUsage(optionsList: WhereaboutsOption[], usage: any) {
  let result = {
  };
  Object.keys(usage).forEach(k => {
    const option = optionsList.find(wo => wo.key === k);
    // @ts-ignore
    if (option) {
      // @ts-ignore
      result[option.label] = usage[k];
    }
  })
  return result;
}

function calculateMaxValue(data: any) {
  let highestValue = 0;
  Object.values(data).forEach((row: any) => {
    const sum = Object.values(row).reduce((a: any, b: any) => {
      return a + (typeof b === 'number' ? b : 0);
    }, 0) as number;
    if (sum > highestValue) highestValue = sum;
  })
  return highestValue;
}

export function WhereaboutsReportingPage(props: Props) {
  const [date, setDate] = useState<Moment>(moment());
  const [teamId, setTeamId] = useState<string>(ALL_TEAMS);
  const [teamName, setTeamName] = useState<string>('All teams');
  const [reportData, setReportData] = useState<any>([]);
  const [keyTableData, setKeyTableData] = useState<any>([]);
  const [maxYAxisValue, setMaxYAxisValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [activeView, setActiveView] = useState(View.TeamReport);
  const teams = useSelector(selectAllTeams);
  const {t} = useTranslation();


  const onTeamIdSelected = (teamId: string) => {
    setTeamId(teamId)
    if (teamId && teamId !== ALL_TEAMS) {
      setTeamName(teams.find(t => t.id === teamId)?.name ?? '');
    } else if (teamId === ALL_TEAMS) {
      setTeamName('All teams');
    } else {
      setTeamName('');
    }
  }

  const onDateChange = (date: Moment) => {
    setDate(date);
  }

  useEffect(() => {
    if (date) {
      setReportData([]);
      setLoading(true)
      getWhereaboutsSummary(date, teamId).then(resp => {
        const optionList = resp.whereaboutsOptionList;
        const whereaboutsMonthlySummaryKeys = Object.keys(resp.whereaboutsUsage);
        const data = whereaboutsMonthlySummaryKeys.map(key => ({
          name: moment(key).format('ddd')[0],
          dateOfMonth: moment(key).format('DD'),
          date: key,
          // @ts-ignore
          ...transformWhereaboutsUsage(optionList, resp.whereaboutsUsage[key])
        }))
        data.sort((a, b) => a.date < b.date ? -1 : 1);
        setReportData(data);
        setKeyTableData(optionList);
        setLoading(false);
        setMaxYAxisValue(calculateMaxValue(resp.whereaboutsUsage));
      })
    }
  }, [date, teamId]);

  return (
    <ReportingContainer>
      <HeaderRow>
        <GoBackButton url={"/manage/reporting"} />
        <ControlButtons>
          <ToggleableOutlineButton isActive={activeView === View.TeamReport}
                                   click={() => setActiveView(View.TeamReport)}
                                   text={'button.team-report'} />
          <ToggleableOutlineButton isActive={activeView === View.UserReport}
                                   click={() => setActiveView(View.UserReport)}
                                   text={'button.user-report'} />
        </ControlButtons>
      </HeaderRow>
      {activeView === View.TeamReport ? <>

        <HeadlineLarge style={{marginBottom: 16}}>{t('reports-page.whereabouts-title')}</HeadlineLarge>

        <SpaceBetweenRow>
          <DateFlicker dateScale={DateScale.Month}
                       updateGlobalDate={false}
                       onChange={onDateChange}
                       disabled={loading} />
          <div>
            <TeamDropDownList teams={teams}
                              onSelect={onTeamIdSelected}
                              includeAllTeams={true} />
          </div>
        </SpaceBetweenRow>
        {loading ? <>
          <Center>
            <LoadingSpinner hideBorder={true} />
          </Center>
        </> : <>
          {reportData.length === 0 ? <>
            <Center style={{marginTop: 32}}>
              <MountedContentCard>
                <MountedContentCardContent>
                  <BodyLarge weight={600} colour={Colours.blue} style={{marginBottom: 12}}>{t('reports-page.whereabouts-page.no-date')}</BodyLarge>
                  <BodyRegular>{t('reports-page.whereabouts-page.select-a-date')}</BodyRegular>
                </MountedContentCardContent>
              </MountedContentCard>
            </Center>
          </> : <>
            <WhereaboutsSummaryBarChart reportData={reportData}
                                        maxValueOfYAxis={maxYAxisValue}
                                        keyTableData={keyTableData} />
          </>}
        </>}
        <Spacer />
        <WhereaboutsBookingDownloadReportBar selectedTeamId={teamId} selectedTeamName={teamName} />

      </> : <>
        <SingleUserReporting />
      </>}
    </ReportingContainer>
  );
}

const ReportingContainer = styled.div`
  max-width: 100%;
  min-height: 100vh;
  background-color: white;
  padding: 24px;
  .recharts-wrapper .recharts-cartesian-grid-horizontal line {
    stroke-opacity: 0;
  }
  .recharts-cartesian-axis-tick-line {
    display: none !important;
  }
`

const HeaderRow = styled.div`
  display: flex;
  margin-bottom: 24px;
  .goBackButton {
    align-self: center;
    margin-bottom: 0;
  }
`

const ControlButtons = styled.div`
  display: flex;
  align-self: center;
  justify-self: center;
  margin-left: auto;
  margin-right: auto;
`

interface Props {
}

