import styled from "styled-components/macro";
import { tablet } from "../../atoms/MediaQueries";
import Colours from "../../atoms/Colours";


export const WorkPlannerContainer = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  margin-bottom: 24px;
  min-height: 40vh;
  position: relative;

  &::-webkit-scrollbar {
    width: 12px; /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: ${Colours.veryLightGrey};
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${Colours.mildGrey}; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 3px solid ${Colours.veryLightGrey}; /* creates padding around scroll thumb */
  }

  & > :last-child {
    margin-bottom: 0 !important;
  }
`

export const WorkPlannerHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 24px;
  padding-top: 0;
  align-items: center;
  justify-content: center;
  @media (${tablet}) {
    flex-direction: row;
    justify-content: space-between;
    .pageTitle {
      margin: 0;
    }
  }
`

export const WorkPlannerControls = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 16px;
  .button, .linkButton {
    width: 200px;
  }
  .button {
    margin-right: 16px;
  }
  .linkButton, button {
    width: 100%;
    max-width: 100%;
    margin-bottom: 16px;
  }
  .multiSelect__wrapper {
    width: 100%;
    margin-bottom: 16px;
  }
  .multiSelect__dropDown {
    width: 100%;
  }
  @media (${tablet}) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .multiSelect__wrapper {
      width: unset;
      margin-bottom: 0;
      margin-right: 16px;
    }
    .multiSelect__dropDown {
      width: auto;
    }

    .linkButton, button {
      max-width: 192px;
      margin: unset;
    }
  }
`;
