import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { endEditingWhereabouts, selectSavingState } from '../../../../../../store/ducks/editMovements.duck';
import './edit-movements-mobile.scss';
import MobileEditMovementsContent from "../../../weekly-movements-setter/components/MobileEditMovementsContent";
import IconButton from "../../../../molecules/icon-button/IconButton";
import WeekFlicker from "../../../../../pages/team-movements/components/WeekFlicker";
import Icon, { IconTypes } from "../../../../atoms/icon/Icon";
import { BodyRegular } from "../../../../atoms/fonts/Body";
import { Column, Row } from '../../../../atoms/StructuralLayout';
import { PrimaryButton } from "../../../../atoms/buttons/PrimaryButton";

export default function EditMovementsMobile() {
  const dispatch = useDispatch();
  const state = useSelector(selectSavingState);

  const save = () => {
    dispatch(endEditingWhereabouts());
  };

  return (
    <Column className="editMovementsMobile">
      <Row className="editMovementsMobile__header">
        <WeekFlicker className="editMovementsMobile__weekFlicker"/>
        <IconButton size="mediumlarge" className="editMovementsMobile__close" icon={IconTypes.Close}
                    onClick={() => dispatch(endEditingWhereabouts())}/>
      </Row>
      <MobileEditMovementsContent/>
      {state.isComplete ? <Saved/> : null}
      <div className="editMovementsMobile__controls">
        <PrimaryButton fullWidth={true} size="large" text="button.finish-and-save" click={() => save()}/>
      </div>
    </Column>
  )
}

function Saved() {
  return (
    <Row className="editMovementsMobile__saved">
      <Icon circle={true} color="blue" icon={IconTypes.Tick}/>
      <Column>
        <BodyRegular weight={600}>Movements set for this week</BodyRegular>
      </Column>
    </Row>
  )
}
