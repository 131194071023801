import React from 'react';
import PrivateRoute from '../../routing/PrivateRoute';
import SettingsPage from './pages/user-management-page/SettingsPage';
import { Switch } from 'react-router';
import EditTeamPage from './pages/EditTeamPage';
import CompanySettingsPage from './pages/edit-company-page/CompanySettingsPage';
import EditUserPage from './pages/EditUserPage';
import './user-management.scss';
import Layout from "../../UI/organisms/layout/Layout";
import OrphanedUsersPage from "./pages/OrphanedUsers";
import PaymentsPage from "./pages/payments-page/PaymentsPage";
import PersonalPreferencesPage from "./pages/PersonalPreferencesPage";
import ManageTeamsAndUsersPage from "./pages/user-management-page/ManageTeamsAndUsersPage";
import AdvanceHotDeskingPage from "./pages/advance-hot-desking/AdvanceHotDeskingPage";
import ManageOfficesPage from "./pages/advance-hot-desking/ManageOfficesPage";
import OfficeManagementPage from "./pages/office-management-page/OfficeManagementPage";
import HolidaySettings from "./pages/HolidaySettings";
import EditWhereaboutsIconsPage from "./pages/EditWhereaboutsIconsPage";
import ManageAreasPage from "./pages/advance-hot-desking/ManageAreasPage";
import ManageDesksPage from "./pages/advance-hot-desking/ManageDesksPage";
import { OfficeUsageReportingPage } from "../reporting/OfficeUsageReportingPage";
import ReportingPageV2 from "./pages/reporting-page/ReportingPageV2";
import { WhereaboutsReportingPage } from "../reporting/WhereaboutsReportingPage";
import { MicrosoftActiveDirectorySync } from "./pages/MicrosoftActiveDirectorySync";
import { HolidaysUsageReportingPage } from "../reporting/HolidaysUsageReportingPage";
import { CompanyHolidaysPage } from "./pages/company-holidays-page/CompanyHolidaysPage";
import { CrudCompanyHolidayPage } from "./pages/company-holidays-page/CrudCompanyHolidayPage";
import { OfficeCheckInReportingPage } from "../reporting/OfficeCheckInReportingPage";
import { UnknownWhereaboutsReportingPage } from "../reporting/UnknownWhereaboutsReportingPage";
import { KeyResponsibilitiesPage } from "../reporting/key-responsibilities-report/KeyResponsibilitiesPage";
import { ManageMeetingRoomsPage } from "./pages/meeting-rooms/ManageMeetingRoomsPage";
import { AddMeetingRoomPage } from "./pages/meeting-rooms/AddMeetingRoomPage";
import { EditMeetingRoomPage } from "./pages/meeting-rooms/EditMeetingRoomPage";

export default function ManagementRouting() {
  return (
    <div className="managePage__container">
      <Layout>
        <Switch>
          <PrivateRoute exact path="/manage/reporting" component={ReportingPageV2} />
          <PrivateRoute exact path="/manage/reporting/office" component={OfficeUsageReportingPage} />
          <PrivateRoute exact path="/manage/reporting/whereabouts" component={WhereaboutsReportingPage} />
          <PrivateRoute exact path="/manage/reporting/holidays" component={HolidaysUsageReportingPage} />
          <PrivateRoute exact path="/manage/reporting/office-checkin" component={OfficeCheckInReportingPage} />
          <PrivateRoute exact path="/manage/reporting/unknown-whereabouts" component={UnknownWhereaboutsReportingPage} />
          <PrivateRoute exact path="/manage/reporting/key-responsibilities" component={KeyResponsibilitiesPage} />
          <PrivateRoute exact path="/manage/user-sync" component={MicrosoftActiveDirectorySync} />
          <PrivateRoute exact path="/manage/company-holidays" component={CompanyHolidaysPage} />
          <PrivateRoute exact path="/manage/company-holidays/add" component={CrudCompanyHolidayPage} />
          <PrivateRoute exact path="/manage/company-holidays/view/:id" component={CrudCompanyHolidayPage} />
          <PrivateRoute exact path="/manage" component={SettingsPage} />
          <div className="managePage">
            <PrivateRoute exact path="/manage/teams-and-users" component={ManageTeamsAndUsersPage} />
            <PrivateRoute exact path="/manage/whereabouts" component={EditWhereaboutsIconsPage} />
            <PrivateRoute exact path="/manage/user/:userId" component={EditUserPage} />
            <PrivateRoute exact path="/manage/team/:teamId" component={EditTeamPage} />
            <PrivateRoute exact path="/manage/company/:companyId" component={CompanySettingsPage} />
            <PrivateRoute exact path="/manage/holidays" component={HolidaySettings} />
            <PrivateRoute exact path="/manage/payments" component={PaymentsPage} />
            <PrivateRoute exact path="/manage/global-settings/:companyId" component={CompanySettingsPage} />
            <PrivateRoute exact path="/manage/assign-users" component={OrphanedUsersPage} />
            <PrivateRoute exact path="/manage/calendar" component={PersonalPreferencesPage} />
            <PrivateRoute exact path="/manage/advance-hot-desking-setup" component={AdvanceHotDeskingPage} />
            <PrivateRoute exact path="/manage/hot-desking" component={OfficeManagementPage} />
            <PrivateRoute exact path="/manage/hot-desking/add" component={ManageOfficesPage} />
            <PrivateRoute exact path="/manage/hot-desking/edit/:officeId" component={ManageOfficesPage} />
            <PrivateRoute exact path="/manage/hot-desking/edit/:officeId/areas" component={ManageAreasPage} />
            <PrivateRoute exact path="/manage/hot-desking/edit/:officeId/desks" component={ManageDesksPage} />
            <PrivateRoute exact path="/manage/meeting-rooms" component={ManageMeetingRoomsPage} />
            <PrivateRoute exact path="/manage/meeting-rooms/add" component={AddMeetingRoomPage} />
            <PrivateRoute exact path="/manage/meeting-rooms/edit/:meetingRoomId" component={EditMeetingRoomPage} />
          </div>
        </Switch>
      </Layout>
    </div>
  )
}
