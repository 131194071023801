import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../../store/ducks/auth.duck";
import { microsoftRequest } from "../../../../../services/EnvironmentVariables";
import { useMsal } from "@azure/msal-react";
import { AuthenticationResult } from "@azure/msal-common";
import { failureNotification } from "../../../../../store/ducks/notification.duck";
import styled from "styled-components/macro";
import IconButton from "../../../../UI/molecules/icon-button/IconButton";
import { IconTypes } from "../../../../UI/atoms/icon/Icon";
import { DropDownListItem } from "../../../../UI/organisms/drop-down-list/ReactSelectDropDownList";
import { REACT_SELECT_DROPDOWN_STYLES } from "../../../../UI/organisms/OfficeDropDownList";
import { BodySmall } from "../../../../UI/atoms/fonts/Body";
import { useTranslation } from "react-i18next";
import { smallTablet } from "../../../../UI/atoms/MediaQueries";
import TextField from "../../../../UI/atoms/TextField";
import { AuthType } from "../../../../../utils/AuthUtils";
import CreatableSelect from "react-select/creatable";

const REQUIRED_SCOPES = ["User.Read", "User.Read.All", "User.ReadBasic.All"];

export function OutlookMeetingRoomLookup(props: Props) {
  const {loading, onChange, microsoftRoomId} = props;
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const {t} = useTranslation();
  const {instance} = useMsal();

  const [isComponentLoading, setIsComponentLoading] = useState(false);
  const [accessToken, setAccessToken] = useState('');
  const [selectedItem, setSelectedItem] = useState<DropDownListItem | undefined>(undefined);
  const [meetingRoomDropDownOptions, setMeetingRoomDropDownOptions] = useState<DropDownListItem[]>([]);

  const isMicrosoftAuth = user?.authType === AuthType.Microsoft
  const showFreeTextInput = (!isMicrosoftAuth || !accessToken);

  const loadMeetingRoomsList = useCallback(async (token?: string) => {
    const tokenWithFallback = token ?? accessToken;
    setIsComponentLoading(true);
    try {
      const response = await fetch(`https://graph.microsoft.com/beta/me/findRooms`, {
        method: 'GET',
        headers: {
          'Authorization': tokenWithFallback
        }
      });
      const data = await response.json();
      setMeetingRoomDropDownOptions(data.value.map((room: any) => ({value: room.address, label: room.address})));
    } finally {
      setIsComponentLoading(false);
    }
  }, [accessToken])

  useEffect(() => {
    if (isMicrosoftAuth) {
      setIsComponentLoading(true);
      instance.acquireTokenSilent({ ...microsoftRequest.login, scopes: REQUIRED_SCOPES, })
        .then((result: AuthenticationResult) => {
          setAccessToken(result.accessToken)
          return result.accessToken;
        })
        .then((accessToken) => loadMeetingRoomsList(accessToken))
        .catch((err) => {
          dispatch(failureNotification('Reload permissions'));
          console.error(err)
        })
        .finally(() => setIsComponentLoading(false));
    }
  }, [dispatch, instance, isMicrosoftAuth, loadMeetingRoomsList]);

  useEffect(() => {
    const currentSelectedItem = meetingRoomDropDownOptions.find(mr => mr.value === microsoftRoomId)
    if (!currentSelectedItem && microsoftRoomId) {
      const newItem = {
        value: microsoftRoomId,
        label: microsoftRoomId,
      }
      setSelectedItem(newItem);
    } else {
      setSelectedItem(currentSelectedItem);
    }
  }, [meetingRoomDropDownOptions, microsoftRoomId]);

  const fetchPermissions = async () => {
    if (isMicrosoftAuth) {
      setIsComponentLoading(true);
      setAccessToken('');
      try {
        const result = await instance.acquireTokenPopup({
          scopes: REQUIRED_SCOPES,
          redirectUri: `${window.location.origin}/blank.html`,
        })
        setAccessToken(result.accessToken);
        await loadMeetingRoomsList(result.accessToken);
      } finally {
        setIsComponentLoading(false);
      }
    }
  }

  const onDropDownChange = (item: any) => {
    if (item) {
      onChange(item.value);
    }
  }

  return <OutlookMeetingRoomContainer>
    <BodySmall className="textField__label" weight={600}>{t('settings.meeting-rooms.meeting-room-email-address')}</BodySmall>
    <Wrapper>
      {showFreeTextInput ? <>
        <TextField disabled={loading}
                   value={microsoftRoomId}
                   onChange={props.onChange}
                   style={{marginTop: 24, marginBottom: 12}}
                   fullWidth={true} />
      </> : <>
        <DropDownWrapper>
          <CreatableSelect
            options={meetingRoomDropDownOptions}
            onChange={onDropDownChange}
            value={selectedItem}
            styles={REACT_SELECT_DROPDOWN_STYLES}
            closeMenuOnSelect={true}
            isMulti={false}
            isLoading={loading || isComponentLoading}
          />
        </DropDownWrapper>
      </>}
      {!accessToken && isMicrosoftAuth && <IconButton icon={IconTypes.Redo}
                                                      disabled={loading || isComponentLoading}
                                                      onClick={fetchPermissions} />}
    </Wrapper>
    <BodySmall weight={600}>{t('settings.meeting-rooms.important')}</BodySmall>
    <BodySmall>{t('settings.meeting-rooms.important-message')}</BodySmall>
  </OutlookMeetingRoomContainer>
}

interface Props {
  loading: boolean;
  microsoftRoomId: string;
  onChange: (roomName: string) => void;
}


const DropDownWrapper = styled.div`
  flex: 1;
  max-width: 400px;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  .textField {
    margin: 0 !important;
    max-width: 400px;
  }
`

const OutlookMeetingRoomContainer = styled.div`
  border-radius: 12px;
  background-color: #E6FCFF;
  padding: 8px;
  margin-bottom: 24px;
  @media (${smallTablet}) {
    padding: 24px;
  }
`
