import React, { useMemo } from 'react';
import styled from "styled-components/macro";
import { MeetingRoomBooking, MeetingRoomStatus, RoomBookingSource } from "../models/MeetingRooms";
import { HOUR_TIME_SLOT_HEIGHT, MEETING_ROOM_HEADER_HEIGHT } from "./StructuralComponents";
import Colours from "../../../UI/atoms/Colours";
import { BodySmall, BodyXXSmall } from "../../../UI/atoms/fonts/Body";
import Icon, { IconTypes } from '../../../UI/atoms/icon/Icon';
import { useDispatch } from "react-redux";
import { DialogIdentifiers, openDialogWithPayload } from "../../../../store/ducks/dialog.duck";
import { useTranslation } from "react-i18next";

export function MeetingRoomBookingContentCard(props: Props) {
  const dispatch = useDispatch();
  const {booking, excludeOffset} = props;
  const {t} = useTranslation();

  const onClick = () => {
    dispatch(openDialogWithPayload({
      payload: { booking: booking },
      activeDialog: DialogIdentifiers.EditBookMeetingRoom
    }));
  }

  const hourOffset = useMemo(() => {
    const offset = !!excludeOffset ? 0 : MEETING_ROOM_HEADER_HEIGHT;
    return booking.startDateTime.local().hour() * HOUR_TIME_SLOT_HEIGHT + offset;
  }, [booking, excludeOffset]);

  const minuteOffset = useMemo(() => {

    return (booking.startDateTime.local().minute() / 60) * HOUR_TIME_SLOT_HEIGHT;
  }, [booking]);

  const height = useMemo(() => {
    return (booking.endDateTime.local().diff(booking.startDateTime, 'minutes') / 60) * HOUR_TIME_SLOT_HEIGHT;
  }, [booking]);

  return (
    <Container hourOffset={hourOffset}
               minuteOffset={minuteOffset}
               bookingHeight={height}
               bookingSource={booking.source}
               onClick={onClick}
               source={booking.source}
               status={booking.status ?? MeetingRoomStatus.COMPLETE}
               title={getDebugMessage(booking.status, booking.source)}>
      {booking.bookerName ? <>
        <BodySmall weight={600} className={"meeting__bookerName"} colour={Colours.white}>{booking.bookerName}</BodySmall>
        <BodyXXSmall weight={400} colour={Colours.white}>{booking?.name}</BodyXXSmall>
      </> : <>
        <BodySmall weight={600} style={{marginBottom: 4}} className={"meeting__bookerName"} colour={Colours.white}>{booking.name}</BodySmall>
        <BodyXXSmall weight={400} colour={Colours.white}>{t('room-booking-dialog.no-booker-name')}</BodyXXSmall>
      </>}
      <Icon className={'meetingIcon'} icon={IconTypes.Users} color={'white'} />
    </Container>
  )
}

interface Props {
  booking: MeetingRoomBooking;
  excludeOffset?: boolean;
}

function getOutlineColour(status: MeetingRoomStatus, source: RoomBookingSource) {
  if (source === RoomBookingSource.SYSTEM_LINKED_TO_OUTLOOK) {
    // In system and matched to outlook
    return Colours.green;
  }

  if (status === MeetingRoomStatus.PENDING) {
    // Room is linked to outlook. Booking has been made but has not yet been matched to an outlook booking.
    return Colours.plannerOrange;
  }

  if (status === MeetingRoomStatus.COMPLETE) {
    if (source === RoomBookingSource.TEAM_TODAY) {
      // Team today booking, room not linked to Outlook
      return Colours.blue;
    } else if (source === RoomBookingSource.OUTLOOK) {
      // Outlook booking, room linked to Outlook but no booking found in Team Today
      return Colours.outlookBlue;
    } else if (source === RoomBookingSource.SYSTEM_LINKED_TO_OUTLOOK) {
      // In system and matched to outlook, but shouldn't get here
      return Colours.green;
    }
  }

  return Colours.darkGrey;
}

function getFillColour(status: MeetingRoomStatus, source: RoomBookingSource) {
  if (source === RoomBookingSource.SYSTEM_LINKED_TO_OUTLOOK) {
    // In system and matched to outlook
    return Colours.green;
  }

  if (status === MeetingRoomStatus.PENDING) {
    // Room is linked to outlook. Booking has been made but has not yet been matched to an outlook booking.
    return Colours.orange;
  }

  if (status === MeetingRoomStatus.COMPLETE) {
    if (source === RoomBookingSource.TEAM_TODAY) {
      // Team today booking, room not linked to Outlook
      return Colours.blue;
    } else if (source === RoomBookingSource.OUTLOOK) {
      // Outlook booking, room linked to Outlook but no booking found in Team Today
      return Colours.outlookBlue;
    } else if (source === RoomBookingSource.SYSTEM_LINKED_TO_OUTLOOK) {
      // In system and matched to outlook, but shouldn't get here
      return Colours.green;
    }
  }

  return Colours.darkGrey;
}

function getDebugMessage(status: MeetingRoomStatus, source: RoomBookingSource): string {
  if (source === RoomBookingSource.SYSTEM_LINKED_TO_OUTLOOK) {
    return 'In system and matched to outlook'
  }

  if (status === MeetingRoomStatus.PENDING) {
    return 'Room is linked to outlook. Booking has been made but has not yet been matched to an outlook booking.'
  }

  if (status === MeetingRoomStatus.COMPLETE) {
    if (source === RoomBookingSource.TEAM_TODAY) {
      return 'Team today booking, room not linked to Outlook'
    } else if (source === RoomBookingSource.OUTLOOK) {
      return 'Outlook booking, room linked to Outlook but no booking found in Team Today'
    } else if (source === RoomBookingSource.SYSTEM_LINKED_TO_OUTLOOK) {
      return 'In system and matched to outlook, but should not get here';
    }
  }

  return 'Nothing has matched, likely data error';
}

const Container = styled.div<any>`
  position: absolute;
  top: ${props => props.hourOffset + props.minuteOffset}px;
  left: 2%;
  height: ${props => props.bookingHeight}px;
  width: 96%;
  background-color: ${props => getFillColour(props.status, props.source)};
  border-radius: 4px;
  border: 1px solid #064CD2;
  border-color: ${props => getOutlineColour(props.status, props.source)};
  padding: 6px 16px;
  overflow: hidden;
  .meetingIcon {
    position: absolute;
    bottom: 12px;
    right: 12px;
  }
  .meeting__bookerName {
    max-width: 85%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    line-height: 16px;
  }
`
