import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect } from 'react';
import Icon, { IconTypes } from "../atoms/icon/Icon";
import { Logo } from "../atoms/Logo";
import styled from "styled-components/macro";
import { smallTablet } from "../atoms/MediaQueries";

export default function Dialog(props: DialogProps) {
  useEffect(() => {
    if (props.isOpen) {
      document.body.classList.add('noscroll');
    }
    return () => {
      document.body.classList.remove('noscroll');
    }
  }, [props.isOpen])

  const close = (e: MouseEvent) => {
    e.stopPropagation();
    if (props.onClose) {
      props.onClose();
    }
  }

  return (
    <AnimatePresence>
      {props.isOpen && (<div className="dialog__container" onClick={(e: any) => e.stopPropagation()}>
        <motion.div className="dialog__animationContainer" onClick={(e: any) => close(e)}
                    initial={{y: 40, opacity: 0}}
                    animate={{
                      y: 0,
                      opacity: 1,
                    }}
                    exit={{opacity: 0, y: 40, transition: {duration: 0.1}}}>
          <div className={`dialog ${props.className || ''}`.trim()} onClick={(e: any) => e.stopPropagation()}>
            {props.showLogo && (
              <DialogTitleBar>
                <DialogLogo><Logo/></DialogLogo>
              </DialogTitleBar>
            )}
            {props.onClose && <Icon className="dialog__close"
                                    icon={IconTypes.Close}
                                    dataTest={'dialog-close'}
                                    onClick={(e: any) => close(e)}/>}
            <div className="dialog__contentContainer">
              <div className="dialog__content">
                {props.children}
              </div>
            </div>
          </div>
        </motion.div>
      </div>)}
    </AnimatePresence>
  )
}

interface DialogProps {
  children: any;
  isOpen: boolean;
  onClose?: any;
  className?: string;
  showLogo?: boolean;
}

export const DialogTitleBar = styled.div`
  position: fixed;
  width: 100%;
  background-color: white;
  height: 60px;
  @media (${smallTablet}) {
    display: none;
  }
`

export const DialogLogo = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
  @media (${smallTablet}) {
    display: none;
  }
`
