import styled from "styled-components/macro";
import {
  MEETING_ROOM_HEADER_HEIGHT,
  MEETING_ROOM_SWIMLANE_WIDTH,
  TIME_BOX_WIDTH,
  TIME_BOX_WIDTH_MOBILE
} from "../StructuralComponents";
import { smallTablet } from "../../../../UI/atoms/MediaQueries";

export const GridItem = styled.div`
  height: ${MEETING_ROOM_HEADER_HEIGHT}px;
`

export const GridItemHeader = styled.div`
  height: ${MEETING_ROOM_HEADER_HEIGHT}px;
  min-height: ${MEETING_ROOM_HEADER_HEIGHT}px;
  position: sticky;
  position: -webkit-sticky;
  background: white;
  top: 0;
`

export const GridContainer = styled.div`
  display: grid; /* This is a (hacky) way to make the .grid element size to fit its content */
  overflow: auto;
  position: relative;
  flex: 1;
`


export const Grid = styled.div`
  display: flex;
  flex-wrap: nowrap;
`

export const GridColumn = styled.div`
  width: ${MEETING_ROOM_SWIMLANE_WIDTH}px;
  min-width: ${MEETING_ROOM_SWIMLANE_WIDTH}px;
`

export const GridColumnFixedLeft = styled.div`
  width: ${TIME_BOX_WIDTH_MOBILE}px;
  min-width: ${TIME_BOX_WIDTH_MOBILE}px;
  position: sticky;
  left: 0;
  z-index: 998;
  background: white;
  @media (${smallTablet}) {
    width: ${TIME_BOX_WIDTH}px;
    min-width: ${TIME_BOX_WIDTH}px;
  }
`

